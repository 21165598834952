import React, { Fragment, useState, useEffect } from 'react'

import { PropTypes } from 'prop-types'
import axios from 'axios'
import { API_URL_USER, API_URL_PRODUCT, API_URL_TODOTYPE, API_URL_TODOFILTER } from '../../settings'

import { removeDuplicatesByKey, sortById } from '../../elements/utils'
import FilterCustomer from '../customers/FilterCustomer'
import { projectTypes } from '../project_types/projectUtils'
import LoadingPage from '../../elements/LoadingPage'

export default function CustomerFilterModalTile ({ session, form, setForm }) {
  const [loaded, setLoaded] = useState(false)

  // open type filter
  const [openToDoTypes, setOpenToDoTypes] = useState([])

  // salesmen filter
  const [salesmen, setSalesmen] = useState(null)

  // toDoFilter
  const [toDoFilters, setToDoFilters] = useState([])

  // employee filter
  const [employees, setEmployees] = useState([])

  // project filter
  const [products, setProducts] = useState([])
  const options = projectTypes.map(p => ({ value: p.key, label: p.name }))

  useEffect(() => {
    resetState()
  }, [])

  const resetState = () => {
    setLoaded(false)
    Promise.all([getSalesmenAndEmployees(), getToDos(), getProducts(), getTodoTypes()]).then(() => setLoaded(true))
  }

  const getSalesmenAndEmployees = async () => {
    return axios.get(API_URL_USER, { params: { is_staff: true, visible: true, is_active: true } })
      .then(res => {
        setSalesmen(res.data
          .filter(user => ['salesmen', 'telemarketers', 'sales_managers', 'admins', 'managers'].includes(user.group_key))
        )
        setEmployees(res.data)
      })
  }

  const getToDos = async () => {
    return axios.get(API_URL_TODOFILTER).then(res => setToDoFilters(res.data))
  }

  const getProducts = async () => {
    axios.get(API_URL_PRODUCT)
      .then(
        res => setProducts(sortById(res.data)))
  }

  const getTodoTypes = async () => {
    return Promise.all([
      axios.get(API_URL_TODOTYPE, { params: { open: true } })
    ]).then(resList => {
      const todoTypes = removeDuplicatesByKey(resList.map(r => r.data).flat(), 'id')
      todoTypes.push({ id: 0, key: 'declined', name: 'Abgesagt' })
      setOpenToDoTypes(todoTypes)
    })
  }

  const getValue = (key) => {
    return form?.data?.filters?.[key]
  }

  const onChange = (key) => {
    return (val) => setForm(prev => {
      const newval = {
        ...prev,
        data: {
          ...prev?.data,
          filters: {
            ...prev?.data?.filters,
            [key]: val
          }
        }
      }

      return newval
    })
  }

  const handleProductsChange = (chosen) => {
    onChange('productsFilter')(chosen)
    const selectedProductIds = chosen.map((option) => option.value)
    const updatedProducts = products.map((product) => ({
      ...product,
      isSelected: selectedProductIds.includes(product.id)
    }))

    setProducts(updatedProducts)
  }

  return (
    <Fragment>
      {(!loaded) ? <LoadingPage /> : null}
      <FilterCustomer
        session={session}
        openToDoTypes={openToDoTypes}
        openToDoTypeFilter={getValue('openToDoTypeFilter')}
        setOpenToDoTypeFilter={onChange('openToDoTypeFilter')}
        employees={employees}
        employeeFilter={getValue('employeeFilter')}
        setEmployeeFilter={onChange('employeeFilter')}
        salesmen={salesmen}
        salesmanFilter={getValue('salesmanFilter')}
        setSalesmanFilter={onChange('salesmanFilter')}
        toDoFilters={toDoFilters}
        setToDoFilters={setToDoFilters}
        toDoFilter={getValue('toDoFilter')}
        setToDoFilter={onChange('toDoFilter')}
        options={options}
        projectTypeFilter={getValue('projectTypeFilter')}
        setProjectTypeFilter={onChange('projectTypeFilter')}
        products={products}
        productsFilter={getValue('productsFilter')}
        handleProductsChange={handleProductsChange}
        openTasksFilter={getValue('openTasksFilter')}
        setOpenTasksFilter={onChange('openTasksFilter')}
      />
    </Fragment>
  )
}
CustomerFilterModalTile.propTypes = {
  session: PropTypes.object,
  form: PropTypes.object,
  setForm: PropTypes.func
}
