import React, { useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import axios from 'axios'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Badge,
  Box,
  Typography
} from '@material-ui/core'

import CustomModal from '../shared/modal_utils/CustomModal'
import { CustomButton } from '../../elements/StyledElements'
import { API_URL_TIMEMANAGEMENTEVENT, API_URL_USER } from '../../settings'
import { userWithLabel } from '../../elements/utils'

export default function VacationModal ({ session, resetParent, vacations }) {
  const [loadingElements, setLoadingElements] = useState({
    submitError: false, eventIdClicked: null
  })
  const [employees, setEmployees] = useState([])

  const loadAllEmployees = () => {
    axios
      .get(API_URL_USER, { params: { is_staff: true, visible: true } })
      .then((res) => {
        setEmployees(res.data)
      })
  }

  useEffect(() => {
    loadAllEmployees()
  }, [])

  const dayOffType = [
    { label: 'Arbeitszeit', value: 0 },
    { label: 'Urlaub', value: 1 },
    { label: 'Krankheit', value: 2 },
    { label: 'Frei wegen 4-Tage-Woche', value: 3 }
  ]

  // Only show vacations for employees that are staff and visible only. Not active employees are still shown
  const filteredVacations = vacations.filter(vacation =>
    employees.some(e => e.id === vacation.user)
  )

  const getOpenButton = (toggle) => {
    return (
      <Box position="relative" display="inline-block">
        <Badge badgeContent={filteredVacations.length} color="secondary" overlap="rectangular">
          <CustomButton onClick={toggle}>Urlaubsgenehmigung</CustomButton>
        </Badge>
      </Box>
    )
  }

  const approveEvent = (eventId) => {
    setLoadingElements({ ...loadingElements, eventIdClicked: eventId })
    axios.put(`${API_URL_TIMEMANAGEMENTEVENT}${eventId}`, {
      approval: true
    })
      .then(() => {
        setLoadingElements({ ...loadingElements, eventIdClicked: null })
        resetParent()
      })
      .catch(error => {
        console.error('Error approving event', error)
        setLoadingElements({ ...loadingElements, submitError: true, eventIdClicked: null })
      })
  }

  const renderTable = () => {
    if (loadingElements.submitError) {
      return <Box sx={{ my: 3, textAlign: 'center' }}><Typography style={{ fontSize: '20px' }}>Fehler beim Genehmigen des Urlaubs</Typography></Box>
    }

    if (!vacations.length) {
      return <Box sx={{ my: 3, textAlign: 'center' }}><Typography style={{ fontSize: '20px' }}>Kein nicht genehmigter Urlaub</Typography></Box>
    }

    return (
      <TableContainer component={Paper} style={{ margin: '30px 0px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Mitarbeitername</TableCell>
              <TableCell>Startdatum</TableCell>
              <TableCell>Enddatum</TableCell>
              <TableCell>Typ Freier Tag</TableCell>
              <TableCell>Aktion</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {filteredVacations.map((vacation) => {
            // Find the employee's name using the vacation.user ID
            const employee = employees.find(e => e.id === vacation.user)

            return (
              <TableRow key={vacation.id}>
                <TableCell>{userWithLabel(employee)}</TableCell>
                <TableCell>{vacation.start_date}</TableCell>
                <TableCell>{vacation.end_date}</TableCell>
                <TableCell>{dayOffType.find(type => type.value === vacation.event_type)?.label || 'Unbekannter Typ'}</TableCell>
                <TableCell>
                  <CustomButton onClick={() => approveEvent(vacation.id)}
                     icon={loadingElements.eventIdClicked === vacation.id ? 'refresh' : ''} disabled={loadingElements.eventIdClicked}>Genehmigen</CustomButton>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <Box>
      <CustomModal getOpenButton={getOpenButton} size="lg" title={'Nicht genehmigte Urlaubsliste'}>
        {renderTable()}
      </CustomModal>
    </Box>
  )
}

VacationModal.propTypes = {
  session: PropTypes.object,
  resetParent: PropTypes.func,
  vacations: PropTypes.array.isRequired
}
