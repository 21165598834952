/* eslint-disable import/no-named-default */
import React, { useState, useEffect, Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import axios from 'axios'

import RootErrorBoundary from './RootErrorBoundary'
import Layout from './layout/Layout'
import Login from './pages/Login'
import Home from './pages/Home'
import Dashboard from './components/Dashboard'
import UserInfo from './pages/UserInfo'
import ResetPasswordPage from './pages/ResetPassword'
import { API_URL_LOGOUT, API_URL_CURRENTUSER } from './settings'
import { checkAccessRights } from './elements/utils'
import { getPages } from './navigation'
import UnsubscribeNewsletter from './components/UnsubscribeNewsletter'
import UnsubscribeEnergyReport from './components/UnsubscribeEnergyReport'
import { default as PVDataInput } from './components/customer_app/project_types/plant_projects/data_input_page/DataInput'
import { default as HeatpumpDataInput } from './components/customer_app/project_types/heatpump_project/data_input_page/DataInput'
import { LeadsProvider } from './contexts/LeadsContext'

const Page = ({ page, session }) => {
  if (!session.user || !checkAccessRights(session.user, page.url)) return null

  return (
    <Fragment>
      <br />
      {/* <div className="text-center">
        {(!isMobileOnly)
          ? <Typography fontSize='h5.fontSize' className='secondary-textcolor'>
            {page.name}
          </Typography>
          : null}
      </div> */}
      {page.getPage(session)}
    </Fragment>
  )
}

Page.propTypes = {
  page: PropTypes.object,
  session: PropTypes.object
}

// A wrapper for <Route> that redirects to the login screen if you're not yet authenticated.
function PrivateRoute ({ isAuthenticated, permitted = true, children, ...rest }) {
  const getContent = (location) => {
    if (!isAuthenticated) {
      return <Redirect
      to={{
        pathname: '/login/',
        state: { from: location }
      }}
    />
    }
    if (!permitted) {
      return <Redirect
      to={{
        pathname: '/',
        state: { from: location }
      }}
    />
    }
    return children
  }
  return (
    <Route
      {...rest}
      render={({ location }) => getContent(location)}
    />
  )
}

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
  permitted: PropTypes.bool,
  children: PropTypes.node
}

function HomeRoute ({ isAuthenticated, children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated
          ? (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location }
            }}
          />
            )
          : (
          <Redirect
            to={{
              pathname: '/login/',
              state: { from: location }
            }}
          />
            )
      }
    />
  )
}

HomeRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
  children: PropTypes.node
}

const LOGOUT_SESSION = {
  isAuthenticated: false,
  user: null,
  token: null,
  logout: () => { },
  resetUser: () => { },
  axiosHeaders: null
}

export default function Urls () {
  const [session, setSession] = useState(LOGOUT_SESSION)

  useEffect(() => {
    const handleBeforeUnload = () => {
      for (const key in localStorage) {
        if (key.startsWith('operators:')) localStorage.removeItem(key)
      }
    }
    window.addEventListener('beforeunload', handleBeforeUnload)

    resetSession()

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  const resetUser = async () => {
    const token = localStorage.getItem('token')
    return axios.get(API_URL_CURRENTUSER, { headers: { Authorization: `Token ${token}` } })
      .then(res => {
        localStorage.setItem('user', JSON.stringify(res.data)) // stringify to save user in local storage (objects can not be saved)
        resetSession()
      })
  }

  const logout = () => {
    const token = localStorage.getItem('token')
    if (token) {
      axios.post(API_URL_LOGOUT, {}, { headers: { Authorization: `Token ${token}` } })
    }
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('expirationDate')
    localStorage.removeItem('companyConfig')
    localStorage.removeItem('creds')
    resetSession()
  }

  const resetSession = () => {
    const user = localStorage.getItem('user')
    const token = localStorage.getItem('token')
    const companyConfig = localStorage.getItem('companyConfig')
    const creds = localStorage.getItem('creds')
    const session = (!token)
      ? LOGOUT_SESSION
      : {
          isAuthenticated: true,
          user: user && user !== 'undefined' ? JSON.parse(user) : {},
          token,
          logout,
          resetUser,
          companyConfig: companyConfig ? JSON.parse(companyConfig) : {},
          creds: creds ? JSON.parse(creds) : {},
          axiosHeaders: { Authorization: `Token ${token}` }
        }
    axios.defaults.headers.common.Authorization = token ? `Token ${token}` : null

    axios.interceptors.response.use((response) => response, (error) => {
      if (error.response && error.response.status === 401) { logout(); return }
      return Promise.reject(error)
    })
    setSession(session)
  }

  return (
    <RootErrorBoundary session={session}>
      <div>
        <BrowserRouter>
          <LeadsProvider>
            <Switch>
              <Route exact path="/pv/dateneingabe/:token"><PVDataInput session={session} /></Route>
              <Route exact path="/heatpump/dateneingabe/:token"><HeatpumpDataInput session={session} /></Route>
              <Route exact path="/login/"><Login session={session} resetUser={resetUser} /></Route>
              <Route exact path="/reset_password/"><ResetPasswordPage session={session} /></Route>
              <Route exact path='/newsletter/unsubscribe/:id'><UnsubscribeNewsletter session={session} /></Route>
              <Route exact path='/energyreport/unsubscribe/:id'><UnsubscribeEnergyReport session={session} /></Route>
              <PrivateRoute exact path="/benutzer/" isAuthenticated={session.isAuthenticated}><Layout pageName={'Benutzer'} session={session}><UserInfo session={session} /></Layout></PrivateRoute>
              <PrivateRoute exact path="/" isAuthenticated={session.isAuthenticated}><Layout pageName={'Home'} session={session}><Home session={session} /></Layout></PrivateRoute>
              <PrivateRoute exact path="/dashboard/" isAuthenticated={session.isAuthenticated}><Layout pageName={'Dashboard'} session={session} stripPadding={true}><Dashboard session={session} /></Layout></PrivateRoute>
              {getPages(session).map(page => (
                <PrivateRoute
                  key={`private-route-to-${page.url}`}
                  exact
                  path={`/${page.url}/`}
                  isAuthenticated={session.isAuthenticated}
                  permitted={page.permission}
                >
                  <Layout pageName={page.name} session={session}>
                    <Page page={page} session={session} />
                  </Layout>
                </PrivateRoute>
              ))}
              {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
              <HomeRoute path="*" isAuthenticated={session.isAuthenticated}></HomeRoute>
            </Switch>
          </LeadsProvider>
        </BrowserRouter>
      </div>
    </RootErrorBoundary>
  )
};
