import React, { Fragment, useEffect, useState, useRef } from 'react'
import { PropTypes } from 'prop-types'
import { Stage, Layer, Image, Line, Group, Circle, Text as TextKonva } from 'react-konva'
import { ButtonGroup, FormGroup } from 'reactstrap'

import { Button, IconButton, Stack, TextField, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

import { CustomToggle, CustomButton } from '../../../elements/StyledElements'
import { getImageWindowWithAuth, getRandomId, round } from '../../../elements/utils'
import { modulePropType, panelPropType, stringPropType } from '../../../elements/PropTypes'
import { isMobileOnly } from 'react-device-detect'

const GRIDBYCENTER = true
const XDIFFM = 0.04
const YDIFFM = 0.04
// there are limited color that are distinct each other, https://sashamaps.net/docs/resources/20-colors/,
// based on this i only used the 99% settings, and remove beige, white, and yellow
const BASECOLORS = ['#e6194B', '#3cb44b', '#4363d8', '#f58231', '#42d4f4', '#f032e6', '#fabed4', '#469990', '#dcbeff', '#9A6324', '#800000', '#000075', '#a9a9a9', '#000000']
const ANGLESTEP = 0.1
const MAXANGLE = 5
const NNEWPANELS = 600

export default function RoofImagePlanning ({ image, imageWidthM, module, panels, setPanels, angle, setAngle, strings, setStrings, direction, flatRoof, maxWidth, maxHeight, roofName }) {
  const [imageState, setImageState] = useState({ borderWidth: 0, stageWidth: null, stageHeight: null, imageScale: null, imageWindow: null })
  const [pvImageScaleW, setPvImageScaleW] = useState(null)
  const [pvImageScaleH, setPvImageScaleH] = useState(null)
  const [pvImageWindowVertical, setPvImageWindowVertical] = useState(null)
  const [pvImageWindowHorizontal, setPvImageWindowHorizontal] = useState(null)
  const [previewPanels, setPreviewPanels] = useState([])
  const [previewString, setPreviewString] = useState([])
  const [imagePanels, setImagePanels] = useState([])

  const [gridMode, setGridMode] = useState(false)
  const [moveAllMode, setMoveAllMode] = useState(false)
  const [stringMode, setStringMode] = useState(false)
  const [optimizerMode, setOptimizerMode] = useState(false)

  const [currEditString, setCurrEditString] = useState(null)

  const origModuleWidthPx = useRef(null)
  const origModuleHeightPx = useRef(null)
  const yPanelPosition = useRef(120)

  const gridActive = useRef(false)
  const stringActive = useRef(false)
  const moveAllActive = useRef(false)
  const masterPanel = useRef(null)
  const masterPanelAlignment = useRef(null)
  const masterPanelPrevPosition = useRef(null)
  const colors = useRef(BASECOLORS)

  const colWidth = 300
  const maxImageWidth = maxWidth
  const maxImageHeight = maxHeight

  const stringNumberDist = isMobileOnly
    ? {
        x: 0.01,
        y: 0.08
      }
    : {
        x: 0.005,
        y: 0.05
      }

  useEffect(() => {
    if (moveAllMode && gridMode) setGridMode(false)
    if (moveAllMode && stringMode) setStringMode(false)
    if (moveAllMode && optimizerMode) setOptimizerMode(false)
  }, [moveAllMode])
  useEffect(() => {
    if (gridMode && moveAllMode) setMoveAllMode(false)
    if (gridMode && stringMode) setStringMode(false)
    if (gridMode && optimizerMode) setOptimizerMode(false)
  }, [gridMode])
  useEffect(() => {
    if (stringMode && moveAllMode) setMoveAllMode(false)
    if (stringMode && gridMode) setGridMode(false)
    if (stringMode && optimizerMode) setOptimizerMode(false)
  }, [stringMode])
  useEffect(() => {
    if (optimizerMode && moveAllMode) setMoveAllMode(false)
    if (optimizerMode && gridMode) setGridMode(false)
    if (optimizerMode && stringMode) setStringMode(false)
  }, [optimizerMode])

  useEffect(() => loadImage(), [image, module])
  useEffect(() => { setImagePanels(panels2imagePanels(panels)) }, [panels, imageState.stageWidth, imageState.stageHeight])

  useEffect(() => {
    const newImagePanels = [...imagePanels]
    newImagePanels
      .filter(p => !p.dragged)
      .filter(p => p.alignment === 'horizontal')
      .forEach(p => { p.y = yPanelPosition.current })
    setImagePanels(newImagePanels)
  }, [yPanelPosition.current])

  const loadImage = () => {
    if (image === null) { setImageState({ stageWidth: null, stageHeight: null, imageScale: null, imageWindow: null }); return }
    getImageWindowWithAuth(image, handleLoad)
  }

  useEffect(() => {
    if (pvImageWindowVertical == null || imageState.stageWidth == null) return
    origModuleWidthPx.current = pvImageWindowVertical.width
    origModuleHeightPx.current = pvImageWindowVertical.height
    const moduleWidthM = module.width
    const moduleWidthPx = imageState.stageWidth * moduleWidthM / imageWidthM
    const scalerW = moduleWidthPx / origModuleWidthPx.current
    setPvImageScaleW(scalerW)
    const moduleHeightPx = module.height / module.width * moduleWidthPx
    const scalerH = moduleHeightPx / origModuleHeightPx.current
    setPvImageScaleH(scalerH)
    yPanelPosition.current = Math.max(moduleWidthPx, moduleHeightPx + 40)
    setImageState(s => ({ ...s, borderWidth: Math.max(moduleWidthPx, moduleHeightPx) + 40 }))
  }, [pvImageWindowVertical, imageState.stageWidth])

  const handleLoad = (newImageWindow) => {
    // const c1 = module.height / module.width
    const c2 = module.width / imageWidthM
    const scalerW = maxImageWidth / (newImageWindow.width * (1 + c2))
    const scalerH = maxImageHeight / newImageWindow.height // (newImageWindow.height + c1 * c2 * newImageWindow.width)
    const scaler = (scalerW >= scalerH) ? scalerH : scalerW
    const stageWidth = newImageWindow.width * scaler
    const stageHeight = newImageWindow.height * scaler
    setImageState({
      imageWindow: newImageWindow,
      imageScale: scaler,
      stageWidth,
      stageHeight,
      borderWidth: 0
    })
    getImageWindowWithAuth(module.image_v, handlePvVerticalLoad)
    getImageWindowWithAuth(module.image_h, handlePvHorizontalLoad)
  }

  const handlePvVerticalLoad = (newImageWindowVertical) => {
    setPvImageWindowVertical(newImageWindowVertical)
  }

  const handlePvHorizontalLoad = (newImageWindowHorizontal) => {
    setPvImageWindowHorizontal(newImageWindowHorizontal)
  }

  const panels2imagePanels = () => {
    const newImagePanels = []
    panels.forEach((panel) => {
      newImagePanels.push({
        id: panel.id,
        key: panel.id.toString(),
        x: panel.x * imageState.stageWidth,
        y: panel.y * imageState.stageHeight,
        dragged: true,
        alignment: panel.vertical ? 'vertical' : 'horizontal',
        string: panel.string,
        idx_in_string: panel.idx_in_string,
        optimizer: panel.optimizer
      })
    })
    let panelId
    for (let i = 0; i < NNEWPANELS; i++) {
      panelId = -getRandomId()
      newImagePanels.push({
        id: panelId,
        x: imageState.stageWidth + 20,
        y: 10,
        alignment: 'vertical',
        key: panelId.toString(),
        dragged: false,
        string: null,
        idx_in_string: 0,
        optimizer: false
      })
      panelId = -getRandomId()
      newImagePanels.push({
        id: panelId,
        x: imageState.stageWidth + 20,
        y: yPanelPosition.current,
        alignment: 'horizontal',
        key: panelId.toString(),
        dragged: false,
        string: null,
        idx_in_string: 0,
        optimizer: false
      })
    }
    return newImagePanels
  }

  const imagePanelInImage = imagePanel => (imagePanel.x <= imageState.stageWidth && imagePanel.y <= imageState.stageHeight)

  const imagePanels2panels = (newImagePanels) => {
    return newImagePanels
      .filter(imagePanelInImage)
      .map(imagePanel => (
        {
          id: imagePanel.id,
          x: imagePanel.x / imageState.stageWidth,
          y: imagePanel.y / imageState.stageHeight,
          vertical: imagePanel.alignment === 'vertical',
          string: imagePanel.string,
          idx_in_string: imagePanel.idx_in_string,
          optimizer: imagePanel.optimizer
        }
      ))
  }

  const handleDragStart = (alignment, e) => {
    if (optimizerMode) {
      e.target.stopDrag()
      return
    }

    if (e.evt.ctrlKey || gridMode) {
      if (!getElementByKey(imagePanels, e.target.attrs.id).dragged) return
      e.target.stopDrag()
      masterPanel.current = e.target
      masterPanelAlignment.current = alignment
      gridActive.current = true
      return
    }
    if (moveAllMode) {
      if (!getElementByKey(imagePanels, e.target.attrs.id).dragged) { masterPanel.current = null; return }
      masterPanel.current = e.target
      masterPanelAlignment.current = alignment
      masterPanelPrevPosition.current = { x: e.target.x(), y: e.target.y() }
      imagePanels.filter((imagePanel) => imagePanel.dragged).forEach(imagePanel => {
        imagePanel.origX = imagePanel.x
        imagePanel.origY = imagePanel.y
      })
      moveAllActive.current = true
    }
  }

  const handleDrag = (e) => {
    if (moveAllMode && moveAllActive.current && masterPanel.current !== null) {
      const xDiff = e.target.x() - masterPanelPrevPosition.current.x
      const yDiff = e.target.y() - masterPanelPrevPosition.current.y
      const newImagePanels = imagePanels.map(imagePanel => {
        if (!imagePanel.dragged) return { ...imagePanel }
        if (imagePanel.key === masterPanel.current.attrs.id) return { ...imagePanel, x: e.target.x(), y: e.target.y() }
        return { ...imagePanel, x: imagePanel.origX + xDiff, y: imagePanel.origY + yDiff }
      })
      setImagePanels(newImagePanels)
    }
  }

  const handleDragEnd = (e) => {
    if (typeof e.evt === 'undefined') { // grid
      return
    }
    if (moveAllActive.current) {
      moveAllActive.current = false
      setPanels(imagePanels2panels(imagePanels))
    } else {
      const x = e.target.x()
      const y = e.target.y()
      const key = e.target.id()
      const newImagePanels = [...imagePanels]
      const imagePanel = getElementByKey(newImagePanels, key)
      imagePanel.x = x
      imagePanel.y = y
      imagePanel.dragged = true
      // setImagePanels(newImagePanels)
      setPanels(imagePanels2panels(newImagePanels))
    }
  }

  const handleMoveStart = (e) => {
    if (stringMode) {
      stringActive.current = true
    }
  }

  const handleMove = (e, isPanel) => {
    const stage = e.target.getStage()
    const cursor = stage.getPointerPosition()
    if (gridActive.current) {
      const coords = getGridPositions(cursor)
      setPreviewPanels(coords.map(function (coord) { return { ...coord, alignment: masterPanelAlignment.current } }))
    } else if (isPanel && stringActive.current) {
      // TODO check if e.target is
      const key = e.target.id()
      const imagePanel = getElementByKey(imagePanels, key)
      const panelId = parseInt(imagePanel.key)
      if (!previewString.includes(panelId) && imagePanel.dragged) {
        const newPreviewString = [...previewString]
        newPreviewString.push(panelId)
        setPreviewString(newPreviewString)
      }
    }
  }

  const handlePanelClick = (panelId) => {
    if (optimizerMode) {
      setPanels(prevPanels => prevPanels.map(panel =>
        panel.id === panelId ? { ...panel, optimizer: !panel.optimizer } : panel
      ))
    }
  }

  const addString = (panelIds) => {
    const newStrings = [...strings]
    const newImagePanels = [...imagePanels]
    const newString = { construction_inverter: null, id: -getRandomId(), color: colors.current[newStrings.length % colors.current.length] }
    if (!newString.name) {
      newString.name = `${roofName} - String ${newStrings.length + 1}`
    }
    newStrings.push(newString)
    panelIds.forEach((pId, idx) => {
      const imagePanel = newImagePanels.find(p => p.id === pId)
      imagePanel.string = newString.id
      imagePanel.idx_in_string = idx
    })
    setStrings(newStrings)
    setPanels(imagePanels2panels(newImagePanels))
  }

  const editString = (stringIdx) => {
    setCurrEditString(stringIdx)
  }
  const handleMoveEnd = (e, leave) => {
    if (gridActive.current) {
      const stage = e.target.getStage()
      const cursor = stage.getPointerPosition()
      createGrid(cursor)
      setPreviewPanels([])
      gridActive.current = false
    } else if (!leave && stringActive.current) {
      if (previewString.length > 1) {
        addString(previewString)
      }
      setPreviewString([])
      stringActive.current = false
    }
  }

  const getGridPositions = (cursor) => {
    const isVertical = (masterPanelAlignment.current === 'vertical')

    const xPos = []
    const yPos = []
    let tmpX = masterPanel.current.x()
    let tmpY = masterPanel.current.y()
    const posInXDir = (cursor.x >= masterPanel.current.x())
    const posInYDir = (cursor.y >= masterPanel.current.y())
    const moduleWidthM = module.width
    const moduleHeightM = module.height
    const imageScaleW = origModuleWidthPx.current * pvImageScaleW / moduleWidthM // pvWPx / pvWM
    const imageScaleH = origModuleHeightPx.current * pvImageScaleH / moduleHeightM // pvHPx / pvHM
    const origPanelWidth = isVertical ? origModuleWidthPx.current : origModuleHeightPx.current
    const origPanelHeight = isVertical ? origModuleHeightPx.current : origModuleWidthPx.current
    const panelScaleW = isVertical ? pvImageScaleW : pvImageScaleH
    const panelScaleH = isVertical ? pvImageScaleH : pvImageScaleW
    const moduleWidthPx = origPanelWidth * panelScaleW
    const moduleHeightPx = origPanelHeight * panelScaleH
    const xDiffPx = imageScaleW * XDIFFM
    let yDiffPx = imageScaleH * YDIFFM
    const xThreshold = GRIDBYCENTER ? moduleWidthPx / 4 : moduleWidthPx
    const yThreshold = GRIDBYCENTER ? moduleHeightPx / 4 : moduleHeightPx
    if ((direction === 'Süd') && flatRoof && !isVertical) {
      const additionalSpacingM = 0.75
      yDiffPx = imageScaleH * additionalSpacingM
    }
    if (posInXDir) {
      while (tmpX + xThreshold <= cursor.x) {
        xPos.push(tmpX)
        tmpX += moduleWidthPx + xDiffPx
      }
    } else {
      while ((tmpX + moduleWidthPx) - xThreshold >= cursor.x) {
        xPos.push(tmpX)
        tmpX -= (moduleWidthPx + xDiffPx)
      }
    }
    if (posInYDir) {
      while (tmpY + yThreshold <= cursor.y) {
        yPos.push(tmpY)
        tmpY += moduleHeightPx + yDiffPx
      }
    } else {
      while ((tmpY + moduleHeightPx) - yThreshold >= cursor.y) {
        yPos.push(tmpY)
        tmpY -= (moduleHeightPx + yDiffPx)
      }
    }
    const coords = []
    const checkForOverlap = function (x, y, alignment) {
      return imagePanels.filter(imagePanel => imagePanel.dragged).some(imagePanel => {
        const wRef = (imagePanel.alignment === 'vertical') ? origModuleWidthPx.current * pvImageScaleW : origModuleHeightPx.current * pvImageScaleH
        const hRef = (imagePanel.alignment === 'vertical') ? origModuleHeightPx.current * pvImageScaleH : origModuleWidthPx.current * pvImageScaleW
        const w = (alignment === 'vertical') ? origModuleWidthPx.current * pvImageScaleW : origModuleHeightPx.current * pvImageScaleH
        const h = (alignment === 'vertical') ? origModuleHeightPx.current * pvImageScaleH : origModuleWidthPx.current * pvImageScaleW
        const xRef = imagePanel.x
        const yRef = imagePanel.y
        const [xRef1, yRef1, xRef2, yRef2] = [xRef, yRef, xRef + wRef, yRef + hRef]
        const [x1, y1, x2, y2] = [x, y, x + w, y + h]
        return !(x2 <= xRef1 || x1 >= xRef2 || y1 >= yRef2 || y2 <= yRef1)
      })
    }
    for (let i = 0; i < xPos.length; i++) {
      for (let j = 0; j < yPos.length; j++) {
        if (!(i === 0 && j === 0)) {
          if (!checkForOverlap(xPos[i], yPos[j], masterPanelAlignment.current)) {
            coords.push({ x: xPos[i], y: yPos[j] })
          }
        }
      }
    }
    return coords
  }

  const createGrid = (cursor) => {
    const coords = getGridPositions(cursor)
    // move
    const alignment = masterPanelAlignment.current
    const newImagePanels = [...imagePanels]
    const movePanelIdxs = newImagePanels.map(function (imagePanel, pidx) {
      if ((!imagePanel.dragged) && imagePanel.alignment === alignment) {
        return pidx
      } else {
        return -1
      }
    }).filter(pidx => pidx !== -1)
    let imagePanel
    for (let i = 0; i < coords.length; i++) {
      imagePanel = newImagePanels[movePanelIdxs[i]]
      imagePanel.x = coords[i].x
      imagePanel.y = coords[i].y
      imagePanel.dragged = true
    }
    // setImagePanels(newImagePanels)
    setPanels(imagePanels2panels(newImagePanels))
    masterPanel.current = null
    masterPanelAlignment.current = null
  }

  const getElementByKey = (list, key) => { // TODO
    return list[list.findIndex(item => item.key === key)]
  }

  const removePanel = (panelId) => {
    const newImagePanels = [...imagePanels]
    const imagePanel = newImagePanels.find(p => p.id === panelId)
    imagePanel.dragged = false
    imagePanel.x = imageState.stageWidth + 20
    // imagePanel.x = (imagePanel.alignment === 'vertical') ? 10 : 120
    imagePanel.y = (imagePanel.alignment === 'vertical') ? 10 : yPanelPosition.current
    // imagePanel.y = imageState.stageHeight + 20
    if (setStrings && strings) setStrings(strings)
    setPanels(imagePanels2panels(newImagePanels))
  }

  const removeAllPanels = () => {
    const newImagePanels = [...imagePanels]
    for (let i = 0; i < newImagePanels.length; i++) {
      newImagePanels[i].dragged = false
      newImagePanels[i].x = imageState.stageWidth + 20
      newImagePanels[i].y = (newImagePanels[i].alignment === 'vertical') ? 10 : yPanelPosition.current
    }
    setPanels(imagePanels2panels(newImagePanels))
  }

  const rotateRoofImage = (anglestep) => {
    if (anglestep !== 0) {
      if ((angle < MAXANGLE && angle > -MAXANGLE) || (angle >= MAXANGLE && anglestep <= 0) || (angle <= -MAXANGLE && anglestep >= 0)) {
        setAngle(angle + anglestep)
      }
    } else setAngle(0)
  }

  const getEdge = (p1, p2, wOffsetV, hOffsetV, wOffsetH, hOffsetH) => {
    const RADV = 0.03
    const RADH = RADV * imageState.stageWidth / imageState.stageHeight
    const x1 = p1.vertical ? p1.x - wOffsetV : p1.x - wOffsetH
    const y1 = p1.vertical ? p1.y - hOffsetV : p1.y - hOffsetH
    let p = null
    let symbol = null
    if (p1.x === p2.x) {
      if (p1.y < p2.y) {
        p = { x: p1.x, y: y1 + RADH }
        symbol = { x: p1.x, y: y1 + RADH / 2 }
      } else {
        p = { x: p1.x, y: p1.vertical ? p1.y + hOffsetV - RADH : p1.y + hOffsetH - RADH }
        symbol = { x: p1.x, y: p1.vertical ? p1.y + hOffsetV - RADH / 2 : p1.y + hOffsetH - RADH / 2 }
      }
    } else if (p1.y === p2.y) {
      if (p1.x < p2.x) {
        p = { x: x1 + RADV, y: p1.y }
        symbol = { x: x1 + RADV / 2, y: p1.y }
      } else {
        p = { x: p1.vertical ? p1.x + wOffsetV - RADV : p1.x + wOffsetH - RADV, y: p1.y }
        symbol = { x: p1.vertical ? p1.x + wOffsetV - RADV / 2 : p1.x + wOffsetH - RADV / 2, y: p1.y }
      }
    } else {
      p = { x: p1.vertical ? p1.x - RADV * (p2.x - p1.x) : p1.x - RADH * (p2.x - p1.x), y: p1.vertical ? p1.y - RADV * (p2.y - p1.y) : p1.y - RADH * (p2.y - p1.y) }
      symbol = { x: p1.vertical ? p1.x - RADV * (p2.x - p1.x) : p1.x - RADH * (p2.x - p1.x), y: p1.vertical ? p1.y - RADV * (p2.y - p1.y) : p1.y - RADH * (p2.y - p1.y) }
    }
    return { p, symbol }
  }

  const updateStringVoltages = () => {
    if (strings) {
      const newStrings = strings.map(string => {
        const stringPanels = panels.filter(panel => panel.string === string.id)
        const { stringVoltage } = getStringLineFromPanels(stringPanels, false)
        return { ...string, calculated_string_voltage: stringVoltage }
      })
      setStrings(newStrings)
    }
  }

  const getStringLineFromPanels = (panels, preview, sort = true) => {
    const moduleWidthM = module.width
    const imageHeightM = imageState.stageHeight / imageState.stageWidth * imageWidthM
    const moduleHeightM = module.height
    const widthOffset = moduleWidthM / imageWidthM / 2
    const heightOffset = moduleHeightM / imageHeightM / 2
    const widthOffsetHorizontal = moduleHeightM / imageWidthM / 2
    const heightOffsetHorizontal = moduleWidthM / imageHeightM / 2
    const sortedPanels = sort
      ? panels.sort((a, b) => a.idx_in_string - b.idx_in_string)
      : panels
    const line = sortedPanels
      .map(panel => {
        const xOffset = (panel.vertical) ? widthOffset : widthOffsetHorizontal
        const yOffset = (panel.vertical) ? heightOffset : heightOffsetHorizontal
        return { x: panel.x + xOffset, y: panel.y + yOffset, vertical: panel.vertical }
      })
    let minus = null
    let plus = null
    if (!preview && line.length > 1) {
      let p1, p2
      p1 = line[0]; p2 = line[1]
      const edge1 = getEdge(p1, p2, widthOffset, heightOffset, widthOffsetHorizontal, heightOffsetHorizontal)
      line.unshift(edge1.p)
      plus = edge1.symbol
      p1 = line[line.length - 1]; p2 = line[line.length - 2]
      const edge2 = getEdge(p1, p2, widthOffset, heightOffset, widthOffsetHorizontal, heightOffsetHorizontal)
      line.push(edge2.p)
      minus = edge2.symbol
    }
    const stringVoltage = panels.length * module.string_voltage
    return { line, plus, minus, stringVoltage }
  }

  useEffect(() => {
    updateStringVoltages()
  }, [panels])

  const getStringLine = (string, preview) => {
    return { ...getStringLineFromPanels(panels.filter(p => p.string === string.id), preview), string }
  }

  const deleteString = (stringId, stringIdx) => {
    const newStrings = strings.filter((s) => s.id !== stringId)
    const color = colors.current[stringIdx % colors.current.length]
    colors.current = colors.current.filter((item, idx) => idx !== stringIdx)
    colors.current.push(color)
    setStrings(newStrings)
  }

  const stringLines = (strings) ? strings.map(string => getStringLine(string)) : null
  const numVerticalPanels = panels.filter(p => p.vertical).length
  const numHorizontalPanels = panels.filter(p => !p.vertical).length
  const currentKwp = panels.length * module.kwp
  const numOptimizers = panels.filter(panel => panel.optimizer).length

  return (
    <Fragment key='roof_image_planning'>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 1, md: 3 }}>
        <Stack
          direction={{ xs: 'row', md: 'column' }}
          style={{ maxWidth: { xs: 'fit-content', md: colWidth } }}
          flexWrap="wrap"
          columnGap={2}
          alignItems="flex-start"
          justifyContent={{ xs: 'space-between', md: 'flex-start' }}
        >
          {setAngle
            ? <FormGroup>
              <Typography className='secondary-textcolor'>Bild drehen:</Typography>
              <ButtonGroup>
                <CustomButton onClick={() => { rotateRoofImage(-ANGLESTEP) }} icon="rotateLeft"></CustomButton>
                &nbsp;&nbsp;
                <CustomButton onClick={() => { rotateRoofImage(0) }}><strong>{round(angle, 2)}</strong></CustomButton>
                &nbsp;&nbsp;
                <CustomButton onClick={() => { rotateRoofImage(ANGLESTEP) }} icon="rotateRight"></CustomButton>
              </ButtonGroup>
            </FormGroup>
            : null}

          {setStrings
            ? <FormGroup>
              <Typography className='secondary-textcolor'>String ziehen:</Typography>
              <ToggleButtonGroup size="small" value={stringMode} exclusive onChange={(e, value) => setStringMode(value)}>
                <CustomToggle value={true}>String erstellen</CustomToggle>
              </ToggleButtonGroup>
            </FormGroup>
            : null}

          <FormGroup>
            <Typography className='secondary-textcolor'>PV-Grid ziehen:</Typography>
            <ToggleButtonGroup size="small" value={gridMode} exclusive onChange={(e, value) => setGridMode(value)}>
              <CustomToggle value={true}>Grid</CustomToggle>
            </ToggleButtonGroup>
          </FormGroup>

          <FormGroup>
            <Typography className='secondary-textcolor'>Alle Module verschieben:</Typography>
            <ToggleButtonGroup size="small" value={moveAllMode} exclusive onChange={(e, value) => setMoveAllMode(value)}>
              <CustomToggle value={true}>Verschieben</CustomToggle>
            </ToggleButtonGroup>
          </FormGroup>

          <FormGroup>
            <Typography className='secondary-textcolor'>Alle Module löschen:</Typography>
            <ButtonGroup>
              <Button variant="outlined" color="error" onClick={removeAllPanels}>Alles Entfernen</Button>
            </ButtonGroup>
          </FormGroup>

          <FormGroup>
            <Typography className='secondary-textcolor'>Optimierer:</Typography>
            <ToggleButtonGroup size="small" value={optimizerMode} exclusive onChange={(e, value) => setOptimizerMode(value)}>
              <CustomToggle value={true}>Optimierer</CustomToggle>
            </ToggleButtonGroup>
          </FormGroup>

          <FormGroup>
            <Typography className='secondary-textcolor'>vertikale Module: <strong>{numVerticalPanels}</strong></Typography>
            <Typography className='secondary-textcolor'>horizontale Module: <strong>{numHorizontalPanels}</strong></Typography>
            <Typography className='secondary-textcolor'>kWp: <strong>{round(currentKwp, 2)}</strong></Typography>
            <Typography className='secondary-textcolor'>optimierer: <strong>{numOptimizers}</strong></Typography>
          </FormGroup>
          {setStrings && strings !== undefined && strings !== null
            ? stringLines.map((stringLine, sidx) => (
              <Stack direction="column" key={`string-line-${stringLine.string.id}`}>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  {currEditString === sidx
                    ? (
                    <TextField
                      type="text"
                      value={stringLine.string.name}
                      inputProps={{
                        style: {
                          color: colors.current[sidx % colors.current.length]
                        }
                      }}
                      onChange={(e) => {
                        const newStrings = [...strings]
                        const string = newStrings.find(
                          (s) => s.id === stringLine.string.id
                        )
                        string.name = e.target.value
                        setStrings(newStrings)
                      }}
                      onBlur={() => {
                        setCurrEditString(null)
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          setCurrEditString(null)
                        }
                      }}
                      autoFocus
                    />
                      )
                    : (
                    <Typography
                      key={`remove-string-line ${sidx}`}
                      style={{
                        color: colors.current[sidx % colors.current.length]
                      }}
                    >
                      {stringLine.string.name}
                    </Typography>
                      )}
                  <IconButton
                    onClick={() => {
                      editString(sidx)
                    }}
                    sx={{
                      color: colors.current[sidx % colors.current.length]
                    }}
                  >
                    <EditIcon
                      onClick={() => {
                        editString(sidx)
                      }}
                    />
                  </IconButton>
                  <IconButton
                    onClick={() => deleteString(stringLine.string.id, sidx)}
                    sx={{
                      color: colors.current[sidx % colors.current.length]
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>
                <Typography
                  key={`string-voltage ${stringLine.string.id}`}
                  style={{
                    color: colors.current[sidx % colors.current.length]
                  }}
                >
                  String Spannung berechnet: {stringLine.stringVoltage}
                </Typography>
              </Stack>
            ))
            : null}
        </Stack>
        <div style={{ width: imageState.stageWidth + imageState.borderWidth }}>
          <Stage
            width={imageState.stageWidth + imageState.borderWidth}
            height={imageState.stageHeight}
            style={{ backgroundColor: '#888888' }}
          >
            <Layer>
              <Image
                rotation={(angle != null) ? angle : 0}
                id='roofimage'
                image={imageState.imageWindow}
                scaleX={imageState.imageScale}
                scaleY={imageState.imageScale}
                onTouchStart={handleMoveStart}
                onTouchEnd={handleMoveEnd}
                onTouchMove={handleMove}
                onMouseDown={handleMoveStart}
                onMouseUp={handleMoveEnd}
                onMouseMove={handleMove}
                onMouseLeave={e => handleMoveEnd(e, true)}
                preventDefault={false}
              />

              {imagePanels.map((panel) => (
                !(flatRoof && direction === 'Süd' && panel.alignment === 'vertical' && panel.x === imageState.stageWidth + 20)
                  ? <Image
                  id={panel.key}
                  key={'panel_' + panel.key}
                  image={(panel.alignment === 'vertical') ? pvImageWindowVertical : pvImageWindowHorizontal}
                  scaleX={(panel.alignment === 'vertical') ? pvImageScaleW : pvImageScaleH}
                  scaleY={(panel.alignment === 'vertical') ? pvImageScaleH : pvImageScaleW}
                  x={panel.x} y={panel.y}
                  stroke={panel.optimizer ? 'rgb(255,0,0)' : null}
                  strokeWidth={20}
                  draggable={!stringMode && !optimizerMode}
                  onDragStart={(e) => handleDragStart(panel.alignment, e)}
                  onDragMove={handleDrag}
                  onDragEnd={handleDragEnd}
                  onDblClick={!optimizerMode ? () => removePanel(panel.id) : null}
                  onDblTap={!optimizerMode ? () => removePanel(panel.id) : null}
                  onTouchStart={!optimizerMode ? handleMoveStart : () => handlePanelClick(panel.id) }
                  onTouchEnd={handleMoveEnd}
                  onTouchMove={(e) => handleMove(e, true)}
                  onMouseDown={!optimizerMode ? handleMoveStart : () => handlePanelClick(panel.id)}
                  onMouseUp={handleMoveEnd}
                  onMouseMove={(e) => handleMove(e, true)}
                />
                  : null
              ))}

              {previewPanels.map((panel, pidx) => (
                <Image
                  id={'previewPanel_' + pidx}
                  key={'previewPanel_' + pidx}
                  opacity={0.5}
                  image={(panel.alignment === 'vertical') ? pvImageWindowVertical : pvImageWindowHorizontal}
                  scaleX={(panel.alignment === 'vertical') ? pvImageScaleW : pvImageScaleH}
                  scaleY={(panel.alignment === 'vertical') ? pvImageScaleH : pvImageScaleW}
                  x={panel.x} y={panel.y}
                  listening={false}
                />
              ))}
              {(strings !== undefined && strings !== null)
                ? (
                    stringLines.map((stringLine, sidx) => (
                    <Group key={`string-line-group-${sidx}`}>
                      <Line
                        key={'string_' + sidx.toString()}
                        points={stringLine.line.map(coord => [coord.x * imageState.stageWidth, coord.y * imageState.stageHeight]).flat()}
                        stroke={colors.current[sidx % colors.current.length]} strokeWidth={3} listening={false} />
                      {(stringLine.plus !== null)
                        ? <Group>
                          <TextKonva
                            key={'line_number_' + sidx.toString()}
                            x={stringLine.plus.x * imageState.stageWidth - stringNumberDist.x * imageState.stageWidth}
                            y={stringLine.plus.y * imageState.stageHeight - stringNumberDist.y * imageState.stageHeight}
                            text={sidx + 1}
                            fontSize={isMobileOnly ? 14 : 20}
                            fill={colors.current[sidx % colors.current.length]}
                            listening={false}
                          />
                          <Circle
                            key={'plus_c_' + sidx.toString()}
                            x={stringLine.plus.x * imageState.stageWidth}
                            y={stringLine.plus.y * imageState.stageHeight}
                            radius={0.015 * imageState.stageWidth}
                            stroke={colors.current[sidx % colors.current.length]} strokeWidth={3}
                            listening={false} />
                          <Line
                            key={'plus_h_' + sidx.toString()}
                            points={[stringLine.plus.x * imageState.stageWidth - 0.01 * imageState.stageWidth, stringLine.plus.y * imageState.stageHeight, stringLine.plus.x * imageState.stageWidth + 0.01 * imageState.stageWidth, stringLine.plus.y * imageState.stageHeight]}
                            stroke={colors.current[sidx % colors.current.length]} strokeWidth={3} listening={false} />
                          <Line
                            key={'plus_v_' + sidx.toString()}
                            points={[stringLine.plus.x * imageState.stageWidth, stringLine.plus.y * imageState.stageHeight - 0.01 * imageState.stageWidth, stringLine.plus.x * imageState.stageWidth, stringLine.plus.y * imageState.stageHeight + 0.01 * imageState.stageWidth]}
                            stroke={colors.current[sidx % colors.current.length]} strokeWidth={3} listening={false} />
                        </Group>
                        : null}
                      {(stringLine.minus !== null)
                        ? <Group>
                        <Circle
                          key={'minus_c_' + sidx.toString()}
                          x={stringLine.minus.x * imageState.stageWidth}
                          y={stringLine.minus.y * imageState.stageHeight}
                          radius={0.015 * imageState.stageWidth}
                          stroke={colors.current[sidx % colors.current.length]} strokeWidth={3}
                          listening={false} />
                        <Line
                          key={'minus_' + sidx.toString()}
                          points={[stringLine.minus.x * imageState.stageWidth - 0.01 * imageState.stageWidth, stringLine.minus.y * imageState.stageHeight, stringLine.minus.x * imageState.stageWidth + 0.01 * imageState.stageWidth, stringLine.minus.y * imageState.stageHeight]}
                          stroke={colors.current[sidx % colors.current.length]} strokeWidth={3} listening={false} />
                      </Group>
                        : null}
                    </Group>))
                  )
                : null}
              {(previewString.length !== 0)
                ? (
                  <Line
                    points={getStringLineFromPanels(previewString.map(panelId => panels.find(p => p.id === panelId)), true, false).line.map(coord => [coord.x * imageState.stageWidth, coord.y * imageState.stageHeight]).flat()}
                    stroke={colors.current[((strings) ? strings.length : 0) % colors.current.length]} strokeWidth={3} listening={false} opacity={0.5} />
                  )
                : null}
            </Layer>
          </Stage>
        </div>
      </Stack>
    </Fragment >
  )
}

RoofImagePlanning.propTypes = {
  image: PropTypes.object,
  imageWidthM: PropTypes.number,
  module: modulePropType,
  panels: PropTypes.arrayOf(panelPropType),
  setPanels: PropTypes.func,
  angle: PropTypes.number,
  setAngle: PropTypes.func,
  strings: PropTypes.arrayOf(stringPropType),
  setStrings: PropTypes.func,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  direction: PropTypes.string,
  flatRoof: PropTypes.bool,
  roofName: PropTypes.string
}
