import React from 'react'
import { hexToRgb, taskUserWithLabel } from '../../elements/utils'

export const taskPriorities = [
  { label: 'Gering', value: 0, color: '#69bf3d' },
  { label: 'Mittel', value: 1, color: '#f7ea52' },
  // { label: 'Hoch', value: 2, color: '#F78E05' },
  { label: 'Hoch', value: 2, color: '#fabc5f' },
  // { label: 'Kritisch', value: 3, color: '#FF0000' }
  { label: 'Kritisch', value: 3, color: '#f58582' }
]

export const getTaskEmployeeNames = (task, bold = true) => {
  return task && task.employees_labels && task.employees_labels.map((label, lIdx) => (
    <React.Fragment key={lIdx}>
      {taskUserWithLabel(label, task.responsible_name, bold)}
      {lIdx < task.employees_labels.length - 1 && ', '}
    </React.Fragment>
  ))
}

export const getTaskStatus = (task) => {
  if (task.finished_confirmed) return 'Fertiggestellt'
  if (task.finished) return 'Warte auf Überprüfung der Fertigstellung'
  if (task.started) return 'Gestartet'
  if (task.accepted) return 'Bestätigt'
  if (task.accepted === false) return 'Abgelehnt'
  return 'Warte auf Annahme'
}

export const getTaskColor = (task, opacity = 1) => {
  if (task.priority > 1) {
    const priority = taskPriorities.find(p => p.value === task.priority)
    const color = hexToRgb(priority.color)
    if (task.on_hold) return `repeating-linear-gradient(-45deg, rgba(${color.r},${color.g},${color.b},${opacity}), rgba(${color.r},${color.g},${color.b},${opacity}) 2px, rgba(248,248,255,0.2) 2px, rgba(248,248,255,0.2) 4px)`
    // if (task.on_hold) return `repeating-linear-gradient(-45deg, rgba(${color.r},${color.g},${color.b},${opacity}), rgba(${color.r},${color.g},${color.b},${opacity}) 5.5px, rgba(248,248,255,0.2) 5.5px, rgba(248,248,255,0.2) 27.5px)`
    return `rgba(${color.r},${color.g},${color.b},${opacity})`
  } else {
    if (task.on_hold) return 'repeating-linear-gradient(-45deg, rgba(164,164,164,0.2), rgba(164,164,164,0.2) 2px, rgba(248,248,255,0.2) 2px, rgba(248,248,255,0.2) 4px)'
    return null
  }
}

export const sortDateArray = (dateArray) => {
  return dateArray.sort((a, b) => new Date(a) - new Date(b))
}

export const getNextDate = (taskdatesSet) => {
  if (taskdatesSet.length === 0) return null
  const sortedDateArray = sortDateArray(taskdatesSet)
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  const nextDateObj = sortedDateArray.find(dateObj => {
    const formattedDate = new Date(dateObj.date)
    formattedDate.setHours(0, 0, 0, 0)
    return formattedDate >= today
  })

  return nextDateObj !== undefined ? nextDateObj.date : sortedDateArray[sortedDateArray.length - 1].date
}
