import React, { Fragment, useCallback, useState, useRef } from 'react'

import { PropTypes } from 'prop-types'
import { Container, Row } from 'reactstrap'

import { Typography, IconButton } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { userWithLabel } from './utils'

const grid = 8
const ITEM_HEIGHT = 52
const ITEM_MARGIN = 8

const getItemStyle = (tentative, itemStyle, isChosen, fixed) => {
  let style = {
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: `${grid}px ${grid * 2}px`,
    margin: `0 0 ${grid}px 0`,
    border: isChosen ? '2px solid black' : 'none'
  }

  if (fixed) {
    style.cursor = 'not-allowed'
  }

  if (tentative) {
    style = { ...style, opacity: 0.7, background: 'repeating-linear-gradient(-45deg, #a4a4a4, #a4a4a4 5.5px, #f8f8ff 5.5px, #f8f8ff 27.5px)' }
  } else {
    style = { ...style, background: '#f8f8ff' }
  }

  if (itemStyle) style = { ...style, ...itemStyle }
  return style
}

const getListStyle = (maxHeight) => ({
  background: '#DCDCDC',
  padding: grid,
  width: '250px',
  minWidth: '250px',
  height: 'fit-content'
})

const getFixedListStyle = () => ({
  overflowY: 'scroll',
  display: 'flex',
  alignItems: 'stretch',
  gap: '40px',
  position: 'fixed',
  maxWidth: '900px',
  minHeight: window.screen.height >= 1440 ? '94.5vh' : window.screen.height >= 1080 ? '92vh' : '87.5vh',
  maxHeight: window.screen.height >= 1440 ? '94.5vh' : window.screen.height >= 1080 ? '92vh' : '87.5vh',
  top: '32px',
  left: 0,
  background: '#FFF', // Or the appropriate color for your design
  zIndex: 3,
  paddingRight: '20px',
  borderRight: '1px solid #DCDCDC',
  scrollbarWidth: 'none !important',
  '&::-webkit-scrollbar': {
    display: 'none !important'
  }
})

const getNonFixedListStyle = (item) => ({
  zIndex: 2,
  display: 'flex',
  alignItems: 'stretch',
  gap: '40px',
  marginLeft: `${250 * item + 40 * item - 40}px`,
  minHeight: '87.5vh'
})

const getTaskStyle = () => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  maxHeight: '87.5vh'
})

const calculateTasksToFit = (items, maxHeight, headerHeight) => {
  const availableHeight = Math.max(0, maxHeight - headerHeight)
  const maxItems = Math.floor(availableHeight / (ITEM_HEIGHT + ITEM_MARGIN))
  return Math.min(items.length, maxItems)
}

const getListHeight = (headerHeight, itemCount) => {
  return itemCount * (ITEM_HEIGHT + ITEM_MARGIN) + headerHeight
}

const pushCurrentColumn = (columns, currentColumn) => {
  if (currentColumn.length > 0) {
    columns.push(currentColumn)
  }
  return []
}

export default function AssignTool ({
  allItems,
  lists,
  disabled = false,
  addItemToList,
  moveItemBetweenLists,
  removeItemFromList
}) {
  // list: {id: <id>, name: <name>, items: <items>, onClick: <func>}
  // item: {id: <id>, name: <name>, fixed: <true/false> }
  const [headerHeights, setHeaderHeights] = useState({})
  const [chosenItem, setChosenItem] = useState(null)
  const headerRefs = useRef({})

  const itemClickEvent = useCallback((item, srcList) => {
    setChosenItem((prev) => prev?.id === item.id ? null : { item, src: srcList.id ? srcList : null })
  }, [])

  const headerRef = (element, listId) => {
    if (element && !headerRefs.current[listId]) {
      headerRefs.current[listId] = element
      const height = element.getBoundingClientRect().height + 40
      setHeaderHeights((prev) => ({ ...prev, [listId]: height }))
    }
  }

  const isItemChosen = (list, item) => chosenItem && item.id === chosenItem.item.id && (list.id === chosenItem.src?.id || (list.id === null && chosenItem.src?.id === undefined))

  const renderListItems = (list, lIdx, maxHeight, chosenItem, handleAddItem, handleRemoveItem) => {
    const listItems = list.items

    return (
      <div key={`list-${lIdx}`} style={getListStyle(maxHeight)}>
        <Stack direction="row" spacing={1} ref={(element) => headerRef(element, list.id)}>
          <Typography className='secondary-textcolor' style={{ ...(list.onClick ? { cursor: 'pointer' } : {}) }} onClick={list.onClick}>{list.name}</Typography>
        </Stack>
        <br />
        {listItems.length > 0 &&
          listItems.map((item) => (
            <div
              key={`item-${list.id || 'all'}-${item.id}`}
              style={getItemStyle(
                !!item.tentative,
                item.style,
                isItemChosen(list, item),
                item.fixed
              )}
              onClick={(!disabled && !item.fixed) ? () => itemClickEvent(item, list) : null}
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: 0, padding: 0, border: 0 }}>
                {userWithLabel(item)}
                <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                  {!disabled && handleRemoveItem && isItemChosen(list, item) && (
                    <IconButton
                      sx={{
                        color: 'black',
                        fontSize: '1.5rem',
                        padding: 0
                      }}
                      onClick={() => handleRemoveItem(list)}
                    >
                      <RemoveCircleOutlineIcon fontSize='inherit' />
                    </IconButton>
                  )}
                {(item.number != null)
                  ? <span style={{ float: 'right', margin: 0, padding: 0, border: 0 }}>{item.number}</span>
                  : null
                }
                </div>
              </div>
            </div>
          ))}
          {!disabled && chosenItem && handleAddItem && (<Box display='flex' justifyContent='center'>
            <IconButton
              sx={{
                color: 'black',
                fontSize: '1.5rem',
                padding: 0
              }}
              onClick={() => handleAddItem(list)}
            >
              <AddCircleOutlineIcon fontSize='inherit' />
            </IconButton>
          </Box>)}
      </div>
    )
  }

  const renderNonFixedList = (nonFixedLists, chosenItem, handleAddItem, handleRemoveItem) => {
    const columns = []
    let currentColumn = []
    let currentHeight = 0
    const screenHeight = window.screen.height

    // 87.5 vh height limit
    const windowMaxHeight = screenHeight >= 1440 ? window.innerHeight * 0.92 : screenHeight >= 1080 ? window.innerHeight * 0.9 : window.innerHeight * 0.875

    nonFixedLists.forEach((list) => {
      let remainingItems = [...list.items]
      const headerHeight = headerHeights[list.id] || 60

      // Handle empty tasks
      if (remainingItems.length === 0) {
        const listHeight = getListHeight(headerHeight, 1)
        if (currentHeight + listHeight > windowMaxHeight) {
          currentColumn = pushCurrentColumn(columns, currentColumn)
          currentHeight = 0
        }
        currentColumn.push({ ...list, items: [], showAddButton: true })
        currentHeight += listHeight
        return
      }

      // Check if the entire list fits in the current column
      const listHeight = getListHeight(headerHeight, remainingItems.length + 1)
      if (currentHeight + listHeight <= windowMaxHeight) {
        currentColumn.push({ ...list, items: remainingItems, showAddButton: true })
        currentHeight += listHeight
        return
      }

      // Check if the entire list should be moved to a new column
      if (listHeight > windowMaxHeight - currentHeight) {
        currentColumn = pushCurrentColumn(columns, currentColumn)
        currentHeight = 0

        while (remainingItems.length > 0) {
          const itemsToFit = calculateTasksToFit(remainingItems, windowMaxHeight - currentHeight, headerHeight)

          if (itemsToFit === 0) {
            if (currentColumn.length > 0) {
              currentColumn = pushCurrentColumn(columns, currentColumn)
              currentHeight = 0
            }
            continue
          }

          const itemsForCurrentColumn = remainingItems.slice(0, itemsToFit)
          remainingItems = remainingItems.slice(itemsToFit)

          const newListHeight = getListHeight(headerHeight, itemsToFit + 1)

          if (currentHeight + newListHeight > windowMaxHeight) {
            currentColumn = pushCurrentColumn(columns, currentColumn)
            currentHeight = 0
          }

          currentColumn.push({ ...list, items: itemsForCurrentColumn, showAddButton: remainingItems.length === 0 })
          currentHeight += newListHeight
        }
      } else {
        currentColumn.push({ ...list, items: remainingItems, showAddButton: true })
        currentHeight += listHeight
      }
    })

    if (currentColumn.length > 0) {
      columns.push(currentColumn)
    }

    return columns.map((columnLists, colIndex) => (
      <Box key={`non-fixed-column-${columnLists[0].id}-col-${colIndex}`} style={getTaskStyle()}>
        {
          columnLists.map((list, lIdx) => {
            const headerHeight = headerHeights[list.id] || 60
            const listHeight = list.items.length * (ITEM_HEIGHT + ITEM_MARGIN) + headerHeight + 30
            return renderListItems(list, lIdx, listHeight, chosenItem, list.showAddButton ? handleAddItem : null, handleRemoveItem)
          })
        }
      </Box>
    ))
  }

  const handleAddItem = useCallback((list) => {
    if (chosenItem) {
      if (chosenItem.src) moveItemBetweenLists(chosenItem.item, chosenItem.src, list)
      else addItemToList(chosenItem.item, list)
      setChosenItem(null)
    }
  }, [chosenItem, addItemToList])

  const handleRemoveItem = useCallback((list) => {
    if (chosenItem) {
      removeItemFromList(chosenItem.item, list)
      setChosenItem(null)
    }
  }, [chosenItem, addItemToList])

  return (
    <Fragment>
      <Row>
        <Container>
          <Stack direction="row" spacing={5} alignItems="stretch" width="100%">
            {/* Fixed Lists */}
            {allItems && <Box style={getFixedListStyle()} className="hide-scrollbar">
              {renderListItems({ id: null, items: allItems }, 0, allItems.length * (ITEM_HEIGHT + ITEM_MARGIN) + 60, chosenItem, null, null)}
            </Box>}

            {/* Non-Fixed Lists */}
            <Box style={getNonFixedListStyle(allItems ? 1 : null)}>
              {renderNonFixedList(lists, chosenItem, handleAddItem, handleRemoveItem)}
            </Box>
          </Stack>
        </Container>
      </Row>
    </Fragment>
  )
}

AssignTool.propTypes = {
  allItems: PropTypes.arrayOf(PropTypes.object),
  lists: PropTypes.arrayOf(PropTypes.object),
  setLists: PropTypes.func,
  disabled: PropTypes.bool,
  addItemToList: PropTypes.func,
  moveItemBetweenLists: PropTypes.func,
  removeItemFromList: PropTypes.func,
  checkMoveValid: PropTypes.func
}
