import React, { Fragment, useEffect, useState } from 'react'

import axios from 'axios'
import { PropTypes } from 'prop-types'
import { Col, Container, Input, Row, Spinner } from 'reactstrap'

import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { IconButton, MenuItem, MenuList, Stack, Typography } from '@mui/material'

import Collapsible from '../elements/Collapsible'
import LoadingPage from '../elements/LoadingPage'
import { CustomButton, CustomIconButton } from '../elements/StyledElements'
import { hasPermission, sortById, useDidMountEffect, removeDuplicatesByKey } from '../elements/utils'
import { API_URL_CUSTOMER, API_URL_USER, API_URL_PRODUCT, API_URL_TODOTYPE, API_URL_TODOFILTER } from '../settings'
import CustomerFormModal from './customers/CustomerFormModal'
import CustomersTable from './CustomersTable'
import FilterCustomer from './customers/FilterCustomer'
import { projectTypes } from './project_types/projectUtils'
import { isMobileOnly } from 'react-device-detect'

const suggestions = [
  { id: 'name', value: 'Name enthält ' },
  { id: 'email', value: 'E-Mailadresse enthält ' },
  { id: 'zip_code', value: 'Postleitzahl beginnt mit ' },
  { id: 'customer_id', value: 'Kundennummer stimmt überein mit ' },
  { id: 'offer_id', value: 'Angebotsnummer stimmt überein mit ' },
  { id: 'project_id', value: 'Projektnummer stimmt überein mit ' },
  { id: 'invoice_id', value: 'Rechnungsnummer stimmt überein mit ' },
  { id: 'kwp', value: 'kWp des PV Projekts befindet sich im Bereich ' },
  { id: 'kwh', value: 'kWh des PV Projekts stimmt überein mit ' },
  { id: 'default', value: '' }
]

export default function Customers ({ session }) {
  const [customers, setCustomers] = useState(null)
  const [loaded, setLoaded] = useState(false)

  // pagination
  const [batch, setBatch] = useState(0)
  const [nPages, setNPages] = useState(null)

  // search flilter
  const [customerSearchInput, setCustomerSearchInput] = useState('')

  // open type filter
  const [openToDoTypes, setOpenToDoTypes] = useState([])
  const [openToDoTypeFilter, setOpenToDoTypeFilter] = useState(null)

  // salesmen filter
  const [salesmen, setSalesmen] = useState(null)
  const [salesmanFilter, setSalesmanFilter] = useState(['salesmen'].includes(session.user.group_key) ? session.user.id : null)

  // toDoFilter
  const [toDoFilter, setToDoFilter] = useState(null)
  const [toDoFilters, setToDoFilters] = useState([])

  // employee filter
  const [employeeFilter, setEmployeeFilter] = useState(null)
  const [employees, setEmployees] = useState([])

  // open task filter
  const [openTasksFilter, setOpenTasksFilter] = useState(null)

  // project filter
  const [projectTypeFilter, setProjectTypeFilter] = useState(null)
  const [products, setProducts] = useState([])
  const [productsFilter, setProductsFilter] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const options = projectTypes.map(p => ({ value: p.key, label: p.name }))
  const batchSize = 50

  const [searchSuggestion, setSearchSuggestion] = useState('default')
  const [debounceTimeout, setDebounceTimeout] = useState(null)

  const [isSearchInputFocused, setIsSearchInputFocused] = useState(false)

  useEffect(() => {
    resetState()
  }, [])

  const resetState = () => {
    setLoaded(false)
    Promise.all([getSalesmenAndEmployees(), getToDos(), getCustomers(0), getProducts(), getTodoTypes()]).then(() => setLoaded(true))
  }

  useEffect(() => {
    if (!loaded) return // run if 'customerSearchInput' changes, but not on initial render
    const delayDebounceFn = setTimeout(() => {
      getCustomers(0)
    }, 1500)

    setDebounceTimeout(delayDebounceFn)

    if (!customerSearchInput) {
      setSearchSuggestion('default')
    }

    return () => clearTimeout(delayDebounceFn)
  }, [customerSearchInput])

  useDidMountEffect(() => { // run if 'batch' changes, but not on initial render
    // cancel debounce timer
    clearTimeout(debounceTimeout)
    setLoaded(false)
    getCustomers(0).then(() => setLoaded(true))
  }, [salesmanFilter, employeeFilter, toDoFilter, projectTypeFilter, productsFilter, openToDoTypeFilter, openTasksFilter, searchSuggestion])

  const getCustomers = async (batch) => {
    const getKwpParams = () => {
      const split = customerSearchInput.split('-')
      const kwpStart = split[0]
      const kwpEnd = split.length === 2 ? split[1] : split[0]

      return {
        kwp_start: kwpStart.trim(),
        kwp_end: kwpEnd.trim(),
        search_mode: 'suggestions'
      }
    }
    setIsLoading(true)
    const params = {
      batch_size: batchSize,
      batch,
      salesman: salesmanFilter,
      search: (customerSearchInput) || null,
      nested: true,
      ascending_ids: false,
      included_todos: toDoFilter ? toDoFilter.included_todos.map(x => x.toString()).join() : null,
      excluded_todos: toDoFilter ? toDoFilter.excluded_todos.map(x => x.toString()).join() : null,
      employee: employeeFilter,
      products: productsFilter.length > 0 ? productsFilter.join(',') : null,
      project_type: projectTypeFilter?.toLowerCase(),
      open_todo_type: openToDoTypeFilter,
      open_tasks: openTasksFilter,
      ...(searchSuggestion !== 'default' && customerSearchInput && searchSuggestion !== 'kwp' && {
        [searchSuggestion]: customerSearchInput,
        search_mode: 'suggestions'
      }),
      ...(searchSuggestion !== 'default' && customerSearchInput && searchSuggestion === 'kwp' && getKwpParams())
    }
    return axios.get(API_URL_CUSTOMER, { params })
      .then(res => {
        setNPages(res.headers.length); setCustomers(res.data)
        setIsLoading(false)
        setBatch(batch)
      })
  }

  const getSalesmenAndEmployees = async () => {
    return axios.get(API_URL_USER, { params: { is_staff: true, visible: true, is_active: true } })
      .then(res => {
        setSalesmen(res.data
          .filter(user => ['salesmen', 'telemarketers', 'sales_managers', 'admins', 'managers', 'office'].includes(user.group_key))
        )
        setEmployees(res.data)
      })
  }

  const getToDos = async () => {
    return axios.get(API_URL_TODOFILTER).then(res => setToDoFilters(res.data))
  }

  const getProducts = async () => {
    axios.get(API_URL_PRODUCT)
      .then(
        res => setProducts(sortById(res.data)))
  }

  const getTodoTypes = async () => {
    return Promise.all([
      axios.get(API_URL_TODOTYPE, { params: { open: true } })
    ]).then(resList => {
      const todoTypes = removeDuplicatesByKey(resList.map(r => r.data).flat(), 'id')
      todoTypes.push({ id: 0, key: 'declined', name: 'Abgesagt' })
      setOpenToDoTypes(todoTypes)
    })
  }

  const handleProductsChange = (chosen) => {
    setProductsFilter(chosen)
    const selectedProductIds = chosen.map((option) => option.value)
    const updatedProducts = products.map((product) => ({
      ...product,
      isSelected: selectedProductIds.includes(product.id)
    }))

    setProducts(updatedProducts)
  }

  const handleNextBatch = async () => {
    setLoaded(false)
    await getCustomers(batch + 1)
    setLoaded(true)
  }

  const handlePrevBatch = async () => {
    setLoaded(false)
    await getCustomers(batch - 1)
    setLoaded(true)
  }

  const getRangeTextSearchSuggestion = () => {
    const split = customerSearchInput.split('-')
    if (split.length === 2) {
      return split[0] + ' - ' + split[1]
    }
    return split[0] + ' - ' + split[0]
  }

  return (
    <Fragment>
      {(!loaded) ? <LoadingPage /> : null}
      <Container style={{ marginTop: '30px' }}>
        {hasPermission(session.user, 'customer_handling')
          ? <Row style={{ paddingBottom: '20px' }}>
            <CustomerFormModal
              resetParent={resetState}
              session={session}
            />
          </Row>
          : null}
        <Stack direction={isMobileOnly ? 'column' : 'row'} spacing={isMobileOnly ? 1 : 0}>
          <Col>
            <Collapsible getOpenButton={(toggle) => <IconButton size="medium" disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }} onClick={toggle}><FilterAltOutlinedIcon style={{ color: '#424242' }} fontSize='medium' />
              <Typography className='secondary-textcolor'>Kunden filtern</Typography>
            </IconButton>}>
              <FilterCustomer
                session={session}
                openToDoTypes={openToDoTypes}
                setOpenToDoTypeFilter={setOpenToDoTypeFilter}
                openToDoTypeFilter={openToDoTypeFilter}
                setEmployeeFilter={setEmployeeFilter}
                employees={employees}
                employeeFilter={employeeFilter}
                setSalesmanFilter={setSalesmanFilter}
                salesmen={salesmen}
                salesmanFilter={salesmanFilter}
                setToDoFilter={setToDoFilter}
                toDoFilters={toDoFilters}
                toDoFilter={toDoFilter}
                setToDoFilters={setToDoFilters}
                setProjectTypeFilter={setProjectTypeFilter}
                options={options}
                projectTypeFilter={projectTypeFilter}
                handleProductsChange={handleProductsChange}
                products={products}
                productsFilter={productsFilter}
                openTasksFilter={openTasksFilter}
                setOpenTasksFilter={setOpenTasksFilter}
              />
            </Collapsible>
          </Col>
          <Col align="right">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexDirection: isMobileOnly ? 'column' : 'row', gap: 4 }}>
              <div style={ { flexBasis: '50%' } }>
                <Input
                  style={{ flex: 1, marginRight: '8px', backgroundColor: '#ffffff', color: '#424242', borderColor: '#424242', boxShadow: 'none' }}
                  type="text"
                  name="customerSearchInput"
                  onChange={e => setCustomerSearchInput(e.target.value)}
                  value={customerSearchInput}
                  placeholder="Kundensuche"
                  autoComplete="off"
                  onFocus={() => { setIsSearchInputFocused(true) }}
                  onBlur={async () => {
                    await new Promise(resolve => setTimeout(resolve, 200))
                    setIsSearchInputFocused(false)
                  }}
                />
                {isLoading && <Spinner color="dark" size="sm"/>}
                {customerSearchInput && isSearchInputFocused &&
                  <MenuList style={{ zIndex: '12', flexBasis: '50%', position: 'absolute', background: 'lightgrey', borderRadius: '2px', opacity: '0.85' }}>
                    {suggestions.map(suggestion => (
                      <MenuItem style={{ whiteSpace: 'normal', textAlign: 'left' }} key={suggestion.id} onClick={() => {
                        setSearchSuggestion(suggestion.id)
                        setIsSearchInputFocused(false)
                      }}>
                        <Typography>
                          { suggestion.id === searchSuggestion ? '✓' : ''} {suggestion.value}
                          { suggestion.id === 'kwp' ? '"' + getRangeTextSearchSuggestion() + '"' : '"' + customerSearchInput + '"' }
                        </Typography>
                      </MenuItem>
                    ))}
                  </MenuList>
                }
              </div>
              <CustomButton onClick={() => getCustomers(0)} icon='close'>Erneut Suchen </CustomButton>
            </div>
          </Col>
        </Stack>
        <br />
        <Row>
          <Col sm="10" md={{ size: 12, offset: -1 }}>
            {customers
              ? <CustomersTable customers={customers} session={session} batch={batch} batchSize={batchSize} />
              : null}
          </Col>
        </Row>
        <Row style={{ margin: '20px' }}>
          {nPages && nPages > 1
            ? <Stack direction="row" spacing={2}>
              <CustomIconButton
                disabled={batch === 0}
                icon='previous'
                onClick={handlePrevBatch}
              />
              <Typography className="secondary-textcolor">{batch + 1}</Typography>
              <CustomIconButton
                disabled={batch === nPages - 1}
                icon='next'
                onClick={handleNextBatch}
              />
            </Stack>
            : null}
          <br />
        </Row>
      </Container>
    </Fragment >
  )
}

Customers.propTypes = {
  session: PropTypes.object
}
