import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import axios from 'axios'

import CustomModal from '../../shared/modal_utils/CustomModal'
import SaveModalFooter from '../../shared/modal_utils/SaveModalFooter'
import { API_URL_INVERTER } from '../../../settings'
import { CustomButton } from '../../../elements/StyledElements'
import { inverterPropType } from '../../../elements/PropTypes'
import InverterForm from './InverterForm'

const emptyInverterForm = {
  id: null,
  name: null,
  power: null,
  manufacturer: null,
  certificate: null,
  data_sheet: null,
  default_idx: '',
  editable: true
}

export default function InverterFormModal ({ inverter = null, getOpenButton, resetParent, session, style }) {
  const [inverterForm, setInverterForm] = useState({ ...emptyInverterForm })
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false, showMissingFields: false
  })
  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle)
    return <CustomButton onClick={toggle} style={style}> + Wechselrichter hinzufügen </CustomButton>
  }

  const submit = async (inverterForm) => {
    const formData = new FormData()
    if (inverterForm.certificate && (inverterForm.certificate instanceof File || inverterForm.certificate instanceof Blob)) {
      formData.append('certificate', inverterForm.certificate)
    }
    for (const key in inverterForm) {
      if (!formData.has(key)) {
        const value = inverterForm[key]
        if (value === null || value === undefined || value === '') {
          formData.append(key, '')
        } else {
          formData.append(key, value)
        }
      }
    }
    if (inverter) {
      await axios.put(API_URL_INVERTER + inverter.id, formData)
      return
    }
    await axios.post(API_URL_INVERTER, formData)
  }

  const onSubmit = async (onSuccess) => {
    setLoadingElements({ ...loadingElements, inProgress: true, submitError: false, showMissingFields: false })
    return submit(inverterForm).then((res) => {
      resetParent()
      onSuccess()
      setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
    })
      .catch(error => {
        setLoadingElements({ ...loadingElements, submitError: true, inProgress: false })
        console.error(error)
      })
  }

  const getFooter = (toggle) => {
    return (
            <SaveModalFooter
                submitError={loadingElements.submitError}
                inProgress={loadingElements.inProgress}
                onSave={function () { onSubmit(toggle) }}
            />)
  }

  const onToggle = (isOpen) => {
    if (!isOpen) clearData()
    else loadData()
  }

  const clearData = () => {
    setLoadingElements({ inProgress: false, submitError: false, showMissingFields: false })
    setInverterForm({ ...emptyInverterForm })
  }

  const loadData = () => {
    setInverterForm(inverter || { ...emptyInverterForm })
  }

  return (<CustomModal getOpenButton={_getOpenButton} title="Wechselrichter" getFooter={getFooter} onToggle={onToggle}>
        <InverterForm
            inverter={inverterForm}
            setInverter={setInverterForm}
            session={session}
            showMissingFields={loadingElements.showMissingFields}
        />
    </CustomModal>)
}

InverterFormModal.propTypes = {
  inverter: inverterPropType,
  resetParent: PropTypes.func,
  getOpenButton: PropTypes.func,
  session: PropTypes.object,
  style: PropTypes.object
}
