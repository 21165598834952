/* eslint-disable camelcase */
import React, { Fragment, useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import axios from 'axios'

import { IconButton, Tooltip } from '@mui/material'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'

import { isString, filterObj, hasPermission, getErrorMessage } from '../../../../elements/utils'
import { CustomButton } from '../../../../elements/StyledElements'
import { API_URL_PLANNING, API_URL_CREATEPLANNING, API_URL_HEATPUMP, API_URL_HEATPUMPEMETERCABINETIMAGE, API_URL_HEATERTANKIMAGE, API_URL_HEATERIMAGE, API_URL_HEATPUMPCONTROLLERIMAGE, API_URL_HEATPUMPPLANNINGIMAGE, API_URL_HEATERDISTRIBUTORIMAGE } from '../../../../settings'
import CustomModal from '../../../shared/modal_utils/CustomModal'
import SaveModalFooter from '../../../shared/modal_utils/SaveModalFooter'
import { projectPropType, planningPropType } from '../../../../elements/PropTypes'
import CustomForm, { getCustomKeysFromTemplate, getCustomOptionsFromTemplate, getOptionalKeysFromTemplate } from '../../../../elements/CustomForm'
import { getHeatpumpPlanningFormTemplate } from './HeatpumpPlanningForm'
import { toast } from 'react-toastify'
import { debounce } from 'lodash'
import ErrorMessage from '../../../../elements/ErrorMessage'

const emptyPlanningForm = {
  project: null,
  heatpump: null,
  n_flats: null,
  n_flats_custom: null,
  n_people: null,
  n_people_custom: null,
  construction_year: null,
  funding: null,
  funding_amount: null,
  funding_deadline: null,
  funding_application_by_customer: null,
  pv: null,
  extra_e_meter: null,
  wallbox: null,
  n_wallboxes: null,
  n_wallboxes_custom: null,
  wallboxes_kw: null,
  wallboxes_kw_custom: null,
  n_flow_heater: null,
  n_flow_heater_custom: null,
  solar_thermal: null,
  remove_solar_thermal: null,
  replace_heating_circuit_distributor: null,
  current_system: null,
  current_system_custom: null,
  oil_tank: null,
  n_oil_tanks: null,
  n_oil_tanks_custom: null,
  oil_volume: null,
  disassembly_current_system: null,
  installation_date_current_system: null,
  heating_system: null,
  heating_circuit_distributor: null,
  n_heating_circuit_distributor: null,
  n_thermostats: null,
  thermostats_digital: null,
  replace_radiators: null,
  update_radiator_valves: null,
  n_radiator_valves: null,
  notes: null,
  heating_load_calculation_pdf: null,
  heatpumpemetercabinetimage_set: [],
  heatertankimage_set: [],
  heaterimage_set: [],
  heatpumpcontrollerimage_set: [],
  heatpumpplanningimage_set: [],
  heaterdistributorimage_set: [],
  resourcetype: 'HeatpumpPlanning'
}

export default function HeatpumpPlanningFormModal ({ project, planning, setPlanning, getOpenButton, resetParent, session }) {
  const [planningForm, setPlanningForm] = useState({ ...emptyPlanningForm })
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false, showMissingFields: false
  })
  const [heatpumps, setHeatpumps] = useState(null)

  const onToggle = (isOpen) => {
    if (isOpen) loadData()
    else clearData(); toast.dismiss()
  }

  const clearData = () => {
    setPlanningForm({ ...emptyPlanningForm })
    setLoadingElements({ inProgress: false, submitError: false, showMissingFields: false })
    setHeatpumps(null)
    if (setPlanning) setPlanning(null)
  }

  /* FILL ############################################################## */

  const getDefaultHeatpump = (heatpumps) => heatpumps.find(x => x.default) || null

  const loadData = async () => {
    setLoadingElements({ inProgress: false, submitError: false, showMissingFields: false })
    const { data: heatpumpData } = await axios.get(API_URL_HEATPUMP)
    const heatpumps = heatpumpData.filter(h => h.available || h.id === (planning ? h.heatpump : null))
    setHeatpumps(heatpumps)

    // fill Planning
    let filledPlanning = { ...emptyPlanningForm, project: project.id }
    const defaultHeatpump = getDefaultHeatpump(heatpumps)
    filledPlanning.heatpump = defaultHeatpump ? defaultHeatpump.id : null
    const optionList = getCustomOptionsFromTemplate(formTemplate)

    if (planning) {
      filledPlanning = { ...filledPlanning, ...planning }

      Object.keys(optionList).forEach(key => {
        const parentKey = key.replace('_custom', '')

        if (!optionList[key]?.options?.includes(filledPlanning[parentKey]) && filledPlanning[parentKey] !== null) {
          filledPlanning[key] = filledPlanning[parentKey]
          filledPlanning[parentKey] = optionList[key]?.options?.includes('custom') ? 'custom' : -1
        }
      })
    }
    setPlanningForm(filledPlanning)
  }

  /* UPLOADING ############################################################## */

  const submit = async (planningForm, onSaveClicked) => {
    const { heating_load_calculation_pdf, ...planningFormWithoutPdf } = planningForm
    const promise = planning
      ? await axios.put(API_URL_PLANNING + planning.id, planningFormWithoutPdf)
      : await axios.post(API_URL_PLANNING, planningFormWithoutPdf)

    const { data } = promise
    const { id } = data

    await getImagePromises(id, planningForm.heatpumpemetercabinetimage_set, API_URL_HEATPUMPEMETERCABINETIMAGE, getImagePromise)
    await getImagePromises(id, planningForm.heatertankimage_set, API_URL_HEATERTANKIMAGE, getImagePromise)
    await getImagePromises(id, planningForm.heaterimage_set, API_URL_HEATERIMAGE, getImagePromise)
    await getImagePromises(id, planningForm.heatpumpcontrollerimage_set, API_URL_HEATPUMPCONTROLLERIMAGE, getImagePromise)
    await getImagePromises(id, planningForm.heatpumpplanningimage_set, API_URL_HEATPUMPPLANNINGIMAGE, getPlanningImagePromise)
    await getImagePromises(id, planningForm.heaterdistributorimage_set, API_URL_HEATERDISTRIBUTORIMAGE, getImagePromise)

    if (heating_load_calculation_pdf && (heating_load_calculation_pdf instanceof File || heating_load_calculation_pdf instanceof Blob)) {
      const heatingLoadPdfForm = new FormData()
      heatingLoadPdfForm.append('heating_load_calculation_pdf', planningForm.heating_load_calculation_pdf)
      await axios.put(API_URL_PLANNING + id, heatingLoadPdfForm)
    }

    if (onSaveClicked) await axios.post(API_URL_CREATEPLANNING, { planning: id })
    return promise
  }

  const getImagePromises = async (planningId, images, url, getPromise) => {
    const deletePromise = (planning)
      ? axios.delete(url, { data: { planning: planningId, except_ids: images.filter(i => !isNewObj(i)).map(i => i.id) } })
      : Promise.resolve()
    return deletePromise
      .then(() => Promise.all(images.map(image => getPromise(planningId, image, url))))
  }

  const getImagePromise = async (planningId, image, url) => {
    const formData = new FormData()
    if (!isString(image.image)) {
      formData.append('image', image.image, image.image.name)
    } else return Promise.resolve()
    formData.append('planning', planningId)
    if (image.id < 0) return axios.post(url, formData)
    formData.append('id', image.id)
    return axios.put(url + image.id, formData)
  }

  const getPlanningImagePromise = async (planningId, image, url) => {
    const heatpumpImage = image
    const formData = new FormData()
    if (!planning) {
      if (!isString(heatpumpImage.image)) formData.append('image', heatpumpImage.image, heatpumpImage.image.name)
      else formData.append('image', heatpumpImage.image)
    } else if (!isString(heatpumpImage.image)) formData.append('image', heatpumpImage.image, heatpumpImage.image.name)
    if (!isString(heatpumpImage.default_image) && heatpumpImage.default_image) {
      formData.append('default_image', heatpumpImage.default_image, heatpumpImage.default_image.name)
    }
    formData.append('planning', planningId)
    formData.append('x', heatpumpImage.x)
    formData.append('y', heatpumpImage.y)
    heatpumpImage.scale_w !== null && formData.append('scale_w', heatpumpImage.scale_w)
    heatpumpImage.scale_h !== null && formData.append('scale_h', heatpumpImage.scale_h)
    heatpumpImage.image_width_edit !== null && formData.append('image_width_edit', heatpumpImage.image_width_edit)
    formData.append('angle', heatpumpImage.angle)

    return (heatpumpImage.id > 0)
      ? axios.put(url + heatpumpImage.id, formData)
      : axios.post(url, formData)
  }

  const isNewObj = (obj) => obj.id <= 0

  const onSubmit = async (onSuccess, onSaveClicked = false) => {
    let planningFormToSubmit = planningForm
    planningFormToSubmit = filterObj(planningFormToSubmit, Object.keys(emptyPlanningForm))
    const optionalKeys = getOptionalKeysFromTemplate(formTemplate, planningFormToSubmit)
    const customKeys = getCustomKeysFromTemplate(formTemplate, planningFormToSubmit)

    customKeys.forEach(key => {
      const parentKey = key.replace('_custom', '')
      if (planningFormToSubmit[parentKey] === 'custom' || planningFormToSubmit[parentKey] === -1) {
        planningFormToSubmit[parentKey] = planningFormToSubmit[key]
        delete planningFormToSubmit[key]
      } else {
        optionalKeys.push(key)
      }
    })

    Object.keys(planningForm)
      .filter(key => !Object.keys(emptyPlanningForm).includes(key))
      .forEach(key => optionalKeys.push(key))
    if (!planning) optionalKeys.push('id')
    const planningKeys = Object.keys(planningFormToSubmit).filter(x => !optionalKeys.includes(x))
    const checkIfEmpty = (key, val) => {
      return (
        val === null || val === '' || (Array.isArray(val) && val.length === 0) || val < 0
      )
    }
    const emptyFields = planningKeys.filter(key => checkIfEmpty(key, planningFormToSubmit[key]))
    if (onSaveClicked && emptyFields.length !== 0) {
      console.error('Following fields are missing: ', emptyFields)
      toast.error(<ErrorMessage message='Bitte alle Informationen eintragen!' />)
      setLoadingElements({ ...loadingElements, submitError: 'Bitte alle Informationen eintragen!', inProgress: false, showMissingFields: true })
      return
    }
    setLoadingElements({ ...loadingElements, inProgress: true, submitError: false, showMissingFields: false })
    if (planningFormToSubmit.n_inverters === null) planningFormToSubmit.n_inverters = ((planningFormToSubmit.kwp >= 17) ? 3 : ((planningFormToSubmit.kwp >= 13) ? 2 : 1))
    planningFormToSubmit.is_complete_click = onSaveClicked
    try {
      if (planningFormToSubmit.project === null) return
      const planningResponse = await submit(planningFormToSubmit, onSaveClicked)
      resetParent(planningResponse.data)
      if (!planning && setPlanning) setPlanning(planningResponse.data)
      if (onSaveClicked && onSuccess) onSuccess()
      setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
    } catch (error) {
      console.error('Error in HeatpumpPlanningFormModal:onSubmit', error, error.stack)
      toast.error(<ErrorMessage message={getErrorMessage(error)} />)
      setLoadingElements({ ...loadingElements, submitError: true, inProgress: false })
    }
  }

  /* MODAL ############################################################## */

  const _getOpenButton = (toggle) => {
    if (getOpenButton) return getOpenButton(toggle)
    if (planning && !setPlanning) {
      return (
        <Tooltip title={existOffer ? 'Sehen' : 'Bearbeiten'} PopperProps={{ style: { zIndex: 9999 } }} >
          <IconButton disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }} size="small" onClick={toggle}>
          {existOffer
            ? <SearchOutlinedIcon className='secondary-textcolor' fontSize='large' />
            : <EditOutlinedIcon className='secondary-textcolor' fontSize='large' />
            }
          </IconButton>
        </Tooltip>
      )
    }
    return <CustomButton onClick={toggle}>Neue Planung</CustomButton>
  }

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        id="submit-planning-form"
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        saveBtnLabel='Abschließen'
        onSave={() => { onSubmit(toggle, true) }}
        saveDisabled={!hasPermission(session.user, 'customer_handling') || existOffer}
      />
    )
  }

  useEffect(() => {
    const debounceSubmit = debounce(() => onSubmit(null, false), 2500)

    if (!existOffer) debounceSubmit()

    return () => {
      debounceSubmit.cancel()
    }
  }, [planningForm])

  const formTemplate = getHeatpumpPlanningFormTemplate(heatpumps)
  const existOffer = !!planning && project.baseoffer_set.some(offer => offer.planning === planning.id)

  return (<CustomModal size='fullscreen' getOpenButton={_getOpenButton} title="Planung" getFooter={getFooter} onToggle={onToggle}>
    {(heatpumps === null || planningForm === null)
      ? null
      : <Fragment>
        <CustomForm
          title='Wärmepumpenplanung'
          template={formTemplate}
          form={planningForm}
          setForm={setPlanningForm}
          showMissingFields={loadingElements.showMissingFields}
          defaultForm={emptyPlanningForm}
        />
      </Fragment>
    }
  </CustomModal>)
}

HeatpumpPlanningFormModal.propTypes = {
  project: projectPropType,
  planning: planningPropType,
  setPlanning: PropTypes.func,
  getOpenButton: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object
}
