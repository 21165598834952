import React, { Fragment, useEffect, useState } from 'react'

import axios from 'axios'
import { PropTypes } from 'prop-types'

import { projectPropType } from '../../../../elements/PropTypes'
import { CustomButton } from '../../../../elements/StyledElements'
import { filterObj, getCustomerName, getImagePromises, hasPermission, isString, removeFromObj, getSignaturePromise } from '../../../../elements/utils'
import {
  API_URL_CONSTRUCTION,
  API_URL_CONSTRUCTIONEMPLOYEESIG,
  API_URL_CONSTRUCTIONFRAMEWORKIMAGE,
  API_URL_CONSTRUCTIONPLANTIMAGE,
  API_URL_USER,
  API_URL_STRING, API_URL_ENERGIEIMPULSIMAGE,
  API_URL_WAREHOUSEPRODUCT, API_URL_WAREHOUSESUPPLIER
} from '../../../../settings'
import CustomModal from '../../../shared/modal_utils/CustomModal'
import SaveModalFooter from '../../../shared/modal_utils/SaveModalFooter'
import { getEmployeeSignatureUpdate, getPlantConstructionFormTemplate } from './PlantConstructionDocumentationForm'
import CustomForm, { getEmptyFieldsErrorFromTemplate } from '../../../../elements/CustomForm'
import { Typography } from '@mui/material'
import { Table } from 'react-bootstrap'

const emptyConstructionDocumentationForm = {
  project: null,
  documentation_n_modules: null,
  tv_is_working: false,
  inverter_communication_set_up: false,
  doorbell_is_working: false,
  neutral_conductor_checked: false,
  plant_checked: false,
  framework_checked: false,
  rotating_field_heat_pump: false,
  five_star_google_rating: false,
  emeter_before_image: null,
  emeter_after_image: null,
  inverter_image: null,
  construction_manager: null,
  electricians: [],
  documentation_completed: false,
  construction_manager_signature: null,
  electrician_signature: null,
  constructionframeworkimage_set: [],
  constructionplantimage_set: [],
  employees: [],
  employeesafetybriefingsignature_set: [],
  documentation_n_optimizers: null,
  n_additional_optimizers: null,
  roofimage_set: [],
  energieimpulsimage_set: [],
  constructiondates_set: []
}
const signatureKeys = ['construction_manager_signature', 'electrician_signature']
const imageKeys = ['emeter_before_image', 'emeter_after_image', 'inverter_image']
const imagesKeys = ['constructionframeworkimage_set', 'constructionplantimage_set', 'energieimpulsimage_set']
const signaturesKeys = ['employeesafetybriefingsignature_set']

export default function PlantConstructionDocumentationFormModal ({ project, disable, getOpenButton, resetParent, session }) {
  const [constructionDocumentationForm, setConstructionDocumentationForm] = useState(emptyConstructionDocumentationForm)
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false, showMissingFields: false
  })
  const [employees, setEmployees] = useState([])
  const [frameworkCheckedModal, setFrameworkCheckedModal] = useState(false)
  const [prevToggle, setPrevToggle] = useState(null)

  const [warehouseProducts, setWarehouseProducts] = useState([])
  const [suppliers, setSuppliers] = useState([])

  const getDefaultSupplierInfo = (warehouseProduct) => {
    const defaultSupplierId = warehouseProduct.amounts.find(amount => amount.location_id === 1)
    const defaultSupplier = warehouseProduct.suppliers.find(supplier => supplier.id === defaultSupplierId?.active_supplier_id)
    warehouseProduct.supplier_id = defaultSupplier?.supplier_id
    warehouseProduct.supplier_name = suppliers.find(supplier => supplier.id === defaultSupplier?.supplier_id)?.name
    warehouseProduct.supplier_product_name = defaultSupplier?.product_name
    warehouseProduct.supplier_product_id = defaultSupplier?.product_id
    warehouseProduct.supplier_price = defaultSupplier?.price
    return warehouseProduct
  }

  const construction = project.construction_obj
  const template = getPlantConstructionFormTemplate(project, constructionDocumentationForm, employees, session, warehouseProducts.map(getDefaultSupplierInfo))
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)

  useEffect(() => {
    axios.get(API_URL_WAREHOUSESUPPLIER).then(res => { setSuppliers(res.data) })
    axios.get(API_URL_WAREHOUSEPRODUCT).then(result => { setWarehouseProducts(result.data) })
  }, [])

  useEffect(() => {
    const documentationForm = { ...emptyConstructionDocumentationForm, ...construction, project: project.id }
    if (!!documentationForm.construction_manager && !documentationForm.employees.includes(documentationForm.construction_manager)) { documentationForm.employees = [...documentationForm.employees, construction.construction_manager] }
    if (documentationForm.electricians) {
      documentationForm.electricians.forEach(electrician => {
        if (!documentationForm.employees.includes(electrician)) {
          documentationForm.employees = [...documentationForm.employees, electrician]
        }
      })
    }
    const filteredEmployees = documentationForm.employees.filter(e => employees.map(_e => _e.id).includes(e))

    documentationForm.employeesafetybriefingsignature_set = getEmployeeSignatureUpdate(
      filteredEmployees, documentationForm.employeesafetybriefingsignature_set
    )
    setConstructionDocumentationForm(documentationForm)
  }, [employees])

  const loadData = () => {
    axios.get(API_URL_USER, { params: { is_staff: true, visible: true, is_active: true } })
      .then(res => {
        const groups = ['construction_managers', 'installers', 'installer_students', 'electricians', 'heating_installers', 'heating_installer_construction_manager']
        const employees = res.data
          .filter(user => groups.includes(user.group_key))
        setEmployees(employees)
      })
  }

  const clearData = () => {
    setConstructionDocumentationForm({ ...emptyConstructionDocumentationForm })
    setLoadingElements({ inProgress: false, submitError: false, showMissingFields: false })
    setEmployees([])
  }

  const _getOpenButton = (toggle) => {
    return getOpenButton
      ? getOpenButton(toggle)
      : <CustomButton disabled={disable} onClick={toggle} icon="documentation">Dokumentation</CustomButton>
  }

  const getConstructionSignaturePromise = async (val, key = null) => {
    return getSignaturePromise(val, 'sig.png', key)
  }

  const getConstructionImagePromise = (val, key = null) => {
    return (val && !isString(val))
      ? new Promise((resolve) => { resolve(key ? [key, val] : val) })
      : new Promise((resolve) => { resolve(key ? [key, null] : null) })
  }

  const submit = async (form) => {
    form = filterObj(form, Object.keys(emptyConstructionDocumentationForm))
    let _form = removeFromObj(form, [...signaturesKeys, ...imagesKeys])
    const keys = [...signatureKeys, ...imageKeys]
    keys.forEach(key => {
      _form = form[key] ? removeFromObj(_form, [key]) : _form
    })
    _form.documentation = true

    /**
     * Prepare form for put request by removing empty keys
     * @param {Record<string, unknown>} form
     * @returns Record<string, unknown> form
     */
    const preventEmptyKeysOnPutRequest = (form) => {
      const removeNullKeys = ['construction_manager_signature', 'electrician_signature']
      return Object.keys(form).reduce((acc, key) => {
        if (!removeNullKeys.includes(key) || (form[key] !== null)) {
          acc[key] = form[key]
        }
        return acc
      }, {})
    }

    const constructionPromise = construction
      ? axios.put(API_URL_CONSTRUCTION + construction.id, preventEmptyKeysOnPutRequest(_form))
      : axios.post(API_URL_CONSTRUCTION, _form)
    return constructionPromise.then((constructionRes) => {
      const promises = []
      promises.push(getImagePromises('construction', constructionRes.data.id, form.constructionframeworkimage_set, API_URL_CONSTRUCTIONFRAMEWORKIMAGE))
      promises.push(getImagePromises('construction', constructionRes.data.id, form.constructionplantimage_set, API_URL_CONSTRUCTIONPLANTIMAGE))
      promises.push(getImagePromises('construction', constructionRes.data.id, form.energieimpulsimage_set, API_URL_ENERGIEIMPULSIMAGE))
      const sigFilePromises = form.employeesafetybriefingsignature_set.map(sig => getConstructionSignaturePromise(sig.image).then(res => ({ ...sig, image: res })))
      promises.push(Promise.all(sigFilePromises).then(sigs => getImagePromises('construction', constructionRes.data.id, sigs, API_URL_CONSTRUCTIONEMPLOYEESIG, ['employee'])))

      const updateStrings = _form.roofimage_set.flatMap(roof =>
        roof.string_set.map(string =>
          axios.put(`${API_URL_STRING}${string.id}`, string)
        )
      )

      promises.push(...updateStrings)

      return Promise.all(promises)
        .then(() => Promise.all([
          ...signatureKeys.map((key) => getConstructionSignaturePromise(form[key], key)),
          ...imageKeys.map((key) => getConstructionImagePromise(form[key], key))
        ]).then(res => {
          const formData = new FormData()
          res
            .filter(([key, img]) => img)
            .forEach(([key, img]) => { formData.append(key, img) })
          formData.append('documentation', true)
          formData.append('form', true)
          return axios.put(API_URL_CONSTRUCTION + constructionRes.data.id, formData)
        }))
    })
  }

  const onSubmit = async (onSuccess, finalize, frameworkChecked = false) => {
    if (finalize) {
      const emptyFieldsError = getEmptyFieldsErrorFromTemplate(template, constructionDocumentationForm, emptyConstructionDocumentationForm)
      if (emptyFieldsError) {
        setLoadingElements({ ...loadingElements, submitError: 'Bitte alle Informationen eintragen!', inProgress: false, showMissingFields: true })
        console.error(emptyFieldsError)
        return
      }
      const plannedModules = project.construction_obj.n_vertical_panels + project.construction_obj.n_horizontal_panels
      const plannedNOptimizers = project.construction_obj.n_optimizers
      if (constructionDocumentationForm.documentation_n_modules !== plannedModules) {
        setLoadingElements({
          ...loadingElements,
          submitError: 'Die Anzahl gesetzter Module stimmt nicht mit der Anzahl in der Bauplanung überein!'
        })
        return
      }
      if (constructionDocumentationForm.documentation_n_optimizers !== plannedNOptimizers) {
        setConfirmationModalOpen(true)
        return
      }
      if (!frameworkChecked && !constructionDocumentationForm.framework_checked) {
        setFrameworkCheckedModal(true)
        setPrevToggle(() => onSuccess)
        return
      }
      constructionDocumentationForm.documentation_completed = true
    }
    setLoadingElements({ ...loadingElements, inProgress: true, submitError: false, showMissingFields: false })
    return submit(constructionDocumentationForm).then((res) => {
      if (resetParent) resetParent(res.data)
      onSuccess()
      setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
    }).catch(error => {
      console.error('Error in ConstructionFormModal:onSubmit', error, error.stack)
      setLoadingElements({ ...loadingElements, submitError: true, inProgress: false })
    })
  }

  const getFooter = (toggle) => {
    return (
      <>
      <CustomModal
      size='md'
      title={'Unterschiede zwischen Bauplanung & Baudokumentation'}
      isOpen={confirmationModalOpen}
      setIsOpen={setConfirmationModalOpen}
      >
        <Typography variant='h6'>
            <Table striped bordered hover style={{ marginTop: '30px' }}>
              <thead>
                <tr>
                  <th></th>
                  <th>Optimierer</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Bauplanung</td>
                  <td>{project.construction_obj.n_optimizers}</td>
                </tr>
                <tr>
                  <td>Baudokumentation</td>
                  <td>{constructionDocumentationForm.documentation_n_optimizers}</td>
                </tr>
              </tbody>
            </Table>
           <span style={{ color: 'red' }}>Die Anzahl der gesetzten Optimierer aus der Bauplanung & Baudokumentation unterscheidet sich. Unterschied bitte korrigieren.</span>
          </Typography>
      </CustomModal>
      <SaveModalFooter
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => onSubmit(toggle, false)}
        onComplete={hasPermission(session.user, 'finish_construction_documentation') ? () => onSubmit(toggle, true) : null}
        saveDisabled={!(hasPermission(session.user, 'customer_handling') || hasPermission(session.user, 'page_constructions')) || !(construction) || (construction.documentation_completed && !hasPermission(session.user, 'finish_construction_documentation'))}
      />
      </>
    )
  }

  const onToggle = (isOpen) => {
    if (isOpen) { loadData() } else clearData()
  }

  const title = (project) ? 'Dokumentation zur Bauplanung von ' + getCustomerName(project.customer_obj) : 'Baudokumentation'

  return (
    <Fragment>
      <CustomModal
          size='md'
          isOpen={frameworkCheckedModal}
          setIsOpen={setFrameworkCheckedModal}
          title="Gerüst"
          getFooter={(toggle) =>
            <SaveModalFooter
              onSave={() => { toggle(); setFrameworkCheckedModal(false); onSubmit(prevToggle, true, true) }}
              onCancel={() => setFrameworkCheckedModal(false)}
              saveBtnLabel='Bestätigen'
              cancelBtnLabel='Abbrechen'
            />}
      >
        <Typography fontSize='h6.fontSize'>
          Bitte bestätigen, dass das Gerüst nicht ordnungsgemäß aufgebaut wurde:
        </Typography>
      </CustomModal>

      <CustomModal size='fullscreen' getOpenButton={_getOpenButton} title={title} getFooter={getFooter} onToggle={onToggle}>
        <Fragment>
          <CustomForm
            title='Baudokumentation'
            template={template}
            form={constructionDocumentationForm}
            setForm={setConstructionDocumentationForm}
            defaultForm={emptyConstructionDocumentationForm}
            showMissingFields={loadingElements.showMissingFields}
          />
        </Fragment>
      </CustomModal>
    </Fragment>
  )
}

PlantConstructionDocumentationFormModal.propTypes = {
  project: projectPropType,
  disable: PropTypes.bool,
  getOpenButton: PropTypes.func,
  resetParent: PropTypes.func,
  session: PropTypes.object
}
