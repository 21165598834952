import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import axios from 'axios'

import { Box, Chip, Link, Typography } from '@mui/material'
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined'
import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined'

import { API_URL_TASK } from '../settings'
import LoadingPage from '../elements/LoadingPage'
import { hexToRGB, string2FormattedString } from '../elements/utils'
import AttachmentList from './tasks/AttachmentList'
import AddCommentModal from './tasks/AddCommentModal'
import AddAttachmentModal from './tasks/AddAttachmentModal'
import { AddPreviousTaskModal, AddFollowingTaskModal, getAddAttachmentIcon, getAddCommentIcon, StartedAction, FinishedAction, AcceptAction, FinishedConfirmationAction, getEditTaskIcon } from './tasks/TaskActions'
import CollapsibleTable from '../elements/CollapsibleTable'
import { getTaskEmployeeNames, getTaskStatus, taskPriorities } from './tasks/utils'
import TaskFormModal from './tasks/TaskFormModal'

// import SimulationHouseFormModal from './project/SimulationHouseFormModal'

export default function Task ({ session }) {
  const { search } = useLocation()
  const history = useHistory()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  const [task, setTask] = useState(null)
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false)
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false)
  const [isFinishedConfirmationModalOpen, setIsFinishedConfirmationModalOpen] = useState(false)

  const { id } = useParams()

  useEffect(() => { getTask() }, [id])

  useEffect(() => {
    if (task && searchParams.get('ablehnen') !== null && !isRejectModalOpen) {
      if (!task.finished && task.accepted !== false) setIsRejectModalOpen(true)
      searchParams.delete('ablehnen')
      history.push({ search: searchParams.toString() })
    }
    if (task && searchParams.get('bestaetigen') !== null && !isAcceptModalOpen) {
      if (!task.finished && !task.accepted) setIsAcceptModalOpen(true)
      searchParams.delete('bestaetigen')
      history.push({ search: searchParams.toString() })
    }
    if (task && searchParams.get('ueberpruefen') !== null && !isAcceptModalOpen) {
      if (!task.finished_confirmed && (task.registered_by === session.user.id || task.coordinator === session.user.id)) setIsFinishedConfirmationModalOpen(true)
      searchParams.delete('ueberpruefen')
      history.push({ search: searchParams.toString() })
    }
  }, [task, searchParams])

  const resetState = () => { getTask() }

  const getTask = () => {
    axios.get(API_URL_TASK + id, { params: { nested: true } })
      .then(res => setTask(res.data))
    // .catch(error => history.push('/'))
  }

  if (!task) return <LoadingPage />

  document.title = `PV App - ${task.title}`

  const priority = task && taskPriorities.find(p => p.value === task.priority)

  return (
    <Box marginLeft={0} marginTop={0} marginBottom={10}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
        <TaskFormModal
          task={task}
          getOpenButton={getEditTaskIcon}
          resetParent={resetState}
          session={session}
        />
      </Box>
      <CollapsibleTable
        collapsible={false}
        columns={[
          { key: 'key', name: '' },
          { key: 'value', name: '' },
          { key: 'actions', name: '', style: { textAlign: 'right' } }
        ]}
        rows={[
          { key: 'Titel', value: task.title },
          { key: 'Beschreibung', value: task.description },
          { key: 'Priorität', value: <Chip key={'task-priority'} label={priority.label} sx={{ backgroundColor: hexToRGB(priority.color, 0.7) }} size="small" /> },
          {
            key: 'Status',
            value: getTaskStatus(task),
            actions: <>
              <AcceptAction task={task} resetParent={resetState} session={session} isAcceptModalOpen={isAcceptModalOpen} setIsAcceptModalOpen={setIsAcceptModalOpen} isRejectModalOpen={isRejectModalOpen} setIsRejectModalOpen={setIsRejectModalOpen} />
              <StartedAction task={task} resetParent={resetState} session={session} />
              <FinishedAction task={task} resetParent={resetState} session={session} />
              <FinishedConfirmationAction task={task} resetParent={resetState} isOpen={isFinishedConfirmationModalOpen} setIsOpen={setIsFinishedConfirmationModalOpen} session={session} />
            </>
          },
          { key: 'Mitarbeiter', value: <Typography>{getTaskEmployeeNames(task, true)}</Typography> },
          task.taskdates_set && task.taskdates_set.length > 0 && {
            key: 'Termin',
            value: (
              <Typography>
                {task.taskdates_set.map(dateObj => string2FormattedString(dateObj.date)).join(', ')}
                {task.start_time ? ` ${task.start_time.slice(0, 5)}` : ''}
                {task.end_time ? `-${task.end_time.slice(0, 5)}` : ''}
                {task.reminder ? (<NotificationsOutlinedIcon fontSize="small" />) : (<NotificationsOffOutlinedIcon fontSize="small" />)}
              </Typography>
            )
          },
          !task.taskdates_set && task.deadline && { key: 'Frist', value: <Typography>{string2FormattedString(task.deadline)} {task.reminder ? <NotificationsOutlinedIcon fontSize="small"/> : <NotificationsOffOutlinedIcon fontSize="small"/>}</Typography> },
          task.customer && { key: 'Kunde', value: <Typography><Link href={`/kunde/${task.customer}`}>{task.customer_name}</Link></Typography> },
          task.project && { key: 'Projekt', value: <Typography><Link href={`/projekt/${task.project}`}>{task.project_name}</Link></Typography> },
          task.address && { key: 'Adresse', value: <Typography><Link href={'http://maps.google.com/maps?q="' + encodeURIComponent(task.address) + '"'}>{task.address}</Link></Typography> },
          { key: 'Vor Ort', value: task.on_site ? '\u2713' : '\u2715' },
          task.coordinator_name && { key: 'Koordinator', value: task.coordinator_name },
          task.task_type_obj && { key: 'Aufgaben-Typ', value: <Chip label={task.task_type_obj.name} sx={{ backgroundColor: hexToRGB(task.task_type_obj.color, 0.7) }} size="small" /> },
          { key: 'Labels', value: task.label_set.map((t, tIdx) => <Chip key={`task-label-${tIdx}`} label={t.name} sx={{ backgroundColor: hexToRGB(t.color, 0.7) }} size="small" />) },
          {
            key: 'Anhänge',
            value: task.taskattachment_set && task.taskattachment_set.length > 0 ? <AttachmentList task={task} header={false} session={session} /> : null,
            actions: <AddAttachmentModal task={task} getOpenButton={getAddAttachmentIcon} save={(c) => { setTask(t => ({ ...t, taskattachment_set: [...t.taskattachment_set, c] })) }} session={session} />
          },
          {
            key: 'Kommentare',
            value: task.taskcomment_set && task.taskcomment_set.length > 0 ? task.taskcomment_set.map((c, cIdx) => <Typography key={`taskcomment-${cIdx}`}><i>{c.author_name}:</i> {c.text}</Typography>) : null,
            actions: <AddCommentModal task={task} getOpenButton={getAddCommentIcon} save={(c) => { setTask(t => ({ ...t, taskcomment_set: [...t.taskcomment_set, c] })) }} session={session} />
          },
          {
            key: 'Vorherige Aufgaben',
            value: task.previous_task_set && task.previous_task_set.length > 0 ? task.previous_task_set.map((t, tIdx) => <Typography key={`previous-task-${tIdx}`}><Link href={`/aufgabe/${t.id}`}>{t.title}</Link></Typography>) : null,
            actions: <AddPreviousTaskModal task={task} resetParent={resetState} session={session} />
          },
          {
            key: 'Nachfolgende Aufgaben',
            value: task.next_tasks && task.next_tasks.length > 0 ? task.next_tasks.map((t, tIdx) => <Typography key={`next-task-${tIdx}`}><Link href={`/aufgabe/${t.id}`}>{t.title}</Link></Typography>) : null,
            actions: <AddFollowingTaskModal task={task} resetParent={resetState} session={session} />
          }
        ].filter(r => r)}
      />
    </Box>
  )
}

Task.propTypes = {
  session: PropTypes.object
}
