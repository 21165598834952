import React, { useState, useCallback, useRef } from 'react'
import { PropTypes } from 'prop-types'
import { Input, Form, FormGroup, Row, Col } from 'reactstrap'
import DatePicker from 'react-multi-date-picker'
import Icon from 'react-multi-date-picker/components/icon'
import DatePanel from 'react-multi-date-picker/plugins/date_panel'
import axios from 'axios'
import moment from 'moment'
import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import debounce from 'lodash/debounce'
import { toast } from 'react-toastify'

import { API_URL_TASK, API_URL_USER, API_URL_PROJECT, API_URL_TASKATTACHMENT, API_URL_TASKCOMMENT, API_URL_TASKTYPE, API_URL_TASKLABEL, API_URL_ADDPREVIOUSTASK } from '../../settings'
import {
  date2FormattedString,
  date2String,
  defaultIfEmpty,
  hexToRGB,
  popFromObj,
  string2Date,
  useDidMountEffect,
  gregorianDe,
  isValidForm,
  isPartialValidForm
} from '../../elements/utils'
import DropDown from '../../elements/DropDown'
import ConfirmationModalFooter from '../shared/modal_utils/ConfirmationModalFooter'
import CustomModal from '../shared/modal_utils/CustomModal'
import SaveModalFooter from '../shared/modal_utils/SaveModalFooter'
import { taskPropType } from '../../elements/PropTypes'
import MultiSelect from '../../elements/MultiSelect'
import './styles.css'
import AttachmentList from './AttachmentList'
import AddCommentModal from './AddCommentModal'
import AddAttachmentModal from './AddAttachmentModal'
import { taskPriorities } from './utils'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import ErrorMessage from '../../elements/ErrorMessage'

const emptyTaskForm = {
  title: '',
  description: '',
  project: null,
  employees: [],
  date: null,
  taskdates_set: null,
  deadline: null,
  reminder: true,
  responsible: null,
  priority: 0,
  start_time: '08:00',
  end_time: '17:00',
  on_site: true,
  finish_confirmation_required: false,
  taskattachment_set: [],
  taskcomment_set: [],
  previous_tasks: [],
  task_type: null,
  labels: [],
  started: false,
  finished: false,
  finished_confirmed: false,
  accepted: null
}

const mandatoryFields = ['title', 'priority', 'start_time', 'end_time']
const orFields = ['responsible', 'project']

export default function TaskFormModal ({ task, date, isOpen, setIsOpen, getOpenButton, resetParent, project, customer, previousTask, nextTask, session }) {
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false, showMissingFields: false
  })
  const [employees, setEmployees] = useState(null)
  const [projects, setProjects] = useState(null)
  const [taskTypes, setTaskTypes] = useState(null)
  const [taskLabels, setTaskLabels] = useState(null)
  const [projectSearchInput, setProjectSearchInput] = useState('')
  const [taskForm, setTaskForm] = useState({ ...emptyTaskForm })
  const [addDate, setAddDate] = useState(null)

  const [deletionModalIsOpen, setDeletionModalIsOpen] = useState(false)
  const [deletionLoadingElements, setDeletionLoadingElements] = useState({
    inProgress: false, submitError: false
  })

  const resetParentOnClose = useRef(false)
  const pickerRef1 = useRef(null)

  const getProjectBatch = (input = null, _customer = null, _taskForm = null) => {
    _taskForm = _taskForm || taskForm
    const params = {
      batch_size: 10,
      batch: 0,
      search: input,
      ascending_ids: false,
      customer: _customer,
      include: _taskForm?.project // [currentProjectId || null, taskForm?.project].filter(x => x)
    }
    axios.get(API_URL_PROJECT, { params })
      .then(res => { setProjects(res.data) })
  }

  const getOptionsDelayed = useCallback(
    debounce((text, callback) => {
      getProjectBatch(text)
    }, 1500),
    []
  )

  useDidMountEffect(() => {
    getOptionsDelayed(projectSearchInput)
  }, [projectSearchInput, getOptionsDelayed])

  useDidMountEffect(() => {
    if (!isOpen && resetParentOnClose.current) resetParent()
  }, [isOpen])

  const onToggle = (isOpen) => {
    if (!isOpen) clearData()
    else loadData()
  }

  const clearData = () => {
    setLoadingElements({ inProgress: false, submitError: false })
    setTaskForm({ ...emptyTaskForm })
    setEmployees(null)
    setProjects(null)
    setTaskTypes(null)
    setTaskLabels(null)
    setDeletionModalIsOpen(false)
    setAddDate(null)
  }

  const handleAddTimeCheckbox = (e) => {
    setAddDate(e.target.checked)
    if (!e.target.checked) {
      setTaskForm(b => ({ ...b, taskdates_set: [], start_time: '08:00', end_time: '17:00', deadline: null }))
      mandatoryFields.pop('taskdates_set')
    } else {
      setTaskForm(b => ({ ...b, taskdates_set: task ? task.taskdates_set : [{ date: date2String(date || new Date()) }], deadline: null }))
      mandatoryFields.push('taskdates_set')
    }
  }

  const loadData = () => {
    let newTaskForm
    if (task) {
      const _task = { ...task }
      if (!_task.taskdates_set && date) _task.taskdates_set = [{ date: date2String(date) }]
      newTaskForm = _task
    } else {
      newTaskForm = { ...emptyTaskForm, project: !project ? null : (project?.id || project), previous_tasks: previousTask ? [previousTask.id] : [] }
    }
    const _addDate = !(newTaskForm.deadline)
    if (!_addDate) {
      newTaskForm = { ...newTaskForm, taskdates_set: [] }
    }
    setTaskForm(newTaskForm)
    setAddDate(_addDate)

    axios.get(API_URL_USER, { params: { is_staff: true, visible: true, is_active: true } })
      .then(res => { setEmployees(res.data) })
    axios.get(API_URL_TASKTYPE)
      .then(res => { setTaskTypes(res.data) })
    axios.get(API_URL_TASKLABEL)
      .then(res => { setTaskLabels(res.data) })
    getProjectBatch(null, customer, newTaskForm)
  }

  const submit = async (taskForm) => {
    taskForm = { ...taskForm }
    const taskattachments = popFromObj(taskForm, 'taskattachment_set')
    const taskcomments = popFromObj(taskForm, 'taskcomment_set')
    const promise = task
      ? axios.put(API_URL_TASK + task.id, taskForm)
      : axios.post(API_URL_TASK, taskForm)
    return promise.then((res) => {
      const _task = res.data
      const promises = []
      taskattachments.filter(att => att.deleted).filter((att) => att.id && att.id > 0).forEach((att) => {
        promises.push(axios.delete(API_URL_TASKATTACHMENT + att.id))
      })
      if (!task) {
        taskattachments.filter(att => !att.deleted).filter((att) => !att.id || att.id < 0).forEach((att) => {
          const formData = new FormData()
          formData.append('task', _task.id)
          formData.append('name', att.name)
          formData.append('file', att.file, att.file.name)
          promises.push(axios.post(API_URL_TASKATTACHMENT, formData))
        })
        taskcomments.filter((c) => !c.id || c.id < 0).forEach(c => promises.push(axios.post(API_URL_TASKCOMMENT, { ...c, task: _task.id })))
      }

      if (nextTask) promises.push(axios.post(API_URL_ADDPREVIOUSTASK, { task: nextTask.id, previous_task: _task.id }))

      return Promise.all(promises).then(() => _task)
    })
  }

  const onSubmit = async (onSuccess) => {
    setLoadingElements({ ...loadingElements, inProgress: true, submitError: false, showMissingFields: false })
    if (!isValidForm(mandatoryFields, taskForm) || !isPartialValidForm(orFields, taskForm)) {
      setLoadingElements({ ...loadingElements, inProgress: false, submitError: false, showMissingFields: true })
      toast.error(<ErrorMessage message={'Bitte alle Felder ausfüllen!'} />)
      return
    }
    return submit(taskForm).then((res) => {
      if (resetParent) resetParent(res.data)
      if (onSuccess) onSuccess()
      setLoadingElements({ ...loadingElements, inProgress: false, submitError: false })
      toast.dismiss()
    }).catch(error => {
      console.error('Error in "Task:onSubmit"', error, error.stack)
      setLoadingElements({ ...loadingElements, submitError: true, inProgress: false, showMissingFields: true })
      if (error.response.data.title) {
        toast.error(<ErrorMessage message={'Bitte alle Felder ausfüllen!'} />)
      }
    })
  }

  const onDelete = async (onSuccess) => {
    setDeletionLoadingElements({ ...deletionLoadingElements, inProgress: true, submitError: false })
    return axios.delete(API_URL_TASK + task.id).then((res) => {
      if (resetParent) resetParent(res.data)
      if (onSuccess) onSuccess()
      setDeletionLoadingElements({ ...deletionLoadingElements, inProgress: false, submitError: false })
    }).catch(error => { console.error('Error in "Task:onDelete"', error, error.stack); setDeletionLoadingElements({ ...deletionLoadingElements, submitError: true, inProgress: false }) })
  }

  const getFooter = (toggle) => {
    return (
      <>
        <SaveModalFooter
          submitError={loadingElements.submitError}
          inProgress={loadingElements.inProgress}
          onSave={() => onSubmit(toggle)}
          onCancel={ () => { toast.dismiss(); toggle() } }
          onDelete={task ? () => { setDeletionModalIsOpen(true) } : null}
        // onSave={() => onSubmit(() => setIsOpen(false))}
        // onCancel={() => setIsOpen(false)}
        // onDelete={() => onDelete(() => { setDeletionModalIsOpen(false); setIsOpen(false) })}
        />
        <CustomModal
          size="small" title={'Aufgabe wirklich löschen?'}
          isOpen={deletionModalIsOpen}
          setIsOpen={(isOpen) => { if (!isOpen) setDeletionModalIsOpen(false) }}
          getFooter={() => getDeletionFooter()}
          onClose={() => setDeletionModalIsOpen(false)}></CustomModal>
      </>
    )
  }

  const getDeletionFooter = (toggle, toogleAll) => {
    return (
      <ConfirmationModalFooter
        submitError={deletionLoadingElements.submitError}
        inProgress={deletionLoadingElements.inProgress}
        onConfirm={() => onDelete(() => { setDeletionModalIsOpen(false); setIsOpen(false) })}
        onCancel={() => setDeletionModalIsOpen(false)}
        btnLabel={'Löschen'}
      />

    )
  }

  const onChange = e => {
    setTaskForm(f => ({ ...f, [e.target.name]: e.target.value }))
  }

  const handleOpenDatePicker = () => {
    if (pickerRef1.current) {
      pickerRef1.current.openCalendar()
    }
  }

  let modalTitle
  if (task) modalTitle = 'Aufgabe'
  else {
    if (previousTask) modalTitle = `Folgeaufgabe für "${previousTask.title}"`
    else if (nextTask) modalTitle = `Vorherige Aufgabe für "${nextTask.title}"`
    else modalTitle = `Neue Aufgabe${date ? ' am ' + date2FormattedString(date) : ''}`
  }
  // const modalTitle = `${task ? 'Aufgabe' : 'Neue Aufgabe'}${date ? ' am ' + date2FormattedString(date) : ''}`

  return (
    <CustomModal size="lg" isOpen={isOpen} setIsOpen={setIsOpen} getOpenButton={getOpenButton} title={modalTitle} getFooter={getFooter} onToggle={onToggle} onClose={(toggle) => {
      toast.dismiss(); toggle()
    }} >
      <Form>
        <FormGroup>
          <Typography className='secondary-textcolor'>Titel:</Typography>
          <div style={{ display: 'flex', gap: '0.5vw' }}>
            <Input
              id="input"
              placeholder="Titel"
              type="text"
              width={'15px'}
              value={taskForm.title}
              onChange={(e) => setTaskForm((x) => ({ ...x, title: e.target.value }))}
              style={{ boxShadow: 'none' }} />
            {loadingElements.showMissingFields && !taskForm.title.trim() && mandatoryFields.includes('title')
              ? <div>
                  &nbsp;&nbsp;
                  <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                </div>
              : null
            }
          </div>
        </FormGroup>
        <FormGroup>
          <Typography className='secondary-textcolor'>Beschreibung:</Typography>
          <Input
            id="input"
            type="textarea"
            name="description"
            onChange={onChange}
            value={defaultIfEmpty(taskForm.description)}
            required={true}
            style={{ boxShadow: 'none' }}
          />
        </FormGroup>
        {taskTypes && <FormGroup>
          <Typography className='secondary-textcolor'>Aufgaben-Typ:</Typography>
          <div style={{ display: 'flex', gap: '0.5vw' }}>
            <DropDown
              text={'Aufgaben-Typ'}
              onChange={value => setTaskForm(b => ({ ...b, task_type: value }))}
              options={taskTypes.map(t => ({ label: t.name, value: t.id, props: { style: { backgroundColor: hexToRGB(t.color, 0.5) } } }))}
              value={taskForm.task_type}
              sort={true}
              search={true}
              noOptionsText={'Keine Aufgaben-Typen'}
            />
            {loadingElements.showMissingFields && !taskForm.task_type && mandatoryFields.includes('task_type')
              ? <div>
                  &nbsp;&nbsp;
                  <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                </div>
              : null
            }
          </div>
        </FormGroup>}
        {taskLabels && <FormGroup>
          <Typography className='secondary-textcolor'>Labels:</Typography>
          <div style={{ display: 'flex', gap: '0.5vw' }}>
            <MultiSelect
              text={'Labels'}
              sort={true}
              options={taskLabels.map(t => ({ label: t.name, value: t.id, color: hexToRGB(t.color, 0.5) }))}
              values={taskForm.labels}
              onChange={chosen => setTaskForm(b => ({ ...b, labels: chosen }))}
            />
            {loadingElements.showMissingFields && taskForm.labels.length === 0 && mandatoryFields.includes('labels')
              ? <div>
                  &nbsp;&nbsp;
                  <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                </div>
              : null
            }
          </div>
        </FormGroup>}
        {employees && <FormGroup>
          <Typography className='secondary-textcolor'>{`Zuständiger Mitarbeiter${!taskForm.responsible && taskForm.project ? ' (optional)' : ''}:`}</Typography>
          <div style={{ display: 'flex', gap: '0.5vw' }}>
            <DropDown
              text={'Zuständig'}
              onChange={(value) => setTaskForm((x) => {
                let _employees = x.employees.filter(e => e !== x.responsible)
                if (!_employees.includes(value)) _employees = [value, ..._employees]
                return { ...x, responsible: value, employees: _employees }
              })}
              options={employees.map(e => ({ label: `${e.first_name} ${e.last_name}`, value: e.id, hidden: !e.is_active }))}
              value={taskForm.responsible}
              sort={true}
              search={true}
              noOptionsText={'Keine Mitarbeiter'}
            // onInputChange={(input, reason) => { if (reason === "input") setProjectSearchInput(input) }}
            />
            {loadingElements.showMissingFields && !taskForm.responsible && orFields.includes('responsible')
              ? <div>
                  &nbsp;&nbsp;
                  <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                </div>
              : null
            }
          </div>
        </FormGroup>}
        {employees && <FormGroup>
          <Typography className='secondary-textcolor'>Mitarbeiter:</Typography>
          <div style={{ display: 'flex', gap: '0.5vw' }}>
            <MultiSelect
              text={'Mitarbeiter'}
              sort={true}
              options={employees.map(e => ({ label: `${e.first_name} ${e.last_name}`, value: e.id, hidden: !e.is_active, disabled: e.id === taskForm.responsible }))}
              values={taskForm.employees}
              onChange={chosen => setTaskForm(b => ({ ...b, employees: chosen }))}
            />
            {loadingElements.showMissingFields && taskForm.employees.length === 0 && mandatoryFields.includes('employees')
              ? <div>
                  &nbsp;&nbsp;
                  <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                </div>
              : null
            }
          </div>
        </FormGroup>}
        {projects && <FormGroup>
          <Typography className='secondary-textcolor'>{`Projekt${!taskForm.project && taskForm.responsible ? ' (optional)' : ''}:`}</Typography>
          <div style={{ display: 'flex', gap: '0.5vw' }}>
            <DropDown
              text={'Projekt'}
              onChange={(value) => setTaskForm((x) => ({ ...x, project: value }))}
              options={projects.map(p => ({ label: p.title, value: p.id, hidden: p.hidden }))}
              value={taskForm.project}
              sort={true}
              search={true}
              noOptionsText={'Keine Projekte'}
              // onInputChange={(input) => getCustomerBatch(input)}
              onInputChange={(input, reason) => { if (reason === 'input') setProjectSearchInput(input) }}
            />
            {loadingElements.showMissingFields && !taskForm.project && orFields.includes('project')
              ? <div>
                  &nbsp;&nbsp;
                  <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                </div>
              : null
            }
          </div>
        </FormGroup>}
        {taskForm.project
          ? <FormGroup>
            <FormControlLabel
              checked={taskForm.on_site}
              className='secondary-textcolor'
              onChange={(e) => { setTaskForm(d => ({ ...d, on_site: e.target.checked })) }}
              control={<Checkbox disableFocusRipple disableRipple style={{ color: '#424242', backgroundColor: 'transparent' }} />}
              label={'Aufgabe muss beim Kunden vor Ort erledigt werden'} />
          </FormGroup>
          : null}
        <FormGroup>
          <div style={{ display: 'flex', gap: '0.5vw' }}>
            <DropDown
              text={'Priorität'}
              onChange={(value) => setTaskForm((x) => { return { ...x, priority: value } })}
              options={taskPriorities.map(t => ({ value: t.value, label: t.label, props: { style: { backgroundColor: hexToRGB(t.color, 1) } } }))}
              search={true}
              value={taskForm.priority}
            // onInputChange={(input, reason) => { if (reason === "input") setProjectSearchInput(input) }}
            />
            {loadingElements.showMissingFields && taskForm.priority === null && mandatoryFields.includes('priority')
              ? <div>
                  &nbsp;&nbsp;
                  <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                </div>
              : null
            }
          </div>
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            checked={addDate}
            className='secondary-textcolor'
            onChange={handleAddTimeCheckbox}
            control={<Checkbox disableFocusRipple disableRipple style={{ color: '#424242', backgroundColor: 'transparent' }} />}
            label={'Zeit hinzufügen'}
          />
        </FormGroup>
        {addDate &&
          <>
            <FormGroup>
            <Typography className='secondary-textcolor'>Datum:</Typography>
            <Row>
              <Col md="auto">
            <div className="custom-date-picker" style={{ maxWidth: 300, width: 'auto', minWidth: 150 }} onClick={handleOpenDatePicker}>
                  <div style={{ border: '1px solid #ccc', padding: '5px', minHeight: '40px', overflowY: 'auto' }}>
                    {taskForm.taskdates_set && taskForm.taskdates_set.map((taskDate, index) => {
                      const date = taskDate ? string2Date(taskDate.date) : null
                      const formattedDate = date ? moment(date).format('DD/MM/YYYY') : ''
                      return (<span key={index}>{formattedDate}{index !== taskForm.taskdates_set.length - 1 && ','} &nbsp;</span>)
                    })}
                  </div>
                </div>
              </Col>
              <Col md="auto">
              <DatePicker
                    ref={pickerRef1}
                    locale={gregorianDe}
                    weekStartDayIndex={1}
                    render={<Icon />}
                    format="DD/MM/YYYY"
                    multiple
                    showOtherDays
                    fixMainPosition={true}
                    calendarPosition='top-start'
                    value={taskForm.taskdates_set && taskForm.taskdates_set.length > 0 ? taskForm.taskdates_set.map(taskDate => taskDate ? string2Date(taskDate.date) : null) : null}
                    onChange={(dates) => {
                      const dateObjects = dates.map(date => ({ date: date2String(date.toDate()), task: taskForm.id }))
                      setTaskForm(x => ({
                        ...x,
                        taskdates_set: dateObjects
                      }))
                    }}
                    plugins={[<DatePanel key="date-panel" header='Daten'/>]}
                    sort={true}
                  />
                  {loadingElements.showMissingFields && taskForm.taskdates_set.length === 0 && mandatoryFields.includes('taskdates_set')
                    ? <div>
                        &nbsp;&nbsp;
                        <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                      </div>
                    : null
                  }
                </Col>
              </Row>
          </FormGroup>
            <FormGroup>
              <Typography className='secondary-textcolor'>Startzeit:</Typography>
              <div style={{ display: 'flex', gap: '0.5vw' }}>
                <Input
                  id="input"
                  type="time"
                  name="start_time"
                  value={taskForm.start_time.slice(0, 5)}
                  autoComplete="off"
                  onChange={onChange}
                  style={{ boxShadow: 'none', width: 250 }}
                />
                {loadingElements.showMissingFields && !taskForm.start_time && mandatoryFields.includes('start_time')
                  ? <div>
                      &nbsp;&nbsp;
                      <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                    </div>
                  : null
                }
              </div>
            </FormGroup>
            <FormGroup>
              <Typography className='secondary-textcolor'>Endzeit:</Typography>
              <div style={{ display: 'flex', gap: '0.5vw' }}>
                <Input
                  id="input"
                  type="time"
                  name="end_time"
                  value={taskForm.end_time.slice(0, 5)}
                  autoComplete="off"
                  onChange={onChange}
                  style={{ boxShadow: 'none', width: 250 }}
                />
                {loadingElements.showMissingFields && !taskForm.end_time && mandatoryFields.includes('end_time')
                  ? <div>
                      &nbsp;&nbsp;
                      <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                    </div>
                  : null
                }
              </div>
            </FormGroup>
          </>
        }
        {!(addDate) && <FormGroup>
          <Typography className='secondary-textcolor'>Frist:</Typography>
          <div style={{ display: 'flex', gap: '0.5vw' }}>
            <DatePicker
              format="DD/MM/YYYY"
              value={taskForm.deadline && string2Date(taskForm.deadline)}
              onChange={(date) => {
                const formattedDate = date.format('YYYY-MM-DD')
                setTaskForm(x => ({ ...x, deadline: formattedDate }))
              }}
            />
            {loadingElements.showMissingFields && !taskForm.deadline && mandatoryFields.includes('deadline')
              ? <div>
                  &nbsp;&nbsp;
                  <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                </div>
              : null
            }
          </div>
        </FormGroup>
        }
        <FormGroup>
          <FormControlLabel
            checked={taskForm.finish_confirmation_required}
            className='secondary-textcolor'
            onChange={(e) => { setTaskForm(d => ({ ...d, finish_confirmation_required: e.target.checked })) }}
            control={<Checkbox disableFocusRipple disableRipple style={{ color: '#424242', backgroundColor: 'transparent' }} />}
            label={'Aufgabenerfüllung muss überprüft werden'} />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            checked={taskForm.reminder}
            className='secondary-textcolor'
            onChange={(e) => { setTaskForm(d => ({ ...d, reminder: e.target.checked })) }}
            control={<Checkbox disableFocusRipple disableRipple style={{ color: '#424242', backgroundColor: 'transparent' }} />}
            label={taskForm.taskdates_set && taskForm.taskdates_set.length > 0 ? 'Terminerinnerung' : 'Fristerinnerung'} />
        </FormGroup>
        {(task && !taskForm.finished)
          ? <FormGroup>
            <FormControlLabel
              checked={taskForm.accepted}
              className='secondary-textcolor'
              onChange={(e) => { setTaskForm(d => ({ ...d, accepted: e.target.checked || null })) }}
              control={<Checkbox disableFocusRipple disableRipple style={{ color: '#424242', backgroundColor: 'transparent' }} />}
              label={'Aufgabe wurde akzeptiert'} />
          </FormGroup>
          : null}
        {task && !taskForm.finished && <FormGroup>
            <FormControlLabel
              checked={taskForm.started}
              className='secondary-textcolor'
              onChange={(e) => { setTaskForm(d => ({ ...d, started: e.target.checked })) }}
              control={<Checkbox disableFocusRipple disableRipple style={{ color: '#424242', backgroundColor: 'transparent' }} />}
              label={'Aufgabe wurde besprochen und angefangen'} />
          </FormGroup>}
        {task && <FormGroup>
          <FormControlLabel
            checked={taskForm.finished}
            className='secondary-textcolor'
            onChange={(e) => { setTaskForm(d => ({ ...d, finished: e.target.checked, finished_confirmed: e.target_checked ? d.finished_confirmed : false })) }}
            control={<Checkbox disableFocusRipple disableRipple style={{ color: '#424242', backgroundColor: 'transparent' }} />}
            label={'Aufgabe ist erledigt'} />
        </FormGroup>}
        {task && taskForm.finish_confirmation_required && <FormGroup>
          <FormControlLabel
            checked={taskForm.finished_confirmed}
            className='secondary-textcolor'
            onChange={(e) => { setTaskForm(d => ({ ...d, finished_confirmed: e.target.checked })) }}
            control={<Checkbox disableFocusRipple disableRipple style={{ color: '#424242', backgroundColor: 'transparent' }} />}
            label={'Erledigung der Aufgabe ist überprüft'} />
        </FormGroup>}
        <FormGroup>
          <Typography className='secondary-textcolor'>Anhänge:</Typography>
          <AttachmentList
            task={taskForm}
            header={false}
            deleteAttachment={(att) => { setTaskForm(f => ({ ...f, taskattachment_set: f.taskattachment_set.map(a => (a.id === att.id) ? { ...a, deleted: true } : a) })); resetParentOnClose.current = true }}
            session={session}
          />
          <AddAttachmentModal
            task={task}
            save={(att) => { setTaskForm(f => ({ ...f, taskattachment_set: [...f.taskattachment_set, att] })); resetParentOnClose.current = true }}
            session={session}
          />
        </FormGroup>
        <FormGroup>
          <Typography className='secondary-textcolor'>Kommentare:</Typography>
          {taskForm.taskcomment_set.map((c, cIdx) =>
            <Typography key={`task-comment-${cIdx}`}><i>{c.author_name || 'Unbekannt'}:</i> {c.text}</Typography>
          )}
          <AddCommentModal
            task={task}
            save={(c) => { setTaskForm(f => ({ ...f, taskcomment_set: [...f.taskcomment_set, c] })); resetParentOnClose.current = true }}
            session={session}
          />
        </FormGroup>
      </Form>
    </CustomModal >
  )
}

TaskFormModal.propTypes = {
  task: taskPropType,
  date: PropTypes.instanceOf(Date),
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  getOpenButton: PropTypes.func,
  resetParent: PropTypes.func,
  customer: PropTypes.object,
  project: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  previousTask: taskPropType,
  nextTask: taskPropType,
  session: PropTypes.object
}
