import React from 'react'

import { PropTypes } from 'prop-types'
import { IconButton, Typography } from '@mui/material'

import Icon from '../../elements/Icon'

export default function AddTileMobile ({ onClick }) {
  return (
    <IconButton disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }} onClick={onClick}>
      <Icon icon={'dashboard-customize'} className='secondary-textcolor' style={{ fontSize: 30 }} fontSize='large' />
      &nbsp;
      <Typography className='secondary-textcolor' fontSize='h6.fontSize'>Add New Tile</Typography>
    </IconButton>
  )
}
AddTileMobile.propTypes = {
  onClick: PropTypes.func
}
