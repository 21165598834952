import React, { useState, useEffect, useMemo } from 'react'
import { PropTypes } from 'prop-types'
import axios from 'axios'
import { API_URL_DASHBOARD_TILES } from '../settings'
import { safe, wrapCallback } from '../elements/utils'
import { Typography } from '@mui/material'
import { CustomIconButton } from '../elements/StyledElements'
import AddTileMobile from './dashboard/AddTileMobile'
import ModalTile from './dashboard/ModalTile'
import { TILE_TYPES, emptyFormData, preprocessItem, isTileTypeAllowed } from './dashboard/dashboard-utils'
import CustomizedCustomer from './dashboard/CustomizedCustomer'

export default function DashboardDetail ({ session }) {
  const [dashboardTiles, setDashboardTiles] = useState([])
  const [index, setIndex] = useState(0)
  const [modalState, setModalState] = useState({
    isOpen: false,
    item: null
  })

  const getTiles = () => {
    return axios.get(API_URL_DASHBOARD_TILES)
  }

  const fetchDashboardTiles = async () => {
    const response = await getTiles()
    const preprocessed = (response?.data || []).map(preprocessItem)
    setDashboardTiles(preprocessed)
  }
  useEffect(() => {
    fetchDashboardTiles()
  }, [])

  useEffect(() => {
    const dashboardLength = (dashboardTiles || []).length
    if (index >= dashboardLength) {
      const newIdx = ((dashboardLength - 1) >= 0) ? (dashboardLength - 1) : 0
      setIndex(newIdx)
    }
  }, [dashboardTiles])

  const createTile = wrapCallback((payload) => {
    return axios.post(API_URL_DASHBOARD_TILES, payload)
  }, fetchDashboardTiles)
  const updateTile = wrapCallback((id, payload) => {
    return axios.put(API_URL_DASHBOARD_TILES + id, payload)
  }, fetchDashboardTiles)
  const deleteTile = wrapCallback((id) => {
    return axios.delete(API_URL_DASHBOARD_TILES + id)
  }, fetchDashboardTiles)

  // get url query parameter `index`
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const idx = urlParams.get('index')
    const { data, err } = safe(() => parseInt(idx))

    if (err) return
    if (idx) setIndex(data)
  }, [])

  const getAllowedTileTypes = (session) => {
    return Object.keys(TILE_TYPES).filter(key => isTileTypeAllowed(session, TILE_TYPES[key].key))
  }
  const allowedTileTypes = getAllowedTileTypes(session)

  const NEW_TILE = () => {
    return {
      _flag: 'draft',
      type: allowedTileTypes.length > 0 ? TILE_TYPES[allowedTileTypes[0]].key : null,
      data: allowedTileTypes.length > 0 ? emptyFormData(session, TILE_TYPES[allowedTileTypes[0]].key) : null
    }
  }

  const setSelectedItem = (item) => {
    setModalState(prev => ({ ...prev, item, isOpen: true }))
  }

  const customerParams = useMemo(() => {
    return {
      ...(dashboardTiles?.[index]?.data?.filters || {})
    }
  }, [dashboardTiles, index])

  const onEdit = () => {
    setSelectedItem(dashboardTiles?.[index])
  }

  return (
    <div>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <AddTileMobile onClick={() => {
          setSelectedItem(NEW_TILE())
        }} />

        <div style={{
          display: 'flex'
        }}>
          <CustomIconButton icon='settings' onClick={onEdit} />
          <CustomIconButton icon='bin' onClick={() => deleteTile(dashboardTiles?.[index]?.id)} />
        </div>
      </div>
      {(dashboardTiles || []).length > 1 && (
        <div style={{
          display: 'flex',
          justifyContent: 'center'
        }}>
          <CustomIconButton
            disabled={index === 0}
            icon='previous'
            onClick={() => setIndex(index - 1)}
          />
          <Typography
            className="secondary-textcolor"
            style={{
              textAlign: 'center'
            }}
          >{index + 1}</Typography>
          <CustomIconButton
            disabled={index === (dashboardTiles || []).length - 1}
            icon='next'
            onClick={() => setIndex(index + 1)}
          />
        </div>)}
      {(dashboardTiles || []).length === 0 && (
        <Typography
          variant='h5'
          style={{
            textAlign: 'center',
            marginTop: '20px'
          }}
        >
          Keine Kacheln gefunden
        </Typography>
      )}
      {(dashboardTiles || [])?.[index] && (
        <div style={{
          width: '100%'
        }}>
          <Typography
            variant='h5'
            style={{
              textAlign: 'center'
            }}
          >
            {dashboardTiles?.[index]?.name}
          </Typography>
          {
            dashboardTiles?.[index]?.type === TILE_TYPES.CUSTOM_FILTER_CUSTOMER.key && (
              <div
                style={{
                  width: '100%'
                }}
              >
                <CustomizedCustomer
                  session={session}
                  params={customerParams}
                />
              </div>
            )
          }
        </div>
      )}

      <ModalTile
        session={session}
        isOpen={modalState?.isOpen}
        setIsOpen={(isOpen) => setModalState(prev => ({ ...prev, isOpen }))}
        form={modalState?.item}
        setForm={(form) => {
          const _form = (typeof form === 'function')
            ? form(modalState?.item)
            : form
          setModalState(prev => ({ ...prev, item: _form }))
        }}
        refresh={fetchDashboardTiles}
        createTile={createTile}
        updateTile={updateTile}
        emptyFormData={emptyFormData}
        allowedTileTypes={allowedTileTypes}
      />
    </div>
  )
}

DashboardDetail.propTypes = {
  session: PropTypes.object
}
