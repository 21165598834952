import React, { Fragment, useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { Spinner, Container, Row, Col, Input } from 'reactstrap'
import { Stage, Layer, Image } from 'react-konva'
import Collapsible from 'react-collapsible'
import axios from 'axios'

import { Typography, Grid } from '@mui/material'
import Stack from '@mui/material/Stack'

import { API_URL_OPTIMIZATION } from '../../../../settings'
import { CustomButton } from '../../../../elements/StyledElements'
import { getImageWindowWithAuth, defaultIfEmpty, getErrorMessage } from '../../../../elements/utils'
import { planningPropType } from '../../../../elements/PropTypes'
import { toast } from 'react-toastify'
import ErrorMessage from '../../../../elements/ErrorMessage'

const planningKeyNeededForOptimization = [
  'project', 'module', 'e_meter', 'e_meter_cabinet', 'big_e_meter_cabinet', 'ground_anchor', 'wallbox', 'wallbox_required', 'heatpump',
  'emergency_socket', 'emergency_house', 'pv_setbycustomer', 'consumption_house', 'consumption_heatpump',
  'consumption_car', 'merge_circuits', 'move_satellite_dish', 'special_installation', 'circuit_preparation',
  'rooftiles', 'tenant_system', 'grid_plant_protection_available', 'converter_measurement_available',
  'n_tenants', 'tenant_model', 'large_household_consumers'
]

const MAXSTAGEWIDTH = 500
const MAXSTAGEHEIGHT = 1200

export default function Optimization ({ planning, setPlanning, session }) {
  const [roiImage, setRoiImage] = useState({
    imageWindow: null, scaler: null, stageWidth: null, stageHeight: null
  })

  const [independenceImage, setIndependenceImage] = useState({
    imageWindow: null, scaler: null, stageWidth: null, stageHeight: null
  })

  const [loadingElements, setLoadingElements] = useState({
    errorMsg: null, inProgress: false
  })

  const [pvRange, setPvRange] = useState({ min_pv: 0, max_pv: 30, step_pv: 1 })
  const [batRange, setBatRange] = useState({ min_bat: 7.5, max_bat: 25, step_bat: 2.5 })

  useEffect(() => {
    if (planning && planning.optimization_obj && planning.optimization_obj.roi_image) loadImage(planning.optimization_obj.roi_image, setRoiImage)
    else {
      setRoiImage({
        imageWindow: null, scaler: null, stageWidth: null, stageHeight: null
      })
    }
  }, [planning.optimization_obj])

  useEffect(() => {
    if (planning && planning.optimization_obj && planning.optimization_obj.independence_image) loadImage(planning.optimization_obj.independence_image, setIndependenceImage)
    else {
      setIndependenceImage({
        imageWindow: null, scaler: null, stageWidth: null, stageHeight: null
      })
    }
  }, [planning.optimization_obj])

  const loadImage = (src, setImage) => {
    getImageWindowWithAuth(src, (newImageWindow) => handleLoad(newImageWindow, setImage))
  }

  const handleLoad = (imageWindow, setImage) => {
    const scalerW = MAXSTAGEWIDTH / imageWindow.width
    const scalerH = MAXSTAGEHEIGHT / imageWindow.height
    const scaler = (scalerW >= scalerH) ? scalerH : scalerW
    const stageWidth = imageWindow.width * scaler
    const stageHeight = imageWindow.height * scaler
    setImage({ scaler, stageWidth, stageHeight, imageWindow })
  }

  const getPlanningInfo = () => {
    const neededPlanningInfo = Object.fromEntries(Object.entries(planning).filter(([key]) => planningKeyNeededForOptimization.includes(key)))
    neededPlanningInfo.move_satellite_dish = (neededPlanningInfo.move_satellite_dish !== null) ? neededPlanningInfo.move_satellite_dish : false
    neededPlanningInfo.special_installation = (neededPlanningInfo.special_installation !== null) ? neededPlanningInfo.special_installation : false
    neededPlanningInfo.grid_plant_protection_available = (neededPlanningInfo.grid_plant_protection_available !== null) ? neededPlanningInfo.grid_plant_protection_available : false
    neededPlanningInfo.converter_measurement_available = (neededPlanningInfo.converter_measurement_available !== null) ? neededPlanningInfo.converter_measurement_available : false
    neededPlanningInfo.large_household_consumers = (neededPlanningInfo.large_household_consumers !== null) ? neededPlanningInfo.large_household_consumers : false
    if (!neededPlanningInfo.tenant_system) {
      neededPlanningInfo.n_tenants = 1
      neededPlanningInfo.tenant_model = 0
    }
    if (Object.values(neededPlanningInfo).some(x => (x === null) || (x === ''))) {
      console.error('missing informations for optimization: ', Object.keys(neededPlanningInfo).filter(k => (neededPlanningInfo[k] === null) || (neededPlanningInfo[k] === '')))
      return null
    }
    return neededPlanningInfo
  }

  const executeOptimization = () => {
    const planningInfo = getPlanningInfo()
    if (planningInfo) {
      setLoadingElements({ inProgress: true, errorMsg: false })
      axios.post(API_URL_OPTIMIZATION, { project: planning.project, n_additional_emeters: planning.n_additional_emeters, n_horizontal_panels: 0, n_vertical_panels: 0, n_optimizers: 0, electricity_price: planning.electricity_price, n_tenants: planning.n_tenants, smartmeter_rent: planning.smartmeter_rent, ...planningInfo, ...pvRange, ...batRange }).then(res => {
        setLoadingElements({ inProgress: false, errorMsg: false })
        const optimization = res.data
        setPlanning(p => ({
          ...p,
          optimization: optimization.id,
          optimization_obj: optimization
        }))
      }
      ).catch(error => {
        toast.error(<ErrorMessage message={getErrorMessage(error)} />)
        console.error('Error in Optimization.post:', error, error.stack)
        setLoadingElements({ inProgress: false, errorMsg: 'Anfrage konnte nicht an Server übermittelt werden.' })
      })
    } else setLoadingElements({ inProgress: false, errorMsg: 'Bitte alle nötigen Informationen ausfüllen!' })
  }

  return (
    <Fragment>

      <div className='secondary-textcolor'>
        <Collapsible trigger="[Einstellungen öffnen]" triggerWhenOpen="[Einstellungen schließen]">
          <>
            <Typography className='secondary-textcolor'>Anlagengröße</Typography>
            <Stack direction="row" spacing={2}>
              {['min_pv', 'max_pv', 'step_pv'].map((key) =>
                <Stack key={`optimization-pv-settings-${key}`} direction="row" spacing={2}><>
                  <Typography className='secondary-textcolor'>
                    {key === 'min_pv' ? 'min kWp' : key === 'max_pv' ? 'max kWp' : 'kWp-Schritt'}
                  </Typography>
                  <Input
                    id="input"
                    style={{ boxShadow: 'none', width: '100px' }}
                    type="number"
                    name={key}
                    value={defaultIfEmpty(pvRange[key])}
                    min="0"
                    onKeyPress={(e) => { if (e.code === 'Minus') { e.preventDefault() } }}
                    autoComplete="off"
                    onChange={(e) => setPvRange(x => ({ ...x, [key]: e.target.valueAsNumber }))}
                    onKeyDown={(e) => { if (e.key === '-') e.preventDefault() }}
                  /></>
                </Stack>)}
            </Stack>
            <br /> <br />
            <Typography className='secondary-textcolor'>Batterie</Typography>
            <Stack direction="row" spacing={2}>
              {['min_bat', 'max_bat', 'step_bat'].map((key) =>
                <Stack key={`optimization-battery-settings-${key}`} direction="row" spacing={2}><>
                  <Typography className='secondary-textcolor'>
                    {key === 'min_bat' ? 'min kWh' : key === 'max_bat' ? 'max kWh' : 'kWh-Schritt'}
                  </Typography>
                  <Input
                    id="input"
                    style={{ boxShadow: 'none', width: '100px' }}
                    type="number"
                    name={key}
                    value={defaultIfEmpty(batRange[key])}
                    min="0"
                    onKeyPress={(e) => { if (e.code === 'Minus') { e.preventDefault() } }}
                    autoComplete="off"
                    onChange={(e) => setBatRange(x => ({ ...x, [key]: e.target.valueAsNumber }))}
                    onKeyDown={(e) => { if (e.key === '-') e.preventDefault() }}
                  /></>
                </Stack>)}
            </Stack>
            <br /> <br />
            <div>
      <Grid container spacing={2}>
        <Grid item>
          <Typography className='secondary-textcolor'>Strompreis [Cent]</Typography>
          <Stack direction="row" spacing={2}>
            <Stack key={'optimization-electricity-price-settings'} direction="row" spacing={2}>
              <Input
                id="input1"
                style={{ boxShadow: 'none', width: '100px' }}
                type="number"
                name="electricity_price"
                value={planning.electricity_price * 100}
                min="0"
                onKeyPress={(e) => { if (e.code === 'Minus') { e.preventDefault() } }}
                autoComplete="off"
                onChange={(e) => setPlanning(p => ({ ...p, electricity_price: e.target.valueAsNumber / 100 }))}
                onKeyDown={(e) => { if (e.key === '-') e.preventDefault() }}
              />
            </Stack>
          </Stack>
        </Grid>
        {planning.tenant_system && (planning.tenant_model === 0 || planning.tenant_model === 1) && (
  <>
    <Grid item>
      <Typography className='secondary-textcolor'>Mieter</Typography>
      <Stack direction="row" spacing={2}>
        <Input
          id="input1"
          style={{ boxShadow: 'none', width: '100px' }}
          type="number"
          name="n_tenants"
          value={planning.n_tenants}
          disabled= {true}
          onChange={(e) => setPlanning(p => ({ ...p, electricity_price: e.target.valueAsNumber / 100 }))}
          onKeyDown={(e) => { if (e.key === '-') e.preventDefault() }}
        />
      </Stack>
    </Grid>
    <Grid item>
      <Typography className='secondary-textcolor'>Smartmeter Miete [Euro]</Typography>
      <Stack direction="row" spacing={2}>
        <Input
          id="input2"
          style={{ boxShadow: 'none', width: '100px' }}
          type="number"
          name="smartmeter_rental"
          value={planning.smartmeter_rent !== null ? planning.smartmeter_rent : (planning.tenant_model === 0 ? 10 : 0) }
          min="0"
          onKeyPress={(e) => { if (e.code === 'Minus') { e.preventDefault() } }}
          autoComplete="off"
          onChange={(e) => setPlanning(p => ({ ...p, smartmeter_rent: e.target.valueAsNumber }))}
          onKeyDown={(e) => { if (e.key === '-') e.preventDefault() }}
        />
      </Stack>
    </Grid>
  </>
        )}
      </Grid>
    </div>
          </>
        </Collapsible>
      </div>
      <br />
      <CustomButton id="start-optimization-btn" disabled={loadingElements.inProgress} onClick={executeOptimization}> {loadingElements.inProgress ? 'Simulation läuft, bitte warten...' : 'Simulation starten'} </CustomButton>
      &nbsp;
      {loadingElements.errorMsg ? <div><br /> <Typography fontSize='h6.fontSize' className='secondary-textcolor'>{loadingElements.errorMsg}</Typography> </div> : null}
      {loadingElements.inProgress ? <Spinner color="dark" size="sm" /> : null}
      <Container>
        <Row>
          <Col>
            {roiImage.imageWindow
              ? (
              <div id="roi-image-optimization">
                <Stage width={roiImage.stageWidth} height={roiImage.stageHeight} listening={false}>
                  <Layer>
                    <Image
                      id='optimization_roi'
                      image={roiImage.imageWindow}
                      scaleX={roiImage.scaler}
                      scaleY={roiImage.scaler}
                    />
                  </Layer>
                </Stage>
              </div>
                )
              : null
            }
          </Col>
          <Col>
            {independenceImage.imageWindow
              ? (
              <div id="independence-image-optimization">
                <Stage width={independenceImage.stageWidth} height={independenceImage.stageHeight} listening={false}>
                  <Layer>
                    <Image
                      id='optimization_independence'
                      image={independenceImage.imageWindow}
                      scaleX={independenceImage.scaler}
                      scaleY={independenceImage.scaler}
                    />
                  </Layer>
                </Stage>
              </div>
                )
              : null
            }
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

Optimization.propTypes = {
  planning: planningPropType,
  setPlanning: PropTypes.func,
  session: PropTypes.object
}
