/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Container } from 'reactstrap'
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'
import { Stack, Typography } from '@mui/material'
import axios from 'axios'
import { toast } from 'react-toastify'
import { API_URL_EMPLOYEE_LABEL } from '../settings'
import { getErrorMessage, hasPermission, EmployeeLabelEnum } from '../elements/utils'
import { CustomButton } from '../elements/StyledElements'
import Label from '../elements/Label'
import ConfirmationModal from './shared/modal_utils/ConfirmationModal'
import LabelEditModal from '../elements/LabelEditModal'

const emptyLabel = {
  name: null,
  text: null,
  text_color: '#FFFFFF',
  label_color: '#000000',
  description: null
}

export default function EmployeeLabel ({ session }) {
  const [labels, setLabels] = useState([])
  const [loading, setLoading] = useState(false)
  const [editLabel, setEditLabel] = useState(emptyLabel)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('Employee Label')

  const loadLabel = async () => {
    setLoading(true)

    try {
      const { data } = await axios.get(API_URL_EMPLOYEE_LABEL)
      setLabels(data)
    } catch (error) {
      console.error(error)
    }

    setLoading(false)
  }

  const deleteLabel = async (labelId) => {
    setLoading(true)

    try {
      await axios.delete(`${API_URL_EMPLOYEE_LABEL}${labelId}`)
      await loadLabel()
      setLabels(labels.filter(l => l.id !== labelId))
    } catch (error) {
      toast.error(getErrorMessage(error))
      console.error(error)
    }

    setLoading(false)
  }

  const TableElement = (props) => (
    <Stack direction="column" spacing={1}>
      <Typography fontWeight='bold'>{props.title}</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: 'center' }}>Vorschau</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Name</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Label</TableCell>
              <TableCell style={{ textAlign: 'left' }}>Beschreibung</TableCell>
              {hasPermission(session.user, 'employee_label_handling') && (
                <TableCell style={{ textAlign: 'center' }} />
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data && props.data.length > 0 && !loading
              ? (
                  props.data.map((item, index) => (
                <TableRow key={index}>
                  <TableCell style={{ textAlign: 'center', padding: '10px' }}>
                    <Label
                      labelColor={item.label_color}
                      text={item.text}
                      textColor={item.text_color}
                      textSize={12}
                      minWidth="4em"
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: 'center', padding: '10px' }}>{item.name}</TableCell>
                  <TableCell style={{ textAlign: 'center', padding: '10px' }}>{item.text}</TableCell>
                  <TableCell style={{ textAlign: 'left', padding: '10px' }}>{item.description}</TableCell>
                  {hasPermission(session.user, 'employee_label_handling') && (
                    <TableCell style={{ textAlign: 'center', padding: '10px' }}>
                      <Stack direction="row" spacing={1} justifyContent="flex-end">
                        <CustomButton onClick={() => {
                          setEditLabel(item)
                          setModalOpen(true)
                          setModalTitle(`Bearbeiten Sie die Bezeichnung ${item.name}`)
                        }}>
                          Bearbeiten
                        </CustomButton>
                        {item.type === EmployeeLabelEnum.MANUAL && (
                          <ConfirmationModal
                            title={"Soll die Bezeichnung '" + item.name + "' wirklich entfernt werden?"}
                            confirm={() => deleteLabel(item.id)}
                            getOpenButton={(toggle) =>
                              <CustomButton
                                onClick={toggle}
                                color="red"
                                icon="delete"
                                iconClassName="IconDeleteButton"
                              >
                                Löschen
                              </CustomButton>
                            }
                          />
                        )}
                      </Stack>
                    </TableCell>
                  )}
                </TableRow>
                  ))
                )
              : (
                  !loading
                    ? (
                <TableRow>
                  <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                    Keine Daten
                  </TableCell>
                </TableRow>
                      )
                    : (
                <TableRow>
                  <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
                      )
                )}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )

  useEffect(() => {
    loadLabel()
  }, [])

  return (
    <>
      <Container style={{ paddingTop: 16 }}>
        {hasPermission(session.user, 'employee_label_handling') && (
          <CustomButton icon="add" onClick={() => {
            setEditLabel(emptyLabel)
            setModalOpen(true)
            setModalTitle('Neues Label')
          }}>
            Neues Label
          </CustomButton>
        )}
        <Stack direction='column' spacing={3} marginTop={2}>
          {labels.filter(label => label.type === EmployeeLabelEnum.AUTOMATIC) && (
            <TableElement
              title="Automatisch"
              data={labels.filter(label => label.type === EmployeeLabelEnum.AUTOMATIC)}
            />
          )}
          {labels.filter(label => label.type === EmployeeLabelEnum.MANUAL) && (
            <TableElement
              title="Manuell"
              data={labels.filter(label => label.type === EmployeeLabelEnum.MANUAL)}
            />
          )}
        </Stack>
      </Container>
      <LabelEditModal
        resetParent={loadLabel}
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        form={editLabel}
        setForm={setEditLabel}
        setLabels={setLabels}
        title={modalTitle}
        url={API_URL_EMPLOYEE_LABEL}
      />
    </>
  )
}

EmployeeLabel.propTypes = {
  session: PropTypes.object
}
