import React from 'react'

import { PropTypes } from 'prop-types'
import { Stack } from '@mui/material'

import DropDown from '../../elements/DropDown'
import EditFiltersModal from './EditFiltersModal'
import MultiSelect from '../../elements/MultiSelect'

export default function FilterCustomer ({
  session,
  openToDoTypes,
  setOpenToDoTypeFilter,
  openToDoTypeFilter,
  setEmployeeFilter,
  employees,
  employeeFilter,
  setSalesmanFilter,
  salesmen,
  salesmanFilter,
  setToDoFilter,
  toDoFilters,
  toDoFilter,
  setToDoFilters,
  setProjectTypeFilter,
  options,
  projectTypeFilter,
  handleProductsChange,
  products,
  productsFilter,
  openTasksFilter,
  setOpenTasksFilter
}) {
  return (
    <Stack direction='column' spacing={2}>
      <Stack direction='row' spacing={1}>
        <DropDown
          onChange={(selected) => setOpenToDoTypeFilter(selected)}
          options={openToDoTypes.map(x => ({ label: x.name, value: x.id, order: x.key ? null : 'last' }))}
          value={openToDoTypeFilter}
          text='Nächster Schritt'
          sort={true}
          search={true}
        />
        <DropDown
          onChange={(selected) => { setEmployeeFilter(selected) }}
          options={
            employees
              ? [
                  { label: 'Nicht zugeordnet', value: -1 },
                  ...employees.map(user => ({ label: user.name, value: user.id }))
                ]
              : [{ label: 'Nicht zugeordnet', value: -1 }]
          }
          value={employeeFilter}
          text='Zuständiger Mitarbeiter'
          sort={true}
          search={true}
        />
      </Stack>
      <Stack direction='row' spacing={1}>
        <DropDown
          onChange={setSalesmanFilter}
          options={salesmen ? [...salesmen.map(user => ({ label: user.name, value: user.id }))] : []}
          value={salesmanFilter}
          text='Verkäufer'
          sort={true}
          search={true}
        />
        <DropDown
          onChange={(idx) => {
            setToDoFilter(toDoFilters[idx])
          }}
          text="ToDo Filter"
          options={toDoFilters.map((f, idx) => ({ label: f.name, value: idx }))}
          value={
            (!toDoFilter || !toDoFilter?.name)
              ? -1
              : toDoFilters.findIndex(f => f.name === toDoFilter.name) === -1
                ? -1
                : toDoFilters.findIndex(f => f.name === toDoFilter.name)
          }
          search={true}
        />
        {toDoFilters
          ? <EditFiltersModal
            filters={toDoFilters} setFilters={setToDoFilters}
            applyFilter={(f) => {
              const customToDoFilter = { ...f, id: -1, name: 'Benutzerdefiniert' }
              setToDoFilter(customToDoFilter)
              setToDoFilters(filters => filters.filter(x => x.name !== customToDoFilter.name).concat([customToDoFilter]))
            }}
            session={session} />
          : null}
      </Stack>
      <Stack direction='row' spacing={1}>
        <DropDown
          onChange={(selected) => { setProjectTypeFilter(selected) }}
          options={options}
          value={projectTypeFilter}
          text='Projekttyp'
          sort={true}
          search={true}
        />
        <MultiSelect
          onChange={handleProductsChange}
          options={products ? products.map(product => ({ label: product.name, value: product.id })) : []}
          values={productsFilter}
          text='Produkte'
          sort={true}
        />
      </Stack>
      <Stack>
        {/* <FormControlLabel control={
                    <Checkbox
                      disableFocusRipple disableRipple
                      checked={!!openTasksFilter}
                      onChange={(e) => {
                        setOpenTasksFilter(e.target.checked ? true : null)
                      }}
                      style={{ color: '#424242', backgroundColor: 'transparent' }}
                    />
                  } label="Offene Aufgaben" /> */}
        <DropDown
          onChange={(selected) => { setOpenTasksFilter(selected) }}
          options={[{ label: 'Offene Aufgaben', value: true }, { label: 'Keine offenen Aufgaben', value: false }]}
          value={openTasksFilter}
          text='Aufgaben'
          sort={true}
          search={true}
        />

        <></>
      </Stack>
    </Stack>
  )
}
FilterCustomer.propTypes = {
  session: PropTypes.object,
  openToDoTypes: PropTypes.array,
  setOpenToDoTypeFilter: PropTypes.func,
  openToDoTypeFilter: PropTypes.number,
  setEmployeeFilter: PropTypes.func,
  employees: PropTypes.array,
  employeeFilter: PropTypes.number,
  setSalesmanFilter: PropTypes.func,
  salesmen: PropTypes.array,
  salesmanFilter: PropTypes.number,
  setToDoFilter: PropTypes.func,
  toDoFilters: PropTypes.array,
  toDoFilter: PropTypes.string,
  setToDoFilters: PropTypes.func,
  setProjectTypeFilter: PropTypes.func,
  options: PropTypes.array,
  projectTypeFilter: PropTypes.string,
  handleProductsChange: PropTypes.func,
  products: PropTypes.array,
  productsFilter: PropTypes.array,
  openTasksFilter: PropTypes.bool,
  setOpenTasksFilter: PropTypes.func
}
