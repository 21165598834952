import React from 'react'

import Customers from './components/Customers'
import Products from './components/Products'
import Statistics from './components/Statistics'
import AppStatistics from './components/AppStatistics'
import Invoices from './components/Invoices'
import TimeManagement from './components/TimeManagement'
import UserManagement from './components/UserManagement'
import GroupManagement from './components/GroupManagement'
import Constructions from './components/Constructions'
import Commissions from './components/Commissions'
import Customer from './components/Customer'
import Construction from './components/Construction'
import Warehouse from './components/Warehouse'
import Project from './components/Project'
import Task from './components/Task'
import Newsletter from './components/Newsletter'
import LeadDetail from './components/LeadDetail'
import CarTracking from './components/CarTracking'

import DataInput from './components/customer_app/DataInput'
import Offers from './components/customer_app/Offers'

import { removeFromObj, checkAccessRights, applyNestedFcn, nestedFilter, checkAccessRightsButton } from './elements/utils'
import Leads from './components/Leads'
import GoogleRatingModal from './components/shared/GoogleRatingModal'
import BusinessVCard from './components/shared/BusinessVCard'
import DashboardDetail from './components/DashboardDetail'
import Tasks from './components/Tasks'
import Map from './components/Map'
import ReminderNoticed from './components/ReminderNoticed'
import EmployeeLabel from './components/EmployeeLabel'

const STAFF_PAGES = [
  {
    name: 'Kundenübersicht',
    url: 'kunde/:id',
    getPage: (session) => <Customer session={session} />
  },
  {
    name: 'Projektübersicht',
    url: 'projekt/:id',
    getPage: (session) => <Project session={session} />
  },
  {
    name: 'Erinnerung',
    url: 'aufgabe/:id/erinnerung',
    getPage: (session) => <ReminderNoticed session={session} />
  },
  {
    name: 'Bauplanung',
    url: 'projekt/:id/bauplanung',
    getPage: (session) => <Construction session={session} />
  },
  {
    name: 'Dashboard',
    url: 'dashboard-detail',
    getPage: (session) => <DashboardDetail session={session} />
  },
  {
    name: 'Leadübersicht',
    url: 'leads/:id',
    getPage: (session) => <LeadDetail session={session} />
  },
  {
    name: 'Aufgabe',
    url: 'aufgabe/:id',
    getPage: (session) => <Task session={session} />
  },
  {
    name: 'Mitarbeiterlabel',
    url: 'mitarbeiterlabel',
    getPage: (session) => <EmployeeLabel session={session} />
  }
]

const STAFF_NAVIGATION = [
  {
    icon: 'sell',
    key: 'sell',
    name: 'Verkauf',
    url: null,
    children: [
      {
        icon: 'people',
        key: 'customers',
        name: 'Kunden',
        url: 'kunden',
        getPage: (session) => <Customers session={session} />
      },
      {
        icon: 'product',
        key: 'products',
        name: 'Produkte',
        url: 'produkte',
        getPage: (session) => <Products session={session} />
      },
      {
        icon: 'lead',
        key: 'leads',
        name: 'Leads',
        url: 'leads',
        getPage: (session) => <Leads session={session} />
      },
      {
        icon: 'newsletter',
        key: 'newsletter',
        name: 'Newsletter',
        url: 'newsletter',
        getPage: (session) => <Newsletter session={session} />
      }
    ]
  },
  {
    icon: 'euro',
    key: 'finances',
    name: 'Finanzen',
    url: null,
    children: [
      {
        icon: 'invoice',
        key: 'invoices',
        name: 'Rechnungen',
        url: 'rechnungen',
        getPage: (session) => <Invoices session={session} />
      },
      {
        icon: 'statistics',
        key: 'statistics',
        name: 'Statistiken',
        url: 'statistiken',
        getPage: (session) => <Statistics session={session} />
      },
      {
        icon: 'savings',
        key: 'commissions',
        name: 'Provisionen',
        url: 'provisionen',
        getPage: (session) => <Commissions session={session} />
      },
      {
        icon: 'apps',
        key: 'appstatistics',
        name: 'App-Statistiken',
        url: 'appstatistiken',
        getPage: (session) => <AppStatistics session={session} />
      }
    ]
  },
  {
    icon: 'clipboard',
    key: 'organization',
    name: 'Organisation',
    url: null,
    children: [
      {
        icon: 'kalendar',
        key: 'kalendars',
        name: 'Kalendar',
        url: 'kalendar',
        getPage: (session) => <Constructions session={session} />
      },
      {
        icon: 'time',
        key: 'timemanagement',
        name: 'Zeiterfassung',
        url: 'zeiterfassung',
        getPage: (session) => <TimeManagement session={session} />
      },
      {
        icon: 'warehouse',
        key: 'warehouse',
        name: 'Lager',
        url: 'lager',
        getPage: (session) => <Warehouse session={session} />
      },
      {
        icon: 'truck',
        key: 'cartracking',
        name: 'Flotte',
        url: 'flotte',
        getPage: (session) => <CarTracking session={session} />
      },
      {
        icon: 'map',
        key: 'map',
        name: 'Karte',
        url: 'karte',
        getPage: (session) => <Map session={session} />
      },
      {
        icon: 'task',
        key: 'tasks',
        name: 'Aufgaben',
        url: 'aufgaben',
        getPage: (session) => <Tasks session={session} />
      }
    ]
  },
  {
    icon: 'settings',
    key: 'administration',
    name: 'Verwaltung',
    url: null,
    children: [
      {
        icon: 'addperson',
        key: 'usermanagement',
        name: 'Nutzer',
        url: 'nutzerverwaltung',
        getPage: (session) => <UserManagement session={session} />
      },
      {
        icon: 'addgroup',
        key: 'groumanagement',
        name: 'Gruppen',
        url: 'gruppenverwaltung',
        getPage: (session) => <GroupManagement session={session} />
      }
    ]
  },
  {
    icon: 'more',
    key: 'moreoption',
    name: 'Sonstiges',
    url: null,
    children: [
      {
        icon: 'thumbUp',
        key: 'googlereview',
        name: 'Google Bewertung',
        button: {
          key: 'openGoogleReview',
          getElmt: ({ session, ...props }) => <GoogleRatingModal session={session} {...props} key="google-rating-modal" />
        }
      },
      {
        icon: 'business',
        key: 'businesscard',
        name: 'Visitenkarte',
        button: {
          key: 'openBusinessCard',
          getElmt: ({ session, ...props }) => <BusinessVCard session={session} {...props} key="business-v-card" />
        }
      }
    ]
  }
]

const CUSTOMER_NAVIGATION = [
  {
    icon: 'handshake',
    key: 'customer_offers',
    name: 'Angebote',
    url: 'kunde/angebote',
    getPage: (session) => <Offers session={session} />
  },
  {
    icon: 'clipboard',
    key: 'customer_datainput',
    name: 'Dateneingabe',
    url: 'kunde/dateneingabe',
    getPage: (session) => <DataInput session={session} />
  }
]

export const getNavigation = (session, all = false) => {
  const checkItemAccess = (item) => {
    if (item.url) return checkAccessRights(session.user, item.url)
    if (item.button) return checkAccessRightsButton(session.user, item.button.key)
    return item.children.some(i => checkItemAccess(i))
  }
  const addItemPermission = (item) => setItemPermission(item, session && session.user && checkItemAccess(item))
  const setItemPermission = (item, permission) => {
    item.permission = permission
  }
  const setItemPermissionFalse = (item) => setItemPermission(item, false)

  let func
  const navigation = []

  func = (session && session.user && session.user.is_staff && session.user.group_key !== 'customers') ? addItemPermission : setItemPermissionFalse
  navigation.push(...applyNestedFcn(STAFF_NAVIGATION, func))

  func = (session && session.user && session.user.group_key === 'customers') ? addItemPermission : setItemPermissionFalse
  navigation.push(...applyNestedFcn(CUSTOMER_NAVIGATION, func))

  if (all) return navigation
  return nestedFilter(navigation, x => x.permission)
}

export const getPages = (session) => {
  const pages = []
  const func = (item) => {
    if (item.url) pages.push(removeFromObj(item, ['children']))
  }
  applyNestedFcn(getNavigation(session, true), func)
  if (session && session.user && session.user.is_staff && session.user.group_key !== 'customers') { pages.push(...STAFF_PAGES) }
  return pages
}
