import React, { Fragment, useState, useEffect, useMemo } from 'react'

import { PropTypes } from 'prop-types'
import { HashLink as Link } from 'react-router-hash-link'
import { Row } from 'reactstrap'
import axios from 'axios'
import { API_URL_DASHBOARD_TILES } from '../../settings'
import { IconButton, Typography } from '@mui/material'

import Collapse from '@mui/material/Collapse'

import Icon from '../../elements/Icon'

import './dashboard.css'
import DashboardDesktop from './DashboardDesktop'
import ModalTile from './ModalTile'
import { TILE_TYPES, emptyFormData, preprocessItem, getAllowedTileTypes } from './dashboard-utils'
import { wrapCallback } from '../../elements/utils'

export default function DashboardDesktopContainer ({ session }) {
  const iconfontsize = 40

  const [openCollapse, setOpenCollapse] = useState(null)
  const [modalState, setModalState] = useState({
    isOpen: false,
    item: null
  })

  const getParentItem = (item, level) => {
    return <Row key={item.key}>
      <span onClick={() => setOpenCollapse(item.key)} onMouseOver={() => setOpenCollapse(item.key)} onMouseLeave={() => setOpenCollapse(null)}>
        <div style={{ marginLeft: 40 * level }}>
          <IconButton disabled disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }}>
            <Icon icon={item.icon} className='secondary-textcolor' style={{ fontSize: iconfontsize }} fontSize='large' />
            &nbsp;
            <Typography className='secondary-textcolor' fontSize='h6.fontSize' sx={{ fontWeight: 'bold' }}>{item.name}</Typography>
          </IconButton>
        </div>
        {item.children
          ? <Collapse in={openCollapse === item.key} timeout="auto" unmountOnExit>
            {item.children.map(c => getListItem(c, level + 1))}
          </Collapse>
          : null}
      </span>
    </Row>
  }

  const getChildItem = (item, level) => {
    return <div style={{ marginLeft: 40 * level }} key={item.key}>
      <Row>
        <Link to={`/${item.url}/`} style={{ textDecoration: 'none' }}>
          <IconButton disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }}>
            <Icon icon={item.icon} className='secondary-textcolor' style={{ fontSize: iconfontsize }} fontSize='large' />
            &nbsp;
            <Typography className='secondary-textcolor' fontSize='h6.fontSize'>{item.name}</Typography>
          </IconButton>
        </Link>
      </Row>
    </div>
  }

  const getListItem = (item, level) => {
    if (item?.button) {
      return item.button.getElmt({ session })
    }

    return item?.url ? getChildItem(item, level) : getParentItem(item, level)
  }

  const [dashboardTiles, setDashboardTiles] = useState([])

  const fetchDashboardTiles = async () => {
    const response = await getTiles()
    setDashboardTiles(response.data)
  }
  useEffect(() => {
    fetchDashboardTiles()
  }, [])

  const getTiles = () => {
    return axios.get(API_URL_DASHBOARD_TILES)
  }
  const createTile = wrapCallback((payload) => {
    return axios.post(API_URL_DASHBOARD_TILES, payload)
  }, fetchDashboardTiles)
  const updateTile = wrapCallback((id, payload) => {
    return axios.put(API_URL_DASHBOARD_TILES + id, payload)
  }, fetchDashboardTiles)
  const deleteTile = wrapCallback((id) => {
    return axios.delete(API_URL_DASHBOARD_TILES + id)
  }, fetchDashboardTiles)

  const allowedTileTypes = getAllowedTileTypes(session)
  const NEW_TILE = () => {
    return {
      _flag: 'draft',
      type: allowedTileTypes.length > 0 ? TILE_TYPES[allowedTileTypes[0]].key : null,
      data: allowedTileTypes.length > 0 ? emptyFormData(session, TILE_TYPES[allowedTileTypes[0]].key) : null
    }
  }

  const preprocessDashboardTiles = (dashboardTiles) => {
    const sortedDashboardTiles = dashboardTiles.map(e => ({ ...e, id: parseInt(e.id) })).sort((a, b) => a.id - b.id)
    const preprocessed = sortedDashboardTiles.map(preprocessItem)
    return [...preprocessed, NEW_TILE()]
  }

  const passedDashboardTiles = useMemo(() => preprocessDashboardTiles(dashboardTiles), [dashboardTiles])

  const setSelectedItem = (item) => {
    setModalState(prev => ({
      ...prev,
      item: { ...item },
      isOpen: true
    }))
  }

  const addTile = () => {
    setSelectedItem(NEW_TILE())
  }

  return (
    <Fragment>
      <DashboardDesktop
        session={session}
        dashboardTiles={passedDashboardTiles}
        setSelectedItem={setSelectedItem}
        deleteTile={deleteTile}
        addTile={addTile}
      />

      <ModalTile
        session={session}
        isOpen={modalState?.isOpen}
        setIsOpen={(isOpen) => setModalState(prev => ({ ...prev, isOpen }))}
        form={modalState?.item}
        setForm={(form) => {
          const _form = (typeof form === 'function') ? form(modalState?.item) : form
          setModalState(prev => ({ ...prev, item: _form }))
        }}
        refresh={fetchDashboardTiles}
        createTile={createTile}
        updateTile={updateTile}
        emptyFormData={emptyFormData}
        allowedTileTypes={allowedTileTypes}
      />
    </Fragment>
  )
}

DashboardDesktopContainer.propTypes = {
  session: PropTypes.object
}
