import React, { useState } from 'react'

import { PropTypes } from 'prop-types'
import { Form, FormGroup, Input } from 'reactstrap'
import { Typography } from '@mui/material'
import DropDown from '../../elements/DropDown'

import CustomModal from '../shared/modal_utils/CustomModal'
import { defaultIfEmpty, getErrorMessage } from '../../elements/utils'
import SaveModalFooter from '../shared/modal_utils/SaveModalFooter'
import { toast } from 'react-toastify'

import CustomerFilterModalTile from './CustomerFilterModalTile'
import { TILE_TYPES } from './dashboard-utils'

export default function ModalTile ({ session, form, setForm, isOpen, setIsOpen, refresh, createTile, updateTile, emptyFormData, allowedTileTypes }) {
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false, showMissingFields: false
  })

  const onChangeProjectType = (type) => {
    setForm(prev => ({
      ...prev,
      type,
      data: emptyFormData(session, type)
    }))
  }

  const onChange = (e) => {
    const name = e.target.name
    const value = e.target.value

    setForm(prev => ({ ...prev, [name]: value }))
  }

  const isFormEmpty = () => {
    switch (form?.type) {
      case TILE_TYPES.CUSTOM_FILTER_CUSTOMER.key:
        return !form?.name || !form?.type
      default:
        return !form?.name || !form?.type
    }
  }

  const constructPayload = () => {
    switch (form?.type) {
      case TILE_TYPES.CUSTOM_FILTER_CUSTOMER.key:
        return { ...form, data: JSON.stringify(form?.data) }
    }
  }

  const onSubmit = async (onSuccess) => {
    if (isFormEmpty()) {
      setLoadingElements(prev => ({ ...prev, submitError: 'Bitte alle Informationen eintragen!', inProgress: false }))
      return
    }

    setLoadingElements(prev => ({ ...prev, inProgress: true, submitError: false, showMissingFields: false }))
    const payload = constructPayload()
    try {
      if (form?.id) {
        await updateTile(form?.id, payload)
      } else {
        await createTile(payload)
      }

      onSuccess()
      refresh()
      setLoadingElements(prev => ({ ...prev, inProgress: false, submitError: false, showMissingFields: false }))
    } catch (err) {
      toast.error(getErrorMessage(err))
      setLoadingElements(prev => ({ ...prev, inProgress: false, submitError: true, showMissingFields: false }))
    }
  }

  const getFooter = (toggle) => {
    return (
      <SaveModalFooter
        id="submit_customer_form"
        submitError={loadingElements.submitError}
        inProgress={loadingElements.inProgress}
        onSave={() => onSubmit(toggle)}
        disabled={isFormEmpty()}
      />
    )
  }

  const resetState = () => {
    setForm(null)
    setLoadingElements({
      inProgress: false, submitError: false, showMissingFields: false
    })
  }

  const setInOpenWrapped = (isOpen) => {
    if (!isOpen) {
      resetState()
    }
    setIsOpen()
  }

  return (
    <CustomModal title={form?.id ? 'Kachel bearbeiten' : 'Kachel hinzufügen'} size="md" isOpen={isOpen} setIsOpen={setInOpenWrapped} getFooter={getFooter} PaperProps={{ sx: { overflow: 'visible' } }}>
      <Form>
        {(form?.id) && (<FormGroup>
          <Typography className='secondary-textcolor'>Id:</Typography>
          <div className="input-group">
            <Input
              style={{ width: '50%' }}
              type="text"
              id="input"
              name="customer_id"
              value={defaultIfEmpty(form?.id)}
              disabled={true}
            />
          </div>
        </FormGroup>)}
        <FormGroup>
          <Typography className='secondary-textcolor'>Name:</Typography>
          <div className="input-group">
            <Input
              type="text"
              id="name-input"
              name="name"
              value={defaultIfEmpty(form?.name)}
              required={true}
              onChange={onChange}
              autoComplete='off'
              invalid={!(form?.name)}
              style={{ boxShadow: 'none' }}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <Typography className='secondary-textcolor'>Kacheltyp:</Typography>
          <DropDown
            id="type_dropdown"
            onChange={(type) => {
              onChangeProjectType(type)
            }}
            text={'Kacheltyp'}
            options={allowedTileTypes.map(key => ({ label: TILE_TYPES[key].name, value: TILE_TYPES[key].key }))}
            value={form?.type}
          />
          {
            allowedTileTypes?.length === 0 && (
              <Typography style={{
                color: 'red',
                marginTop: '10px'
              }}>
                Keine Kacheltypen verfügbar!
              </Typography>
            )
          }
        </FormGroup>
        {
          form?.type === TILE_TYPES.CUSTOM_FILTER_CUSTOMER.key && <CustomerFilterModalTile session={session} form={form} setForm={setForm} />
        }
      </Form>
    </CustomModal>
  )
}
ModalTile.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  session: PropTypes.object,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  refresh: PropTypes.func,
  createTile: PropTypes.func,
  updateTile: PropTypes.func,
  emptyFormData: PropTypes.func,
  allowedTileTypes: PropTypes.array
}
