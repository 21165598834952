import { hasPermission } from '../../elements/utils'

export const TILE_TYPES = {
  CUSTOM_FILTER_CUSTOMER: {
    key: 'custom-filter-customer',
    name: 'Kunden'
  }
}

export const emptyFormData = (session, tileType) => {
  switch (tileType) {
    case TILE_TYPES.CUSTOM_FILTER_CUSTOMER.key:
      return {
        filters: {
          openToDoTypeFilter: null,
          salesmanFilter: ['salesmen'].includes(session.user.group_key) ? session.user.id : null,
          toDoFilter: null,
          employeeFilter: null,
          projectTypeFilter: null,
          productsFilter: []
        }
      }
  }
}

export const isTileTypeAllowed = (session, tile) => {
  switch (tile) {
    case TILE_TYPES.CUSTOM_FILTER_CUSTOMER.key:
      return hasPermission(session?.user, 'pages_customer_handling')
  }
  return false
}

export const preprocessItem = (item) => {
  switch (item?.type) {
    case TILE_TYPES.CUSTOM_FILTER_CUSTOMER.key:
      return {
        ...item,
        data: JSON.parse(item?.data)
      }
  }
  return item
}

export const getAllowedTileTypes = (session) => {
  return Object.keys(TILE_TYPES).filter(key => isTileTypeAllowed(session, TILE_TYPES[key].key))
}
