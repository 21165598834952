import React, { Fragment, useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import LoadingPage from '../../elements/LoadingPage'
import DropDown from '../../elements/DropDown'
import { MONTHS, numFormatter, THIS_YEAR } from '../../elements/utils'
import { Typography } from '@mui/material'
import { Table } from 'react-bootstrap'
import { DAPI_URL_SALESMENLEADSEVALUATIONSTATS } from '../../settings'
import axios from 'axios'

export default function SalesmenLeadEvaluation ({ leadType, setLeadType, leadTypes, leadSources, refreshLeadSources }) {
  const [year, setYear] = useState(new Date().getFullYear())
  const [source, setSource] = useState('Gesamt')
  const [salesmenEvaluations, setSalesmenEvaluations] = useState([])
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false
  })

  useEffect(() => {
    const fetchEvaluations = async () => {
      setLoadingElements({ ...loadingElements, inProgress: true })
      try {
        const res = await axios.get(DAPI_URL_SALESMENLEADSEVALUATIONSTATS,
          { params: { year, source: source === 'Gesamt' ? 'all' : source, lead_type: leadType } })
        setSalesmenEvaluations(res.data)
      } finally {
        setLoadingElements({ ...loadingElements, inProgress: false })
      }
    }
    const _refreshLeadSources = async () => {
      setLoadingElements({ ...loadingElements, inProgress: true })
      try {
        await refreshLeadSources()
      } finally {
        setLoadingElements({ ...loadingElements, inProgress: false })
      }
    }
    fetchEvaluations().then(() => {})
    _refreshLeadSources().then(() => {})
  }, [year, source, leadType])

  if (loadingElements.inProgress || salesmenEvaluations.length === 0) {
    return (
        <LoadingPage />
    )
  }

  return (
      <Fragment>
        <div style={{ display: 'flex', gap: '5px' }}>
          <div>
            <DropDown
                onChange={setYear}
                options={Array.from({ length: ((THIS_YEAR) - 2022) + 1 }, (v, i) => 2022 + i)}
                value={year}
                text='Jahr'
            />
          </div>
          <div>
            <DropDown
                onChange={(val) => {
                  !val ? setSource('Gesamt') : setSource(val)
                }}
                options={['Gesamt'].concat(leadSources)}
                value={source}
                text='Leadquelle'
                search={true}
                style={{ paddingBottom: '5px' }}
            />
          </div>
          <div>
            <DropDown
                onChange={setLeadType}
                options={leadTypes ? leadTypes.map(t => ({ label: t.name, value: t.id })) : []}
                value={leadType}
                text='Lead-Typ'
                sort={true}
                search={true}
                style={{ paddingBottom: '5px' }}
            />
          </div>
        </div>
        <Table>
          <thead>
            <tr>
              <th colSpan={13} style={{ textAlign: 'center' }}>{source}</th>
            </tr>
            <tr>
              <th><Typography className='secondary-textcolor'></Typography></th>
              {
                MONTHS.map(m =>
                  (
                        <th key={m} className="align-middle"><Typography className='secondary-textcolor'>{m}</Typography>
                        </th>
                  ))
              }
            </tr>
          </thead>
          <tbody>
            {salesmenEvaluations.employees.map((e, i) => {
              return (
                <tr key={e}>
                  <td key={e}>{e}</td>
                  {
                    MONTHS.map((_, j) =>
                      (
                          <td key={e}>
                            {salesmenEvaluations.data[i].n_accepted_offers[j]} / {salesmenEvaluations.data[i].n_appointment_made[j]}
                            <p style={{
                              fontWeight: 'bold',
                              color: 'grey',
                              fontSize: '0.8rem'
                            }}>
                              ({numFormatter(salesmenEvaluations.data[i].rate[j] * 100)}%)
                            </p>
                          </td>
                      ))
                  }
                </tr>
              )
            })}
          </tbody>
        </Table>
      </Fragment>
  )
}

SalesmenLeadEvaluation.propTypes = {
  leadType: PropTypes.object,
  setLeadType: PropTypes.func,
  leadTypes: PropTypes.object,
  leadSources: PropTypes.object,
  refreshLeadSources: PropTypes.func
}
