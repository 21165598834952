/* eslint-disable react/prop-types */
import React, { Fragment, useState } from 'react'
import CustomModal from '../components/shared/modal_utils/CustomModal'
import PropTypes from 'prop-types'
import { CustomButton } from './StyledElements'
import Label from './Label'
import { Stack, Typography } from '@mui/material'
import axios from 'axios'
import CustomForm from './CustomForm'
import { toast } from 'react-toastify'
import { getErrorMessage, ProjectLabelEnum } from './utils'

const labelEditModalFormTemplate = () => [
  {
    type: 'text',
    name: 'Name',
    key: 'name'
  },
  {
    type: 'text',
    name: 'Text',
    key: 'text'
  },
  {
    type: 'text',
    name: 'Beschreibung',
    key: 'description',
    optional: true
  },
  {
    type: 'color',
    name: 'Textfarbe',
    key: 'text_color',
    optional: true
  },
  {
    type: 'color',
    name: 'Etikettenfarbe',
    key: 'label_color',
    optional: true
  }
]

export default function LabelEditModal ({ resetParent, isOpen, setIsOpen, form, setForm, setLabels, title, url }) {
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, showMissingFields: false
  })

  const template = labelEditModalFormTemplate()
  const optionalKeys = ['description', 'text_color', 'label_color']

  const checkIfEmpty = (key, val) => {
    return (
      val === null || val === ''
    )
  }

  const addLabel = async () => {
    setLoadingElements({ ...loadingElements, inProgress: true })

    try {
      const emptyFields = Object.keys(form).filter(x => !optionalKeys.includes(x)).filter(key => checkIfEmpty(key, form[key]))
      if (emptyFields.length > 0) {
        setLoadingElements({ ...loadingElements, inProgress: false, showMissingFields: true })
        toast.error('Bitte füllen Sie alle Felder aus')
        return
      }

      setLoadingElements({ inProgress: true, showMissingFields: false })

      const payload = {
        ...form,
        type: ProjectLabelEnum.MANUAL,
        key: form.name.toLowerCase().replace(/ /g, '_')
      }

      const { data } = await axios.post(url, payload)

      setLabels((labels) => [...labels, data])
      setIsOpen(false)
    } catch (error) {
      toast.error(getErrorMessage(error))
      console.error(error)
    }

    setLoadingElements({ showMissingFields: false, inProgress: false })
  }

  const editLabel = async () => {
    setLoadingElements({ ...loadingElements, inProgress: true })

    try {
      const emptyFields = Object.keys(form).filter(x => !optionalKeys.includes(x)).filter(key => checkIfEmpty(key, form[key]))
      if (emptyFields.length > 0) {
        setLoadingElements({ ...loadingElements, inProgress: false, showMissingFields: true })
        toast.error('Bitte füllen Sie alle Felder aus')
        return
      }

      setLoadingElements({ inProgress: true, showMissingFields: false })

      const { data } = await axios.put(`${url}${form.id}`, form)

      resetParent()
      setLabels((labels) => labels.map(l => l.id === data.id ? data : l))
      setIsOpen(false)
    } catch (error) {
      toast.error(getErrorMessage(error))
      console.error(error)
    }

    setLoadingElements({ showMissingFields: false, inProgress: false })
  }

  const onToggle = () => {
    setLoadingElements({ inProgress: false, showMissingFields: false })
  }

  const getFooter = () => (
    <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%' paddingX={2}>
      <Stack direction='row' alignItems='center' spacing={1}>
        <Typography color='white'>
          Vorschau
        </Typography>
        <Label
          labelColor={form.label_color}
          text={form.text || 'Geben Sie Text ein'}
          textColor={form.text_color}
          textSize={16}
        />
      </Stack>
      <CustomButton
        color="white"
        onClick={form.key ? editLabel : addLabel}
        disabled={loadingElements.inProgress}
      >
        {loadingElements.inProgress ? '...' : 'Speichern'}
      </CustomButton>
    </Stack>
  )

  return (
    <CustomModal title={title} isOpen={isOpen} setIsOpen={setIsOpen} size="lg" getFooter={getFooter} onToggle={onToggle}>
      <Fragment>
        <CustomForm
          template={template}
          form={form}
          setForm={setForm}
          showMissingFields={loadingElements.showMissingFields}
        />
      </Fragment>
    </CustomModal>
  )
}

LabelEditModal.propTypes = {
  resetParent: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  form: PropTypes.object,
  setForm: PropTypes.func,
  setLabels: PropTypes.func,
  title: PropTypes.string,
  url: PropTypes.string
}
