import React, { Fragment, useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { Container } from 'reactstrap'
import { Table } from 'react-bootstrap'
import axios from 'axios'

import { Typography } from '@mui/material'

import { hasPermission, sortById, EmployeeLabelEnum } from '../elements/utils'
import LoadingPage from '../elements/LoadingPage'
import { API_URL_GROUP, API_URL_PERMISSIONS, API_URL_EMPLOYEE_LABEL } from '../settings'
import GroupFormModal from './group_management/GroupFormModal'

export default function GroupManagement ({ session }) {
  const [groups, setGroups] = useState([])
  const [permissions, setPermissions] = useState([])
  const [chosenGroup, setChosenGroup] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [labels, setLabels] = useState([])

  useEffect(() => {
    Promise.all([getGroups(), getLabels(), getPermissions()]).then(() => setLoaded(true))
  }, [])

  const resetState = () => {
    getGroups()
    getLabels()
  }

  const getGroups = async () => {
    return axios.get(API_URL_GROUP)
      .then(
        res => setGroups(sortById(res.data)))
  }

  const getLabels = async () => {
    return axios.get(API_URL_EMPLOYEE_LABEL, {
      params: {
        type: EmployeeLabelEnum.AUTOMATIC
      }
    }).then(res => setLabels(res.data))
  }

  const getPermissions = async () => {
    return hasPermission(session.user, 'groups_change_permissions')
      ? axios.get(API_URL_PERMISSIONS)
        .then(res => setPermissions(res.data))
      : Promise.resolve()
  }

  const onRowClick = (group) => {
    if (group) {
      setChosenGroup(group)
    }
  }

  if (!loaded) return <LoadingPage />

  return (
    <Fragment>
      <Container>
        <GroupFormModal
          session={session}
          permissions={permissions}
          resetParent={resetState}
        />
        <GroupFormModal
          session={session}
          resetParent={resetState}
          group={chosenGroup}
          label={labels.find(label => label.key === chosenGroup?.key || label.name === chosenGroup?.name)}
          isOpen={!!chosenGroup}
          permissions={permissions}
          setIsOpen={(isOpen) => { if (!isOpen) setChosenGroup(null) }}
          getOpenButton={() => null}
        />
        <br />
        <Table className={!groups || groups.length <= 0 ? 'table-not-hover' : 'table-hover'}>
          <thead>
            <tr>
              <th className="align-middle"><Typography className='secondary-textcolor'>#</Typography></th>
              <th className="align-middle"><Typography className='secondary-textcolor'>Name</Typography></th>
            </tr>
          </thead>

          <tbody>
            {!groups || groups.length <= 0
              ? (
                  null
                )
              : (
                  groups.map((group, index) =>
                  <tr key={group.id}>
                    <td className="align-middle" onClick={(e) => { if (!e.ctrlKey) onRowClick(group) }}><Typography className='secondary-textcolor'>{index + 1}</Typography></td>
                    <td className="align-middle" onClick={(e) => { if (!e.ctrlKey) onRowClick(group) }}><Typography className='secondary-textcolor'>{group.name} </Typography></td>
                  </tr>
                  )
                )}
          </tbody>
        </Table>
      </Container >
    </Fragment >
  )
}

GroupManagement.propTypes = {
  session: PropTypes.object
}
