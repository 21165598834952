import React, { Fragment, useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { CustomButton } from '../../elements/StyledElements'
import CustomModal from './modal_utils/CustomModal'
import { Table } from 'react-bootstrap'
import { Input } from 'reactstrap'
import axios from 'axios'
import { API_URL_MAPSETTING, API_URL_MAPSETTING_BULK } from '../../settings'
import SaveModalFooter from './modal_utils/SaveModalFooter'
import { Typography } from '@mui/material'
import { formatUrl } from '../../elements/utils'

function MapSiteSetting ({ isOpen, setIsOpen, mapSettings, loadingElements, setLoadingElements }) {
  const [_mapSettings, _setMapSettings] = useState(mapSettings)

  useEffect(() => {
    _setMapSettings(mapSettings)
  }, [mapSettings])

  const onChange = (e) => {
    const province = e.target.name
    const url = e.target.value

    const newSettings = structuredClone(_mapSettings)
    for (const setting of newSettings) {
      if (setting.province === province) {
        setting.url = url
        break
      }
    }

    _setMapSettings(newSettings)
  }

  const onSave = async () => {
    setLoadingElements({ ...loadingElements, inProgress: true })
    try {
      await axios.put(API_URL_MAPSETTING_BULK, _mapSettings)
      setIsOpen(false)
    } catch (e) {
      console.error(e)
      setLoadingElements({ ...loadingElements, submitError: true })
    } finally {
      setLoadingElements({ ...loadingElements, inProgress: false })
    }
  }

  const getOpenButton = (toggle) => {
    return (
          <CustomButton onClick={toggle}> MAP Einstellungen </CustomButton>
    )
  }

  const getFooter = () => {
    return <SaveModalFooter
          submitError={loadingElements.submitError}
          inProgress={loadingElements.inProgress}
          onSave={onSave}
        />
  }

  return (
        <CustomModal size='fullscreen' title='MAP Einstellungen' getOpenButton={getOpenButton} getFooter={getFooter} isOpen={isOpen} setIsOpen={setIsOpen}>
          <Table>
            <thead>
              <tr>
                <th>Bundesland</th>
                <th>MAP Link</th>
              </tr>
            </thead>
            <tbody>
              { _mapSettings.map(setting => {
                return (
                  <tr key={setting.province}>
                    <td>{setting.province}</td>
                    <td>
                      <Input
                          id={setting.province}
                          type="text"
                          name={setting.province}
                          onChange={onChange}
                          value={setting.url}
                          style={{ boxShadow: 'none' }}
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </CustomModal>
  )
}

MapSiteSetting.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  mapSettings: PropTypes.arrayOf(PropTypes.object),
  loadingElements: PropTypes.object,
  setLoadingElements: PropTypes.func
}

function MapSiteNotif ({ openNotif, setOpenNotif, setOpenSettings }) {
  return (
        <CustomModal
          getFooter={(_toggle) => <SaveModalFooter
            onSave={() => { _toggle() }}
            onCancel={() => {
              setOpenSettings(true)
              setOpenNotif({ ...openNotif, open: false })
            }}
            saveBtnLabel={'Okay'}
            cancelBtnLabel={'Zu den Einstellungen'}
          />}
          size="lg"
          title={`Kein MAP-Link gefunden für ${openNotif.province}`}
          isOpen={openNotif.open}
          setIsOpen={(isOpen) => setOpenNotif({ ...openNotif, open: isOpen })}
        >
          <Typography variant='h6'>
            Kein MAP Link für: <strong>{openNotif.province}</strong> hinterlegt. Link bitte in den MAP Einstellungen hinzufügen. Die Adresse wurde in die Zwischenablage kopiert.
          </Typography>
        </CustomModal>
  )
}

MapSiteNotif.propTypes = {
  openNotif: PropTypes.object,
  setOpenNotif: PropTypes.func,
  setOpenSettings: PropTypes.func
}

export default function MapSite ({ project }) {
  const [openSettings, setOpenSettings] = useState(false)
  const [openNotif, setOpenNotif] = useState({ open: false, province: '' })
  const [mapSettings, setMapSettings] = useState([])
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false
  })

  useEffect(() => {
    const fetchMapSettings = async () => {
      setLoadingElements({ ...loadingElements, inProgress: true })
      try {
        const res = await axios.get(API_URL_MAPSETTING)
        setMapSettings(res.data)
      } finally {
        setLoadingElements({ ...loadingElements, inProgress: false })
      }
    }

    fetchMapSettings().then(() => {})
  }, [openSettings])

  const openMap = async () => {
    let province = project.province_project
    const zipCode = (project.zip_and_city_project || '').split(' ')
    if (!province && zipCode.length > 1) {
      try {
        const res = await axios.get(`https://zip-api.eu/api/v1/info/DE-${zipCode[0]}`)
        province = res.data.state
      } catch (e) {
        console.error(e)
      }
    }
    const setting = mapSettings.find(s => s.province === province)
    if (!setting || !setting.url) {
      setOpenNotif({ open: true, province })
    }
    navigator.clipboard.writeText(project.street_and_number_project + ' ' + project.zip_and_city_project).then(() => {
      const url = setting.url
      if (url) {
        window.open(formatUrl(url), '_blank')
      }
    })
  }

  return (
        <Fragment>
            <div style={{ display: 'flex', gap: '5px', marginTop: '1vh' }}>
                <CustomButton onClick={openMap}>MAP Öffnen</CustomButton>
                <MapSiteSetting
                    isOpen={openSettings}
                    setIsOpen={setOpenSettings}
                    mapSettings={mapSettings}
                    loadingElements={loadingElements}
                    setLoadingElements={setLoadingElements}
                />
                <MapSiteNotif
                    openNotif={openNotif}
                    setOpenNotif={setOpenNotif}
                    setOpenSettings={setOpenSettings}
                />
            </div>
        </Fragment>
  )
}

MapSite.propTypes = {
  project: PropTypes.object
}
