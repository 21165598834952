import React from 'react'
import { PropTypes } from 'prop-types'
import CollapsibleTable from '../elements/CollapsibleTable'
import { projectNameWithLabel, string2FormattedString } from '../elements/utils'
import { getProjectType } from './project_types/projectUtils'
import { Box } from '@material-ui/core'
import Icon from '../elements/Icon'

export default function CustomerTable ({ customers, session, batch, batchSize }) {
  return (
    <CollapsibleTable
      columns={[{
        name: '',
        key: 'has_open_tasks'
      }, {
        name: 'Nachname',
        key: 'last_name',
        style: {
          width: '40%',
          maxWidth: 0,
          wordWrap: 'break-word'
        }
      }, {
        name: 'Vorname',
        key: 'first_name',
        style: {
          width: '30%',
          maxWidth: 0,
          wordWrap: 'break-word'
        }
      }, {
        name: 'Datum',
        key: 'registration',
        style: {
          width: '20%',
          maxWidth: 0,
          wordWrap: 'break-word'
        }
      }]}
      rows={customers.map(customer => ({
        key: `${customer.id}`,
        has_open_tasks: customer.has_open_tasks ? <Icon icon="warning" style={{ color: '#fcba03' }} fontSize='large' /> : null,
        first_name: customer.gender !== 'Firma' ? customer.first_name : `${customer.first_name} ${customer.last_name}`,
        last_name: customer.gender !== 'Firma' ? customer.last_name : customer.company,
        registration: string2FormattedString(customer.registration_date),
        link: `/kunde/${customer.id}`,
        child:
          (customer.project_set.length === 0)
            ? <Box marginLeft={0} marginTop={0} marginBottom={0}>
              <CollapsibleTable
                columns={[{ name: '', key: 'value' }]}
                rows={[{ key: `no-project-customer-${customer.id}`, value: 'Keine Projekte' }]}
                header={false}
                collapsible={false}
                buttomLine={false}
              />
            </Box>
            : <Box marginLeft={0} marginTop={0} marginBottom={0}>
              <CollapsibleTable
                columns={[{
                  name: '',
                  key: 'has_open_tasks'
                }, {
                  name: 'Name',
                  key: 'name',
                  style: { width: '35%' }
                }, {
                  name: 'Typ',
                  key: 'type',
                  style: { width: '25%' }
                }, {
                  name: 'Todo',
                  key: 'todo',
                  style: { width: '25%' }
                }]}
                rows={
                  customer.project_set.map(project => ({
                    key: `${project.id}`,
                    name: projectNameWithLabel(project),
                    type: getProjectType(project),
                    todo: project.open_todo_name,
                    link: `/projekt/${project.id}`,
                    has_open_tasks: project.has_open_tasks ? <Icon icon="warning" style={{ color: '#fcba03' }} fontSize='medium' /> : null
                  }))
                }
                header={false}
                counter={false}
                collapsible={false}
                buttomLine={false}
              />
            </Box>
      }))}
      counter={true}
      startCounter={1 + batchSize * batch}
      collapsible={true}
      collapseAllButton={true}
    />)
}

CustomerTable.propTypes = {
  customers: PropTypes.array.isRequired,
  session: PropTypes.object.isRequired,
  batch: PropTypes.number.isRequired,
  batchSize: PropTypes.number.isRequired
}
