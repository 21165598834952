import React, { useEffect, useState } from 'react'

import axios from 'axios'
import { PropTypes } from 'prop-types'
import { Table } from 'react-bootstrap'
import 'react-datepicker/dist/react-datepicker.css'
import { Col, Container, Row, Spinner } from 'reactstrap'

import { Link, Stack, Typography } from '@mui/material'

import { CustomIconButton } from '../../elements/StyledElements'
import { getCustomerName, projectNameWithLabel, string2FormattedString } from '../../elements/utils'
import { API_URL_OFFER, API_URL_PROJECT } from '../../settings'
import CustomModal from '../shared/modal_utils/CustomModal'
import LoadingPage from '../../elements/LoadingPage'
import Icon from '../../elements/Icon'
import { useHistory } from 'react-router-dom'

const BATCHSIZE = 15

const initialSortState = [
  { field: 'accepted_date', state: 'asc' },
  { field: 'zip_code', state: 'no sort' }
]
export default function ChooseProjectToScheduleModal ({ setChosenProject, chosenDate, isOpen, setIsOpen }) {
  const [projects, setProjects] = useState(null)
  const history = useHistory()

  // pagination
  const [batch, setBatch] = useState(0)
  const [nPages, setNPages] = useState(null)
  const [sortState, setSortState] = useState(initialSortState)
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false, showMissingFields: false
  })

  useEffect(() => {
    if (isOpen) {
      loadData()
      setSortState(initialSortState)
    } else clearData()
  }, [isOpen])

  const loadData = () => {
    update(batch).then(() => {})
  }

  useEffect(() => {
    if (isOpen) {
      const sState = sortState.filter((state) => state.state !== 'no sort')
      if (sState.length === 0) {
        return
      }
      if (sState[0].field === 'accepted_date') {
        update(batch).then(() => {})
      } else {
        updateByProject(batch).then(() => {})
      }
    }
  }, [batch, sortState])

  const getSortState = () => {
    let state = ''

    let acceptedDateState = 'asc'
    sortState.forEach(s => {
      if (s.state === 'no sort') return
      state += `${s.field} ${s.state},`
      if (s.field === 'accepted_date') acceptedDateState = s.state
    })
    state += 'id ' + acceptedDateState

    return state
  }

  const update = async (batch) => {
    setLoadingElements({ ...loadingElements, inProgress: true })
    try {
      const acceptedOffers = await axios.get(API_URL_OFFER, {
        params: {
          construction_to_be_scheduled: true,
          batch_size: BATCHSIZE,
          batch,
          project_declined: false,
          sort: getSortState()
        }
      })
      const projectsWithAcceptedOffers = acceptedOffers.data.map(offer => offer.project)

      const projects = await axios.get(API_URL_PROJECT, {
        params: {
          batch_size: BATCHSIZE,
          batch: 0,
          nested: true,
          ...(Array.isArray(projectsWithAcceptedOffers) && (projectsWithAcceptedOffers.length > 0) &&
              { multi_ids: true, ids: projectsWithAcceptedOffers.toString() })
        }
      })
      let newProjects = []
      for (const offer of acceptedOffers.data) {
        newProjects = [
          ...newProjects,
          ...projects.data.filter(p => p.id === offer.project)
        ]
      }
      setNPages(acceptedOffers.headers.length)
      setProjects(newProjects)
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingElements({ ...loadingElements, inProgress: false })
    }
  }

  const updateByProject = async (batch) => {
    setLoadingElements({ ...loadingElements, inProgress: true })
    try {
      const projects = await axios.get(API_URL_PROJECT, {
        params: {
          to_be_scheduled: true,
          batch_size: BATCHSIZE,
          batch,
          nested: true,
          sort: getSortState()
        }
      })
      setNPages(projects.headers.length)
      setProjects(projects.data)
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingElements({ ...loadingElements, inProgress: false })
    }
  }

  const clearData = () => {
    setChosenProject(null)
    setProjects(null)
    setBatch(0)
  }

  const onRowClick = (project) => {
    if (project) setChosenProject(project)
  }
  const onSortChange = (field) => {
    const newSortState = structuredClone(sortState)
    let target = newSortState.filter((state) => state.field === field)
    if (target.length === 0) {
      return
    }
    target = target[0]
    if (target.state === 'asc') {
      target.state = 'desc'
    } else {
      target.state = 'asc'
    }
    for (const state of newSortState) {
      if (state.field === field) continue
      state.state = 'no sort'
    }
    setSortState(newSortState)
  }

  const getSortArrowSymbol = (field) => {
    const filteredSortState = sortState.filter((state) => state.field === field)

    if (filteredSortState.length === 0) {
      return '↑'
    }
    if (filteredSortState[0].field !== field || filteredSortState[0].state === 'no sort') {
      return ''
    }

    if (filteredSortState[0].state === 'asc') return '↑'

    return '↓'
  }

  const title = chosenDate ? 'Bautermin erfassen am ' + chosenDate.toLocaleDateString('de-DE') : ''
  return (
    <CustomModal size="full" isOpen={isOpen} setIsOpen={setIsOpen} title={title}>
      <Container>
        {(projects === null) ? <LoadingPage /> : null}
        <Row>
          <Col>
            <Row>
              <div className="text-center">
                <Typography fontSize='h5.fontSize' className='secondary-textcolor'>
                  Angenommenes Angebot wählen
                </Typography>
              </div>
            </Row>
            <br />
            <Row>
              <Col sm="10" md={{ size: 12, offset: -1 }}>
                <Table className={!projects || projects.length <= 0 ? 'table-not-hover' : 'table-hover'}>
                  <thead>
                  <tr>
                    <th className="align-middle" style={{ textAlign: 'left' }}>
                      <Typography className='secondary-textcolor'></Typography>
                    </th>
                    <th className="align-middle" style={{ textAlign: 'left' }}>
                      <Typography className='secondary-textcolor'>Projekt</Typography>
                    </th>
                    <th className="align-middle" style={{ textAlign: 'left' }}>
                      <Typography className='secondary-textcolor'>Angebot</Typography>
                    </th>
                    <th onClick={() => onSortChange('accepted_date')} className="align-middle"
                        style={{ textAlign: 'left', cursor: 'pointer' }}>
                      <Typography style={{
                        display: 'flex',
                        gap: '5px',
                        alignItems: 'center',
                        padding: '0',
                        border: '1px solid #dee2e6'
                      }} className='secondary-textcolor'>
                        <span style={{ fontSize: 'x-large' }}>{getSortArrowSymbol('accepted_date')}</span> <span>Angenommen am</span>
                      </Typography>
                    </th>
                    <th className="align-middle" style={{ textAlign: 'left' }}>
                      <Typography className='secondary-textcolor'>Kunde</Typography>
                    </th>
                    <th onClick={() => onSortChange('zip_code')} className="align-middle"
                        style={{ textAlign: 'left', cursor: 'pointer' }}>
                      <Typography style={{
                        display: 'flex',
                        gap: '5px',
                        alignItems: 'center',
                        padding: '0',
                        border: '1px solid #dee2e6'
                      }} className='secondary-textcolor'>
                        <span style={{ fontSize: 'x-large' }}>{getSortArrowSymbol('zip_code')}</span> <span>Ort</span>
                      </Typography>
                    </th>
                    <th className="align-middle" style={{ textAlign: 'left' }}>
                      <Typography className='secondary-textcolor'>Telefon</Typography>
                    </th>
                    <th className="align-middle" style={{ textAlign: 'left' }}>
                      <Typography className='secondary-textcolor'>kWp</Typography>
                    </th>
                  </tr>
                  </thead>

                  <tbody>
                  {loadingElements.inProgress
                    ? <tr>
                        <td colSpan="8" align="center">
                            <Spinner style={{ height: '2rem', width: '2rem' }} size='sm'/>
                        </td>
                      </tr>
                    : (!projects || projects.length <= 0)
                        ? (
                        <tr>
                          <td colSpan="8" align="center">
                            <Typography className='secondary-textcolor'> Keine Angebote vorhanden </Typography>
                          </td>
                        </tr>
                          )
                        : (
                            projects.map((project) =>
                                <tr key={`select-project-row-${project.id}`}>
                                  <th className="align-middle" style={{ textAlign: 'left' }}>{project.has_open_tasks
                                    ? <Icon icon="warning" style={{ color: '#fcba03' }} fontSize='large'/>
                                    : null}</th>
                                  <td className="align-middle" style={{ textAlign: 'left' }}
                                      onClick={() => onRowClick(project)}><Typography
                                      className='secondary-textcolor'>{projectNameWithLabel(project)}</Typography></td>
                                  <td className="align-middle" style={{ textAlign: 'left' }}
                                      onClick={() => onRowClick(project)}><Typography
                                      className='secondary-textcolor'>{project.accepted_offer}</Typography></td>
                                  <td className="align-middle" style={{ textAlign: 'left' }}
                                      onClick={() => onRowClick(project)}><Typography
                                      className='secondary-textcolor'>{string2FormattedString(project.accepted_offer_obj.accepted_date)}</Typography>
                                  </td>
                                  <td className="align-middle" style={{ textAlign: 'left' }}>
                                    <Typography
                                        className='secondary-textcolor'
                                        style={{
                                          display: 'flex',
                                          gap: '5px',
                                          alignItems: 'center',
                                          padding: '0',
                                          border: '1px solid #dee2e6'
                                        }}
                                    >
                                      <Link
                                          href={`/kunde/${project.customer}`}
                                          onClick={(e) => {
                                            e.preventDefault()
                                            history.push(`/kunde/${project.customer}`)
                                          }}
                                          style={{
                                            width: '100%',
                                            cursor: 'pointer',
                                            textDecoration: 'none',
                                            color: 'inherit'
                                          }}
                                      >
                                        {getCustomerName(project.customer_obj)}
                                      </Link>
                                    </Typography>

                                  </td>
                                  <td className="align-middle" style={{ textAlign: 'left' }}
                                      onClick={() => onRowClick(project)}><Typography
                                      className='secondary-textcolor'>{project.zip_and_city_project}</Typography></td>
                                  <td className="align-middle" style={{ textAlign: 'left' }}
                                      onClick={() => onRowClick(project)}><Typography
                                      className='secondary-textcolor'>{project.customer_obj.phone1}</Typography></td>
                                  <td className="align-middle" style={{ textAlign: 'left' }}
                                      onClick={() => onRowClick(project)}><Typography
                                      className='secondary-textcolor'>{project.accepted_offer_obj.planning_obj.kwp || ''}</Typography>
                                  </td>
                                </tr>
                            )
                          )
                  }
                  </tbody>
                </Table>
                {nPages && nPages > 1
                  ? <Stack direction="row" spacing={2}>
                      <CustomIconButton
                          disabled={batch === 0}
                          icon='previous'
                          onClick={() => setBatch(batch - 1)}
                      />
                      <Typography className="secondary-textcolor">{batch + 1}</Typography>
                    <CustomIconButton
                      disabled={batch === nPages - 1}
                      icon='next'
                      onClick={() => setBatch(batch + 1)}
                    />
                  </Stack>
                  : null}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container >
    </CustomModal >
  )
}

ChooseProjectToScheduleModal.propTypes = {
  chosenDate: PropTypes.instanceOf(Date),
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  setChosenProject: PropTypes.func
}
