export const SESSION_DURATION = 5 * 3600 * 1000

const host = process.env.REACT_APP_WEBAPPHOST
let port = process.env.REACT_APP_WEBAPPPORT
const protocol = process.env.REACT_APP_WEBAPPPROTOCOL

if (port !== '') { port = ':' + port }

export const PROTOCOL = protocol
export const HOST_PORT = host + port
export const BASE_URL = protocol + '://' + HOST_PORT + '/'

export const API_URL_LOGIN = BASE_URL + 'api/auth/login/'
export const API_URL_LOGOUT = BASE_URL + 'api/auth/logout/'
export const API_URL_PASSWORDUPDATE = BASE_URL + 'api/auth/updatepassword/'
export const API_URL_PASSWORDRESET = BASE_URL + 'api/auth/resetpassword/'
export const API_URL_USERPASSWORDRESET = BASE_URL + 'api/auth/resetuserpassword/'
export const API_URL_USER = BASE_URL + 'api/auth/users/'
export const API_URL_CURRENTUSER = BASE_URL + 'api/auth/currentuser/'
export const API_URL_PERMISSIONS = BASE_URL + 'api/auth/permissions/'
export const API_URL_GROUP = BASE_URL + 'api/auth/groups/'
export const API_URL_TOKEN = BASE_URL + 'api/token/'

export const API_URL_CUSTOMER = BASE_URL + 'api/customers/'
export const API_URL_PROJECT = BASE_URL + 'api/projects/'
export const API_URL_PLANVILLELOCATION = BASE_URL + 'api/planvillelocations/'
export const API_URL_NEARESTPLANVILLELOCATION = BASE_URL + 'api/nearestplanvillelocation/'
export const API_URL_PREFERREDPLANVILLELOCATION = BASE_URL + 'api/preferredplanvillelocation/'
export const API_URL_PRODUCT = BASE_URL + 'api/products/'
export const API_URL_PRODUCTGROUP = BASE_URL + 'api/productgroups/'
export const API_URL_MODULE = BASE_URL + 'api/modules/'
export const API_URL_HEATPUMP = BASE_URL + 'api/heatpumps/'
export const API_URL_INVERTER = BASE_URL + 'api/inverters/'
export const API_URL_BATTERY = BASE_URL + 'api/batteries/'
export const API_URL_PLANNING = BASE_URL + 'api/plannings/'
export const API_URL_EMETER = BASE_URL + 'api/plantproject/emeters/'
export const API_URL_CONSTRUCTION = BASE_URL + 'api/constructions/'
export const API_URL_LABEL = BASE_URL + 'api/labels/'
export const API_URL_EMPLOYEE_LABEL = BASE_URL + 'api/auth/employee_labels/'
export const API_URL_CONSTRUCTIONFRAMEWORKIMAGE = BASE_URL + 'api/plantproject/constructionframeworkimages/'
export const API_URL_CONSTRUCTIONPLANTIMAGE = BASE_URL + 'api/plantproject/constructionplantimages/'
export const API_URL_CONSTRUCTIONEMPLOYEESIG = BASE_URL + 'api/plantproject/constructionemployeesignature/'
export const API_URL_ENERGIEIMPULSIMAGE = BASE_URL + 'api/plantproject/energieimpulsimages/'
export const API_URL_STRING = BASE_URL + 'api/plantproject/strings/'
export const API_URL_OFFER = BASE_URL + 'api/offers/'
export const API_URL_INVOICE = BASE_URL + 'api/invoices/'
export const API_URL_CUSTOMPRODUCT = BASE_URL + 'api/customproducts/'
export const API_URL_ROOFIMAGE = BASE_URL + 'api/plantproject/roofimages/'
export const API_URL_ROOFIMAGEPANEL = BASE_URL + 'api/plantproject/roofimagepanels/'
export const API_URL_ROOFIMAGEPANEL_BULK = BASE_URL + 'api/plantproject/roofimagepanels/bulk'

// export const API_URL_ADDITIONALIMAGE = BASE_URL + "api/additionalimages/";
export const API_URL_HEATPUMPEMETERCABINETIMAGE = BASE_URL + 'api/heatpumpproject/heatpumpemetercabinetimages/'
export const API_URL_HEATERTANKIMAGE = BASE_URL + 'api/heatpumpproject/heatertankimages/'
export const API_URL_HEATERIMAGE = BASE_URL + 'api/heatpumpproject/heaterimages/'
export const API_URL_HEATPUMPCONTROLLERIMAGE = BASE_URL + 'api/heatpumpproject/heatpumpcontrollerimages/'
export const API_URL_HEATERDISTRIBUTORIMAGE = BASE_URL + 'api/heatpumpproject/heaterdistributorimages/'
export const API_URL_HEATPUMPPLANNINGIMAGE = BASE_URL + 'api/heatpumpproject/heatpumpplanningimages/'
export const API_URL_HEATPUMPIMAGE = BASE_URL + 'api/heatpumpproject/constructionheatpumpimages/'
export const API_URL_SENDHEATINGINSTALLER = BASE_URL + 'api/heatpumpproject/sendheatinginstaller/'
export const API_URL_HEATERLABEL = BASE_URL + 'api/heatpumpproject/heaterlabelimages/'
export const API_URL_HEATPUMPPROJECTDATA = BASE_URL + 'api/heatpumpproject/projectdata/'
export const API_URL_EMETERIMAGE = BASE_URL + 'api/plantproject/emeterimages/'
export const API_URL_BATTERY_KWH = BASE_URL + 'api/plantproject/batterykwh/'
export const API_URL_EMETERCABINETIMAGE = BASE_URL + 'api/plantproject/emetercabinetimages/'
export const API_URL_ROOFTILEIMAGE = BASE_URL + 'api/plantproject/rooftileimages/'
export const API_URL_CONSTRUCTIONINVERTER = BASE_URL + 'api/plantproject/constructioninverters/'
export const API_URL_OPTIMIZATION = BASE_URL + 'api/plantproject/optimization/'
export const API_URL_WAREHOUSEPRODUCT = BASE_URL + 'api/warehouseproducts/'
export const API_URL_WAREHOUSEPRODUCTTYPE = BASE_URL + 'api/warehouseproductstype/'
export const API_URL_WAREHOUSESUPPLIER = BASE_URL + 'api/warehousesuppliers/'
export const API_URL_WAREHOUSEUSEDINPRODUCT = BASE_URL + 'api/warehouseusedinproduct/'
export const API_URL_WAREHOUSEACTIVITY = BASE_URL + 'api/warehouseactivity/'
export const API_URL_WAREHOUSEUSEDATCONSTRUCTION = BASE_URL + 'api/warehouseusedatconstruction/'
export const API_URL_WAREHOUSEADDRESS = BASE_URL + 'api/warehouseaddress/'
export const API_URL_WAREHOUSECOMISSION = BASE_URL + 'api/warehousecomission/'
export const API_URL_WAREHOUSEDELIVERYSUPPLIER = BASE_URL + 'api/warehousedeliverysupplier/'
export const API_URL_WAREHOUSEDELIVERYIMAGE = BASE_URL + 'api/warehousedeliveryimage/'
export const API_URL_TIMEMANAGEMENTEVENT = BASE_URL + 'api/timemanagementevent/'
export const API_URL_VACATIONACCOUNT = BASE_URL + 'api/vacationaccount/'
export const API_URL_OPERATORREQUEST = BASE_URL + 'api/plantproject/operatorrequest/'
export const API_URL_CIRCUITDIAGRAM = BASE_URL + 'api/plantproject/circuitdiagram/'
export const API_URL_TODO = BASE_URL + 'api/todos/'
export const API_URL_TODOTYPE = BASE_URL + 'api/todotypes/'
export const API_URL_TODOFILTER = BASE_URL + 'api/todofilters/'
export const API_URL_CUSTOMEREMAIL = BASE_URL + 'api/customeremails/'
export const API_URL_CUSTOMERACTIVITY = BASE_URL + 'api/customeractivity/'
export const API_URL_TASK = BASE_URL + 'api/tasks/'
export const API_URL_TASKTYPE = BASE_URL + 'api/tasktypes/'
export const API_URL_TASKLABEL = BASE_URL + 'api/tasklabels/'
export const API_URL_TASKSETTINGS = BASE_URL + 'api/tasksettings/'
export const API_URL_TASKATTACHMENT = BASE_URL + 'api/taskattachments/'
export const API_URL_TASKCOMMENT = BASE_URL + 'api/taskcomments/'
export const API_URL_CANCEL_INVOICE = BASE_URL + 'api/cancelinvoice/'
export const API_URL_COMMISSIONTYPE = BASE_URL + 'api/commissiontypes/'
export const API_URL_COMMISSIONAMOUNT = BASE_URL + 'api/commissionamounts/'
export const API_URL_TRANSACTIONS = BASE_URL + 'api/transactions/'
export const API_URL_OPERATOR = BASE_URL + 'api/plantproject/operators/'
export const API_URL_VOLTAVOPOLYGON = BASE_URL + 'api/plantproject/voltavopolygon/'
export const API_URL_VOLTAVODATA = BASE_URL + 'api/plantproject/voltavodata/'
export const API_URL_SIMULATIONHOUSE = BASE_URL + 'api/plantproject/simulationhouse/'
export const API_URL_LEAD = BASE_URL + 'api/leads/'
export const API_URL_AREA = BASE_URL + 'api/areas/'
export const API_URL_LEADTYPE = BASE_URL + 'api/leadtypes/'
export const API_URL_NEWSLETTEREMAILDESIGN = BASE_URL + 'api/newsletteremaildesign/'
export const API_URL_VEHICLE = BASE_URL + 'api/vehicles/'
export const API_URL_VEHICLESTATE = BASE_URL + 'api/vehiclestates/'

export const API_URL_CREATEPLANNING = BASE_URL + 'api/createplanning/'
export const API_URL_COPYPLANNING = BASE_URL + 'api/copyplanning/'
export const API_URL_CREATEOFFER = BASE_URL + 'api/createoffer/'
export const API_URL_POSTCREATEOFFER = BASE_URL + 'api/postcreateoffer/'
export const API_URL_COPYOFFER = BASE_URL + 'api/copyoffer/'
export const API_URL_SENDOFFER = BASE_URL + 'api/sendoffer/'
export const API_URL_ACCEPTOFFER = BASE_URL + 'api/acceptoffer/'
export const API_URL_TECHNICALAPPROVAL = BASE_URL + 'api/technicalapproval/'
export const API_URL_CONFIRMOFFER = BASE_URL + 'api/confirmoffer/'
export const API_URL_COMMENTOFFER = BASE_URL + 'api/commentoffer/'
export const API_URL_SENDCONSTRUCTIONDATE = BASE_URL + 'api/sendconstructiondate/'
export const API_URL_SENDCONSTRUCTIONCOSTDIFFERENCE = BASE_URL + 'api/sendconstructioncostdifference/'
export const API_URL_CREATECONSTRUCTIONCOSTDIFFERENCE = BASE_URL + 'api/createconstructioncostdifference/'
export const API_URL_SENDCONSTRUCTIONMANAGER = BASE_URL + 'api/plantproject/sendconstructionmanager/'
export const API_URL_CREATEINVOICE = BASE_URL + 'api/createinvoice/'
export const API_URL_POSTCREATEINVOICE = BASE_URL + 'api/postcreateinvoice/'
export const API_URL_SENDINVOICE = BASE_URL + 'api/sendinvoice/'
export const API_URL_PAYINVOICE = BASE_URL + 'api/payinvoice/'
export const API_URL_SENDPAYMENTCONFIRMATION = BASE_URL + 'api/sendpaymentconfirmation/'
export const API_URL_DISCOUNTINVOICE = BASE_URL + 'api/discountinvoice/'
export const API_URL_GROWATTACCOUNT = BASE_URL + 'api/plantproject/growattaccount/'
export const API_URL_DECLINE = BASE_URL + 'api/decline/'
export const API_URL_CONTACTDENIAL = BASE_URL + 'api/deny_contact/'
export const API_URL_SENDOPERATORREQUEST = BASE_URL + 'api/plantproject/sendoperatorrequest/'
export const API_URL_SENDDATAREQUEST = BASE_URL + 'api/plantproject/senddatarequest/'
export const API_URL_UNKNWONOPERATORREQUEST = BASE_URL + 'api/plantproject/unknownoperatorrequest/'
export const API_URL_SENDMARKTSTAMM = BASE_URL + 'api/plantproject/sendmarktstamm/'
export const API_URL_OFFERWITHDRAWAL = BASE_URL + 'api/offerwithdrawal/'
export const API_URL_CREATEAPPACCESS = BASE_URL + 'api/createappaccess/'
export const API_URL_PROJECTCOMPLETED = BASE_URL + 'api/projectcompleted/'
export const API_URL_SENDREFERRALCODE = BASE_URL + 'api/sendreferralcode/'
export const API_URL_CUSTOMERDUPLICATES = BASE_URL + 'api/customerduplicates/'
export const API_URL_POSTCREATEPROJECT = BASE_URL + 'api/postcreateproject/'
export const API_URL_LEADDUPLICATES = BASE_URL + 'api/customerduplicates/'
export const API_URL_ASSIGNLEAD = BASE_URL + 'api/assignlead/'
export const API_URL_UNSUBSCRIBENEWSLETTER = BASE_URL + 'api/unsubscribenewsletter/'
export const API_URL_UNSUBSCRIBEENERGYREPORT = BASE_URL + 'api/unsubscribeenergyreport/'
export const API_URL_SENDNEWSLETTER = BASE_URL + 'api/sendnewsletter/'
export const API_URL_DASHBOARD_TILES = BASE_URL + 'api/dashboard/tiles/'
export const API_URL_CONSTRUCTIONDATESUGGESTION = BASE_URL + 'api/constructiondatesuggestion/'
export const API_URL_EMPLOYEESCHEDULE = BASE_URL + 'api/employeeschedule/'
export const API_URL_OPERATORDETECTIONREQUEST = BASE_URL + 'api/operatordetectionrequest/'
export const API_URL_ADDITIONALEXTERIORHOUSEIMAGE = BASE_URL + 'api/plantproject/additionalexteriorhouseimage/'
export const API_URL_LEAD_TODO = BASE_URL + 'api/leadtodos/'
export const API_URL_LEAD_EMAIL = BASE_URL + 'api/leademails/'
export const API_URL_LEAD_ACTIVITY = BASE_URL + 'api/leadactivity/'
export const API_URL_LEAD_EMAIL_HISTORY = BASE_URL + 'api/leademailhistory/'
export const API_URL_LEAD_STEP_FILTER = BASE_URL + 'api/leadstepfilter'
export const API_URL_LEAD_SETTING = BASE_URL + 'api/leadsettings/'
export const API_URL_LEAD_SETTING_BULK = BASE_URL + 'api/leadsettings/bulk/'
export const API_URL_LEAD_SOURCE = BASE_URL + 'api/leadsources/'

export const API_URL_REJECTTASK = BASE_URL + 'api/rejectask/'
export const API_URL_ACCECTTASK = BASE_URL + 'api/accepttask/'
export const API_URL_ADDPREVIOUSTASK = BASE_URL + 'api/addprevioustask/'
export const API_URL_TASKREMINDERNOTED = BASE_URL + 'api/taskremindernoted/'
export const API_URL_CONSTRUCTIONEMPLOYEES = BASE_URL + 'api/constructionemployees/'
export const API_URL_TASKEMPLOYEES = BASE_URL + 'api/taskemployees/'

export const API_URL_MAPSETTING = BASE_URL + 'api/mapsettings/'
export const API_URL_MAPSETTING_BULK = BASE_URL + 'api/mapsettings/bulk/'

/* Roof project related apis */
/* Start */
export const API_URL_ROOFPROJECT_PLANNINGADDITIONALIMAGE = BASE_URL + 'api/roofproject/planningadditionalimages/'
export const API_URL_ROOF_PROJECT_LINETYPE = BASE_URL + 'api/roofproject/linetypes/'

export const API_URL_ROOF_PROJECT_ROOFLINE = BASE_URL + 'api/roofproject/rooflines/'
export const API_URL_ROOF_PROJECT_ROOFLINE_BULK = BASE_URL + 'api/roofproject/rooflines/bulk'
export const API_URL_ROOF_PROJECT_ROOFIMAGE = BASE_URL + 'api/roofproject/roofimages/'

export const API_URL_ROOF_PROJECT_CUSTOMPRODUCT_LINE_MAPPING_BULK = BASE_URL + 'api/roofproject/customproductlinemapping/bulk'

export const API_URL_ROOF_PROJECT_CUSTOMPRODUCT_LINE_MAPPING = BASE_URL + 'api/roofproject/customproductlinemapping/'

export const API_URL_ROOF_PROJECT_SENDCONSTRUCTIONMANAGER = BASE_URL + 'api/roofproject/sendconstructionmanager/'

export const API_URL_ROOF_PROJECT_CONSTRUCTIONEMPLOYEESIG = BASE_URL + 'api/roofproject/constructionemployeesignature/'

export const API_URL_ROOF_PROJECT_CONSTRUCTION_IMAGE_BEFORE = BASE_URL + 'api/roofproject/constructionimagebefore/'

export const API_URL_ROOF_PROJECT_CONSTRUCTION_IMAGE_AFTER = BASE_URL + 'api/roofproject/constructionimageafter/'

/* End */

export const CAPI_URL_CUSTOMER = BASE_URL + 'customerapi/customer/'
export const CAPI_URL_PROJECT = BASE_URL + 'customerapi/project/'
export const CAPI_URL_OFFER = BASE_URL + 'customerapi/offers/'
export const CAPI_URL_PLANNING = BASE_URL + 'customerapi/plannings/'
export const CAPI_URL_CONSTRUCTION = BASE_URL + 'customerapi/construction/'
export const CAPI_URL_DATAINPUT = BASE_URL + 'customerapi/datainput/'

export const CAPI_URL_EMETER = BASE_URL + 'customerapi/plantproject/emeters/'
export const CAPI_URL_ACCEPTMANDATE = BASE_URL + 'customerapi/plantproject/acceptmandate/'
export const CAPI_URL_PVDATAINPUT = BASE_URL + 'customerapi/plantproject/datainput/'

export const CAPI_URL_HPDATAINPUT = BASE_URL + 'customerapi/heatpumpproject/datainput/'

export const DAPI_URL_SALESCOMMISSION = BASE_URL + 'api/data/salescommissions/'
export const DAPI_URL_CONSTRUCTIONCOMMISSION = BASE_URL + 'api/data/constructioncommissions/'
export const DAPI_URL_MONTHLYSALESCOMMISSION = BASE_URL + 'api/data/monthlysalescommissions/'
export const DAPI_URL_MONTHLYCONSTRUCTIONCOMMISSION = BASE_URL + 'api/data/monthlyconstructioncommissions/'
export const DAPI_URL_OFFERSTATS = BASE_URL + 'api/data/offerstats/'
export const DAPI_URL_REVENUESTATS = BASE_URL + 'api/data/revenuestats/'
export const DAPI_URL_CONSTRUCTIONSTATS = BASE_URL + 'api/data/constructionstats/'
export const DAPI_URL_MODULESTATS = BASE_URL + 'api/data/modulestats/'
export const DAPI_URL_MODULECONSTRUCTIONS = BASE_URL + 'api/data/moduleconstructions/'
export const DAPI_URL_APPSTATS = BASE_URL + 'api/data/appstats/'
export const DAPI_URL_TASKSTATS = BASE_URL + 'api/data/taskstats/'
export const DAPI_URL_LEADSEVALUATIONSTATS = BASE_URL + 'api/data/leadsevaluationstats/'
export const DAPI_URL_SALESMENLEADSEVALUATIONSTATS = BASE_URL + 'api/data/salesmenleadsevaluationstats/'

export const VERSION_URL = BASE_URL + 'version/'
