import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { Input, Container, Col, Row } from 'reactstrap'
import axios from 'axios'

import { Typography, IconButton, Tooltip } from '@mui/material'
import MapIcon from '@mui/icons-material/Map'

import { API_URL_PLANNING } from '../../../../settings'
import SaveModalFooter from '../../../shared/modal_utils/SaveModalFooter'
import CustomModal from '../../../shared/modal_utils/CustomModal'
import PdfViewModal from '../../../shared/modal_utils/PdfViewModal'
import { getCustomerName, isString } from '../../../../elements/utils'
import { offerPropType, projectPropType } from '../../../../elements/PropTypes'
import MapSite from '../../../shared/MapSite'

export default function UploadMap ({ project, offer, onSubmit = null, isOpen = null, setIsOpen = null, onToggle, session }) {
  const [map, setMap] = useState(null)
  const [loadingElements, setLoadingElements] = useState({
    inProgress: false, submitError: false
  })

  const _onToggle = (isOpen) => {
    if (isOpen) loadData()
    else clearData()
    if (onToggle) onToggle()
  }

  const clearData = () => {
    setMap(null)
    setLoadingElements({ inProgress: false, submitError: false })
  }

  const loadData = () => {
    const acceptedOffer = project.accepted_offer_obj
    let currentMap = acceptedOffer ? acceptedOffer.planning_obj.map : null

    if (!currentMap) {
      const planning = project.planning_set.find(p => p.map)
      currentMap = planning ? planning.map : null
    }
    if (currentMap) {
      fetch(currentMap, { headers: session.axiosHeaders })
        .then(r => r.blob()).then(m => setMap(m))
    }
  }

  const submit = async (toggle) => {
    setLoadingElements({ inProgress: true, submitError: false })
    const formData = new FormData()
    formData.append('map', map, map.name)
    return axios.put(API_URL_PLANNING + offer.planning, formData)
      .then((res) => {
        setLoadingElements({ inProgress: false, submitError: false })
        _onSubmit().then(toggle)
        return res
      }).catch(() => setLoadingElements({ inProgress: false, submitError: true }))
  }

  const onFileChange = (e) => {
    if ((!e.target.files) || e.target.files.length < 1) return
    const file = e.target.files[0]

    if (file.type !== 'application/pdf') {
      setMap(null)
      setLoadingElements({ ...loadingElements, submitError: 'Bitte eine Datei vom Typ PDF hochladen' })
      return
    }
    setLoadingElements({ ...loadingElements, submitError: null })
    setMap(file)
  }

  const _onSubmit = () => onSubmit && onSubmit()

  const getFooter = (toggle) => {
    return (
            <SaveModalFooter
                disabled={map == null}
                submitError={loadingElements.submitError}
                inProgress={loadingElements.inProgress}
                onSave={() => submit(toggle)}
                onCancel={toggle}
                btnLabel={'Speichern'}
            />
    )
  }

  const getOpenButton = (toggle) => {
    if (isOpen === null) {
      return (
      <Tooltip title='Karte' PopperProps={{ style: { zIndex: 9999 } }} >
      <IconButton disableFocusRipple disableRipple style={{ backgroundColor: 'transparent', float: 'right' }} size="small" onClick={toggle} ><MapIcon className='secondary-textcolor' fontSize='large' /></IconButton>
      </Tooltip>
      )
    }
    return null
  }

  return (
        <CustomModal size="lg" isOpen={isOpen} setIsOpen={setIsOpen} getFooter={getFooter} getOpenButton={getOpenButton} onToggle={_onToggle} title={'Map hochladen'}>
            <MapSite project={project} />
            <br />
            <Typography className='secondary-textcolor'>Adresse: {project.street_and_number_project}, {project.zip_and_city_project}</Typography>
            <Typography className='secondary-textcolor'>(Kundenadresse wird in die Zwischenablage kopiert)</Typography>
            <br />
            {/* <Typography className='secondary-textcolor'>Bitte zeichne die PV Anlage in Tim Online ein und drücke dann auf Drucken. Maßstab 1:500</Typography>
            <br /> */}
            <Container>
                <Row>
                    <Col md={{ size: 8 }}>
                        <Input style={{ width: '50%', boxShadow: 'none' }} id="input" type="file" accept="application/pdf" onChange={onFileChange} />
                    </Col>
                    {map
                      ? <Col align="right" md={{ size: 1 }}>
                            <PdfViewModal
                                title={'Map für ' + getCustomerName(project.customer_obj)}
                                filepath={isString(map) ? map : URL.createObjectURL(map)}
                            />
                        </Col>
                      : null}
                </Row>
            </Container>
        </CustomModal>
  )
}

UploadMap.propTypes = {
  project: projectPropType,
  offer: offerPropType,
  onSubmit: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  onToggle: PropTypes.func,
  session: PropTypes.object
}
